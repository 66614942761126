.no-op {
  // If an element has this class, it is used for non-semantic, non-styled, structural purposes
}

// For 'footer' buttons / button-groups below blocks, etc.

.s-block-action {
  margin-top: var(--spacing-06);

  @include breakpoint('medium+') {
    margin-top: var(--spacing-09);
  }
}

// Tiling helper for any large listings, e.g. callouts

.s-large-listing-group {
  margin-top: calc(0px - var(--spacing-08));

  > * {
    margin-top: var(--spacing-08);
  }

  @include breakpoint('medium+') {
    margin-top: calc(0px - var(--spacing-10));

    > * {
      margin-top: var(--spacing-10);
    }
  }
}

// The initial spacing after a hero

.s-after-hero {
  margin-top: var(--spacing-08);
}

// A large separation between blocks
// to distinguish a disconneciton

.s-block-section {
  margin-top: var(--spacing-09);

  @include breakpoint('medium') {
    margin-top: var(--spacing-10);
  }

  @include breakpoint('large+') {
    margin-top: var(--spacing-11);
  }

  // &--breathe {
  //   @include breakpoint('large+') {
  //     // TODO -- check against spacing scale
  //     margin-top: 290px;
  //   }
  // }
}


/*

dumps, code samples, etc.

In twig:

```
{% for key, value in _context %}
    {% if key starts with '_' %}
    {% else %}
        <pre>{{ key }}:
{{ value | json_encode(constant('JSON_PRETTY_PRINT')) }}

        </pre>
    {% endif %}
{% endfor %}
```

*/

pre {
  max-height: 300px;
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  padding: 1px 3px;
  background: rgba(0,0,0,.05);
  font-family: "Inconsolata","SFMono-Regular","Consolas","Liberation Mono","Menlo",monospace;
  font-weight: 600;
  background-color: #eee;
}
