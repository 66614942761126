.filter-group {
  &__filters {
    display: flex;
    padding-top: var(--spacing-04);
    overflow-x: auto;
    margin-bottom: -20px;
    padding-bottom: 20px;
    -webkit-overflow-scrolling: touch;

    @include breakpoint('medium+') {
      padding-top: var(--spacing-05);
    }

    > * {
      margin-left: var(--spacing-04);

      &:last-child {
        padding-right: var(--spacing-04);
      }
    }
  }

  &__tags {
    display: flex;
    margin-top: var(--spacing-06);
    margin-left: calc(0px - var(--spacing-04));
    overflow-x: auto;
    margin-bottom: -20px;
    padding-bottom: 20px;
    -webkit-overflow-scrolling: touch;

    > * {
      margin-left: var(--spacing-04);
      flex-shrink: 0;
    }
  }

  &__panels {
    position: absolute;
    z-index: 10;
    width: 240px;
    pointer-events: none;
  }
}
