.list-price {
  @include f-body-ui;
  padding: var(--spacing-04) 0;
  color: var(--color-text-alt);
  border-top: 1px solid var(--color-border);
  border-bottom: 1px solid var(--color-border);
}

.list-price__item {
  & + & {
    margin-top: var(--spacing-02);
  }
}

.list-price__single {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
}

.list-price__left {
  width: calc(100% - 200px);
}

.list-price__right {
  width: 200px;
}

.list-price__infos {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-end;
}

.list-price__infos-cell {
  width: 33%;
  text-align: right;
}
