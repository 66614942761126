.block--carousel-video {
  background-color: var(--color-black);
  color: var(--color-white);

  .container {
    margin-bottom: 0;
  }

  .block__cell {
    @include grid-column(1, 24);

    @include breakpoint('small-') {
      @include grid-column(1, 8);
    }
  }

  .video__container {
    background-color: var(--color-black);
  }
}
