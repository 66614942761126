
.block--text_cta {
  .block-text__title {
    @include f-heading-04;
    margin-bottom: var(--spacing-05);
  }

  .button {
    margin-top: var(--spacing-05);
  }
}
