.tag {
  border: 1px solid var(--color-border-dark);
  border-radius: 20px;
  padding-top: var(--spacing-02);
  padding-bottom: var(--spacing-02);
  padding-left: var(--spacing-04);
  padding-right: var(--spacing-04);
  display: inline-flex;
  align-items: center;
}

.tag__label {
  @include f-caption;
}

.tag__icon {
  margin-left: var(--spacing-02);

  .icon {
    width: 16px;
    height: 16px;
    display: block;
  }
}

.tag:hover {
  border-color: var(--color-border);
}

.tag:hover .tag__icon {
  // opacity: 0.5
}
