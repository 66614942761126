.hero-membership {
  padding-top: var(--spacing-07);

  @include breakpoint('medium') {
    padding-top: var(--spacing-08);
  }

  @include breakpoint('large+') {
    padding-top: var(--spacing-10);
  }

  @include breakpoint('xlarge+') {
    padding-top: var(--spacing-11);
  }
}

.hero-membership__title {
  @include f-display-02-alt;

  @include breakpoint('small') {
    @include grid-column(1, 8);
  }

  @include breakpoint('medium+') {
    @include grid-column(3, 16);
  }

  @include breakpoint('xlarge') {
    @include grid-column(4, 14);
  }

  @include breakpoint('xxlarge') {
    @include grid-column(5, 13);
  }
}

// Taken from .hero-standard__text

.hero-membership__text {
  @include grid-column(1, 8);
  @include f-subheading-02;
  margin-top: var(--spacing-02);

  @include breakpoint('small') {
    @include grid-column(1, 8);
  }

  @include breakpoint('medium+') {
    @include grid-column(3, 16);
  }

  @include breakpoint('xlarge') {
    @include grid-column(4, 14);
  }

  @include breakpoint('xxlarge') {
    @include grid-column(5, 13);
  }

}


.hero-membership__details {
  display: flex;
  justify-content: space-between;
  margin-top: var(--spacing-06);
  margin-bottom: var(--spacing-06);

  @include breakpoint('small') {
    @include grid-column(1, 8);
    flex-direction: column;
  }

  @include breakpoint('medium') {
    @include grid-column(3, 20);
  }

  @include breakpoint('medium+') {
    position: relative;
    margin-top: var(--spacing-11);
    margin-bottom: var(--spacing-06);
  }

  @include breakpoint('large') {
    @include grid-column(3, 20);
  }

  @include breakpoint('xlarge') {
    @include grid-column(4, 18);
  }

  @include breakpoint('xxlarge') {
    @include grid-column(5, 16);
  }
}

.hero-membership__price-label {
  color: var(--color-gray-90);
  opacity: 0.6;
}

.hero-membership__buttons {
  align-items: flex-end;
  display: flex;

  .hero-membership__renew {
    margin-left: var(--spacing-03);
  }

  @include breakpoint('small') {
    margin-top: var(--spacing-04);

    .hero-membership__renew {
      display: none;
    }
  }
}

.hero-membership__media {
  position: relative;

  @include breakpoint('small') {
    @include grid-column(1, 8);
    @include img-container($ratio-1x1);
  }

  @include breakpoint('medium+') {
    @include grid-column(3, 20);
    @include img-container($ratio-2x1);
  }

  @include breakpoint('xlarge') {
    @include grid-column(4, 18);
  }

  @include breakpoint('xxlarge') {
    @include grid-column(5, 16);
  }
}
