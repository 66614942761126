.block {
  margin-top: var(--spacing-07);

  &:first-child:not(.is-bled) {
    margin-top: 0;
  }

  @include breakpoint('medium') {
    margin-top: var(--spacing-09);
  }

  @include breakpoint('large+') {
    margin-top: var(--spacing-10);
  }
}

.block__cell {
  @include grid-column(1, 8);

  @include breakpoint('medium') {
    @include grid-column(3, 20);
  }

  @include breakpoint('large') {
    @include grid-column(9, 14);
  }

  @include breakpoint('xlarge') {
    @include grid-column(9, 13);
  }

  @include breakpoint('xxlarge') {
    @include grid-column(9, 12);
  }
}

.block__title {
  @include f-heading-04;
  margin-bottom: var(--spacing-07);
}


/*********************
      Modifiers
*********************/

.blocks--page {
  margin-top: var(--spacing-08);

  @include breakpoint('medium') {
    margin-top: var(--spacing-10)
  }

  @include breakpoint('large+') {
    margin-top: var(--spacing-11)
  }
}

.blocks--detail {
  margin-top: var(--spacing-07);
}

.block--text {
  @include breakpoint('large+') {
    .block__cell {
      @include grid-column(9, 13);
    }
  }

  @include breakpoint('xlarge') {
    .block__cell {
      @include grid-column(9, 12);
    }
  }

  @include breakpoint('xxlarge') {
    .block__cell {
      @include grid-column(9, 11);
    }
  }
}

.block--quick-access,
.block--tool {
  @include breakpoint('medium+') {
    .block__cell {
      @include grid-column(1, 24);
    }
  }
}

.block--carousel {
  overflow: hidden;
}

.block--chapter {
  margin-top: var(--spacing-08);

  @include breakpoint('medium') {
    margin-top: var(--spacing-10);
  }

  @include breakpoint('large+') {
    margin-top: var(--spacing-11);
  }
}

.block--membership {
  margin-top: var(--spacing-09);

  @include breakpoint('medium') {
    margin-top: var(--spacing-11);
  }

  @include breakpoint('large+') {
    margin-top: var(--spacing-12);
  }
}

.block--activity,
.block--membership,
.block--flv_play,
.block--flv_play_series,
.block--flv_play_short,
.block--flv_play_architecture {
  .block__cell {
    @include grid-column(1, 8);
  }

  @include breakpoint('medium+') {
    .block__cell {
      @include grid-column(1, 24);
    }
  }
}

.block--flv_play,
.block--flv_play_short,
.block--flv_play_architecture {

  &.has-anchor {
    margin-top: var(--spacing-09);

    .block-flv-play {
      padding-top: var(--spacing-05);
    }

    &:first-child:not(.is-bled) {
      margin-top: calc(-1 * var(--spacing-05));
    }

    @include breakpoint('medium') {
      margin-top: var(--spacing-10);
    }

    @include breakpoint('large+') {
      margin-top: var(--spacing-11);
    }
  }
}

.block--title,
.block--title-icon {
  .block__title {
    margin-bottom: 0;
  }
}

.block--title + .block {
  margin-top: var(--spacing-06);
}

.block--caption {
  margin-top: var(--spacing-05);
}

.block--accordion + .block--text,
.block--accordion + .block-bleed > .block--text:first-child,
.block--faq + .block--text,
.block--faq + .block-bleed > .block--text:first-child,
.block--text + .block--accordion,
.block--text + .block-bleed > .block--accordion:first-child,
.block--text + .block--faq,
.block--text + .block-bleed > .block--faq:first-child,
.block--text + .block--ticket-button,
.block--text + .block-bleed > .block--ticket-button:first-child,
.block--title + .block--accordion,
.block--title + .block-bleed > .block--accordion:first-child,
.block--title + .block--faq,
.block--title + .block-bleed > .block--faq:first-child,
.block--title + .block--ticket-button,
.block--title + .block-bleed > .block--ticket-button:first-child,
.block--title-icon + .block--accordion,
.block--title-icon + .block-bleed > .block--accordion:first-child,
.block--title-icon + .block--faq,
.block--title-icon + .block-bleed > .block--faq:first-child,
.block--title-icon + .block--ticket-button,
.block--title-icon + .block-bleed > .block--ticket-button:first-child,
.block--link,
.block--button {
  margin-top: var(--spacing-07);
}

.block--title + .block--wide {
  margin-top: var(--spacing-06);

  @include breakpoint('medium+') {
    margin-top: var(--spacing-07);
  }

  @include breakpoint('xlarge+') {
    margin-top: var(--spacing-08);
  }
}

.block--text + .block--text {
  margin-top: var(--spacing-06);
}

.block--chapter + .block--works-grid-tabbed {
  // Less spacing because tabs should feel related
  // plus even less spacing due to the tab's overflow-x scroll area
  margin-top: 0;
}

.block--chapter + .block--after-chapter {
  // Use 'after-chapter' block type if there's specific type needed
  // (This is here to avoid a global breaking change)
  margin-top: var(--spacing-05);
}

.block--chapter + .block--carousel {
  @include breakpoint('medium+') {
    margin-top: var(--spacing-07);
  }

  @include breakpoint('xlarge+') {
    margin-top: var(--spacing-09);
  }
}

.block--sound-cover {

  @include breakpoint('medium') {
    margin-top: var(--spacing-08);
  }

  @include breakpoint('large+') {
    margin-top: var(--spacing-09);
  }
}

.blocks--membership {
  margin: -120px var(--spacing-02) 0;
  position: relative;
  z-index: var(--z-index-section);

  //@include breakpoint('medium+') {
  //  @include grid;
  //}

  @include breakpoint('large+') {
    display: flex;
  }

  //&.blocks--grid-3 {
  //  @include breakpoint('large+') {
  //    display: flex;
  //  }
  //}
  //
  //&.blocks--grid-4 {
  //  @include breakpoint('large+') {
  //    grid-gap: var(--spacing-04);
  //
  //    .block--membership_detail {
  //      @include grid-column('auto', 12);
  //    }
  //  }
  //
  //  @include breakpoint('xlarge+') {
  //
  //    .block--membership_detail {
  //      @include grid-column('auto', 6);
  //    }
  //  }
  //}
}

.block--membership_detail {
  flex: 1;
  margin-top: 0;

  .grid {
    display: block;
  }

  .container,
  .grid,
  .block__cell {
    height: 100%;
  }

  .container {
    width: calc(100% - 16px);
  }

  .block__cell {
    @include grid-column(1, 8);
  }

  @include breakpoint('medium-') {
    margin-bottom: var(--spacing-04);
  }

  @include breakpoint('medium') {
    @include grid-column(3, 20);
  }

  @include breakpoint('medium+') {

    .block__cell {
      @include grid-column(1, 24);
    }
  }
}

/*********************
      Sizes
*********************/


.block--wide {
  .block__cell {
    @include breakpoint('medium+') {
      @include grid-column(3, 20);
    }

    @include breakpoint('xlarge') {
      @include grid-column(4, 18);
    }

    @include breakpoint('xxlarge') {
      @include grid-column(5, 16);
    }
  }
}

.block--full-width {
  .block__cell {
    @include breakpoint('medium+') {
      @include grid-column(1, 24);
    }
  }
}
