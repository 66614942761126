
.block-flv-play-architecture {
  .carousel .carousel__item {
    bottom: auto;
    top: 0;
  }
}

.block-flv-play-architecture__more {
  @include grid;

  .block-flv-play-architecture__more-container {
    @include grid-column(1, 8);

    @include breakpoint('medium+') {
      @include grid-column(1, 24);
    }
    @include breakpoint('large') {
      @include grid-column(3, 20);
    }
    @include breakpoint('xlarge+') {
      @include grid-column(5, 16);
    }
  }

  .block__header {
    margin: 0 0 var(--spacing-04);

    @include breakpoint('medium+') {
      margin: 0 var(--spacing-02) var(--spacing-06);
    }
  }

  .block__title {
    @include grid-column(1, 8);
    @include f-heading-05;
    margin-bottom: var(--spacing-02);
    margin-top: var(--spacing-08);

    @include breakpoint('medium+') {
      @include grid-column(1, 24);
      margin-top: var(--spacing-12);
    }
  }
  .block__subtitle {
    @include grid-column(1, 8);
    @include f-subheading-02;

    @include breakpoint('medium') {
      @include grid-column(1, 16);
    }
    @include breakpoint('large+') {
      @include grid-column(1, 10);
    }
  }

  .carousel .flickity-viewport {
    width: 100%;
  }
  .carousel .carousel__item {
    opacity: 1;
    width: 33.33%;
  }
  .carousel__list:after {
    content: 'flickity';
    display: none;
  }

  @include breakpoint('small') {
    .carousel .carousel__item {
      height: 200px;
      // opacity: 0.4;
      width: 200px;

      &.is-selected {
        opacity: 1;
      }
    }
  }

  @include breakpoint('medium+') {

    .carousel .container {
      width: 100%;
    }

    .carousel__list {
      align-items: center;
      display: flex;
      justify-content: center;
    }

    .carousel__item {
      max-width: 350px;
      margin: 0 var(--spacing-02);
    }

    .carousel__controls {
      display: none;
    }

    .carousel__single {
      padding-right: 0;
    }

    .carousel__list:after {
      content: '';
    }
  }
}
