.block-checklist__main-title {
  @include f-heading-04;
  margin-bottom: var(--spacing-06);
}

.block-checklist__item {
  margin-bottom: var(--spacing-06);
}

.block-checklist__single {
  position: relative;
  padding-left: var(--spacing-06);

  .icon {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.block-checklist__title {
  @include f-heading-05;
}

.block-checklist__text {
  margin-top: var(--spacing-02);
}
