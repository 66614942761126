.datepicker-range {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: var(--z-index-datepicker-range);

  .flatpickr-calendar,
  .flatpickr-days {
    width: 100% !important;
  }

  .dayContainer + .dayContainer {
    box-shadow: none;
  }

  @include breakpoint('small') {
    display: block;
    background-color: var(--color-background-light);

    .dayContainer,
    .flatpickr-weekdaycontainer {
      width: 100%;
      min-width: 100%;
      max-width: 100%;
    }

    .flatpickr-rContainer {
      width: 100%;
    }

    .dayContainer,
    .flatpickr-weekdaycontainer {
      padding-left: 0;
      padding-right: 0;
    }
  }

  @include breakpoint('large+') {
    .flatpickr-days {
      min-height: 288px;
    }

    .dayContainer,
    .flatpickr-weekdaycontainer {
      width: 50%;
      min-width: 0;
      max-width: none;

      &:first-child {
        padding-right: 36px;
      }

      &:last-child {
        padding-left: 36px;
      }
    }

    .flatpickr-weekdaycontainer {
      &:first-child {
        padding-right: 36px;
      }
    }
  }
}

.datepicker-range__container {
  width: 100%;
  padding-bottom: 60px;
}

.datepicker-range__reset {
  margin-top: var(--spacing-08);
  text-align: center;

  @include breakpoint('medium+') {
    display: none;
  }
}

.datepicker-range__footer {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: var(--spacing-06);
  text-align: right;

  @include breakpoint('small') {
    position: absolute;
    bottom: 0;
    left: 0;
    flex-flow: row nowrap;
    width: 100%;
    margin-top: 0;
    padding: var(--spacing-03) var(--spacing-04);
    text-align: center;
    background-color: var(--color-background-light);
    border-top: 1px solid var(--color-border);
    z-index: 2;
  }
}

.datepicker-range__footer-btns {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.datepicker-range__footer-infos {
  @include f-caption;
  min-width: 1px;
  color: var(--color-text-alt);
  text-transform: capitalize;

  @include breakpoint('small') {
    padding-right: var(--spacing-03);
    text-align: left;
  }
}

.datepicker-range__footer-cell {
  @include breakpoint('small') {
    &--reset {
      display: none;
    }
  }

  @include breakpoint('medium+') {
    &--validate {
      margin-left: var(--spacing-05);
    }
  }
}
