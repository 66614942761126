.link {
  @include reset-btn;
  position: relative;
  display: inline-flex;
  align-items: center;
  border-bottom: 1px solid currentColor;
  transition: color var(--transition-link);

  &:hover {
    color: var(--color-text-alt);
  }

  @include focus {
    color: var(--color-focus);
    transition: none;
  }
}

.link__label {
  position: relative;
  z-index: 1;
}


/*********************
      Modifiers
*********************/

.link--icon {
  @include f-label;
  position: relative;
  border-bottom: 0;

  .icon {
    position: relative;
    margin-left: 2px;
    z-index: 1;
    transition: transform var(--transition-link);

    @include breakpoint('small') {
      transform: scale(1.2);
    }
  }

  .link__label {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 100%;
      display: block;
      height: 1px;
      background-color: currentColor;
      transition: right var(--transition-link-slow);
    }
  }

  &:after {
    content: '';
    position: absolute;
    top: -4px;
    right: -4px;
    bottom: -4px;
    left: -8px;
    display: none;
    border: 2px solid var(--color-focus);
    border-radius: 20px;
  }

  &:hover {
    color: currentColor;

    .link__label:before {
      right: 0;
    }
  }

  @include focus {
    color: currentColor;

    &:after {
      display: block;
    }
  }
}

.link--small {
  @include f-caption;
  color: var(--color-text-alt);

  &:hover {
    color: var(--color-text);
  }
}
