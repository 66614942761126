.tiles {
  position: relative;
  padding-top: var(--spacing-08);

  @include breakpoint('medium+') {
    padding-top: var(--spacing-09);
  }
}

.tiles__title {
  @include f-display-03;
  margin-bottom: var(--spacing-07);

  @include breakpoint('medium+') {
    margin-bottom: var(--spacing-08);
  }
}

.tiles__link {
  margin-top: var(--spacing-06);

  .link {
    display: flex;
  }
  .link + .link {
    margin-top: var(--spacing-04);
  }

  @include breakpoint('medium+') {

    .link {
      display: inline-flex;
    }

    .link + .link {
      margin-top: 0;
      margin-left: var(--spacing-05);
    }
  }
}

/*********************
      Modifiers
*********************/

.tiles--text-center {
  text-align: center;

  @include breakpoint('small') {
    .button + .button {
      margin-top: var(--spacing-04);
    }
  }

  @include breakpoint('medium+') {
    .button + .button {
      margin-left: var(--spacing-04);
    }
  }
}

.tiles--background {
  margin-top: var(--spacing-12);
  padding-bottom: var(--spacing-09);
  background-color: var(--color-background-darker);
}

.tiles--pad-large {
  padding-top: var(--spacing-09);

  @include breakpoint('large+') {
    padding-top: var(--spacing-12);
  }
}

.tiles--medium-title {
  .tiles__title {
    @include f-heading-03;
    margin-bottom: var(--spacing-05);
  }

  @include breakpoint('medium+') {
    .tiles__title {
      margin-bottom: var(--spacing-06);
    }
  }
}

.tiles--small-title {
  .tiles__title {
    @include f-heading-04;
    margin-bottom: var(--spacing-05);
  }

  @include breakpoint('medium+') {
    .tiles__title {
      margin-bottom: var(--spacing-06);
    }
  }
}

.tiles--center {
  .tiles__container {
    @include grid;
  }

  .tiles__cell {
    @include grid-column(1, 8);
  }

  @include breakpoint('medium+') {
    .tiles__cell {
      @include grid-column(3, 20);
    }
  }

  @include breakpoint('xlarge') {
    .tiles__cell {
      @include grid-column(4, 18);
    }
  }

  @include breakpoint('xxlarge') {
    .tiles__cell {
      @include grid-column(5, 16);
    }
  }
}

.tiles--grid-2-cols {
  .tiles__content {
    @include grid-container;
    @include grid-columns(grid-repeat(1, 1fr));
    @include grid-row-gap(var(--spacing-07));
  }

  @include breakpoint('xlarge+') {
    .tiles__content {
      @include grid-columns(grid-repeat(2, 1fr));
      @include grid-column-gap(var(--spacing-04));
      @include grid-row-gap(var(--spacing-10));
    }
  }
}

.tiles--grid-2-cols-center {
  .tiles__container {
    @include grid;
  }

  .tiles__cell {
    @include grid-column(1, 8);
  }

  .tiles__content {
    @include grid-container;
    @include grid-columns(grid-repeat(8, 1fr));
    @include grid-column-gap(16px);
    @include grid-row-gap(var(--spacing-08));

    *:nth-child(1),
    *:nth-child(2) {
      @include grid-column(1, 8);
    }
  }

  @include breakpoint('medium+') {
    .tiles__cell {
      @include grid-column(3, 20);
    }

    .tiles__content {
      @include grid-columns(grid-repeat(20, 1fr));
      @include grid-row-gap(var(--spacing-09));

      *:nth-child(1),
      *:nth-child(2) {
        @include grid-column(1, 20);
      }
    }
  }

  @include breakpoint('large+') {
    .tiles__content {
      *:nth-child(1) {
        @include grid-column(1, 9);
      }

      *:nth-child(2) {
        @include grid-column(12, 9);
      }
    }
  }

  @include breakpoint('xlarge') {
    .tiles__cell {
      @include grid-column(4, 18);
    }

    .tiles__content {
      @include grid-columns(grid-repeat(18, 1fr));

      *:nth-child(1) {
        @include grid-column(1, 8);
      }

      *:nth-child(2) {
        @include grid-column(11, 8);
      }
    }
  }

  @include breakpoint('xxlarge') {
    .tiles__cell {
      @include grid-column(5, 16);
    }

    .tiles__content {
      @include grid-columns(grid-repeat(16, 1fr));

      *:nth-child(1) {
        @include grid-column(1, 7);
      }

      *:nth-child(2) {
        @include grid-column(10, 7);
      }
    }
  }
}

.tiles--grid-3-cols {
  .tiles__content {
    @include grid-container;
    @include grid-columns(grid-repeat(1, 1fr));
    @include grid-row-gap(var(--spacing-08));
  }

  @include breakpoint('large+') {
    .tiles__content {
      @include grid-columns(grid-repeat(3, 1fr));
      @include grid-column-gap(var(--spacing-04));
    }
  }
}

.tiles--grid-3-cols-center {
  .tiles__container {
    @include grid;
  }

  .tiles__cell {
    @include grid-column(1, 8);
  }

  .tiles__content {
    @include grid-container;
    @include grid-row-gap(var(--spacing-07));
  }

  @include breakpoint('medium+') {
    .tiles__cell {
      @include grid-column(3, 20);
    }
  }

  @include breakpoint('large+') {
    .tiles__content {
      @include grid-columns(grid-repeat(3, 1fr));
      @include grid-column-gap(var(--spacing-04));
    }
  }

  @include breakpoint('xxlarge') {
    .tiles__cell {
      @include grid-column(5, 16);
    }
  }
}

.tiles--grid-2-cols-card {
  .tiles__container {
    @include grid;
  }

  .tiles__cell {
    @include grid-column(1, 8);
  }

  .tiles__content {
    @include grid-container;
    @include grid-columns(grid-repeat(2, 1fr));
    @include grid-column-gap(var(--spacing-04));
    @include grid-row-gap(var(--spacing-07));
  }

  @include breakpoint('medium+') {
    .tiles__cell {
      @include grid-column(3, 20);
    }
  }

  @include breakpoint('xlarge') {
    .tiles__cell {
      @include grid-column(4, 18);
    }
  }

  @include breakpoint('xxlarge') {
    .tiles__cell {
      @include grid-column(5, 16);
    }
  }
}

.tiles--border-top {
  margin-top: var(--spacing-09);
  border-top: 1px solid var(--color-border);

  .tiles__header {
    @include grid;
  }

  .tiles__title {
    @include grid-column(1, 8);
    margin-bottom: var(--spacing-07);
  }

  @include breakpoint('medium+') {
    margin-top: var(--spacing-10);

    .tiles__title {
      @include grid-column(3, 20);
      margin-bottom: var(--spacing-09);
    }
  }

  @include breakpoint('large+') {
    margin-top: var(--spacing-11);
  }

  @include breakpoint('xlarge') {
    .tiles__title {
      @include grid-column(4, 18);
    }
  }

  @include breakpoint('xxlarge') {
    .tiles__title {
      @include grid-column(5, 16);
    }
  }
}

.tiles--flex {
  .tiles__content {
    margin-bottom: calc(var(--spacing-07) * -1);
  }

  .product {
    margin-bottom: var(--spacing-07);
  }

  @include breakpoint('medium+') {
    .tiles__container {
      @include grid;
    }

    .tiles__cell {
      @include grid-column(3, 20);
    }
  }

  @include breakpoint('large+') {
    .tiles__content {
      display: flex;
      flex-flow: row wrap;
      align-items: flex-start;
      justify-content: center;
    }

    .product {
      width: 33.3333%;
      padding: 0 var(--spacing-02);
    }
  }

  @include breakpoint('xlarge') {
    .tiles__cell {
      @include grid-column(4, 18);
    }
  }

  @include breakpoint('xxlarge') {
    .tiles__cell {
      @include grid-column(5, 16);
    }
  }
}

.tiles--title-left {
  padding-top: var(--spacing-09);

  .tiles__title {
    @include breakpoint('small') {
      margin-bottom: var(--spacing-06);
    }
  }

  .tiles__cell {
    @include grid;
  }

  .tiles__header {
    @include grid-column(1, 8);
  }
  .tiles__content {
    @include grid-column(1, 8);
  }

  @include breakpoint('medium+') {
    .tiles__header {
      @include grid-column(3, 20);
    }
    .tiles__content {
      @include grid-column(3, 20);
    }
  }

  @include breakpoint('large+') {
    padding-top: var(--spacing-10);

    .tiles__header {
      @include grid-column(9, 12);
    }
    .tiles__content {
      @include grid-column(9, 14);
    }
  }

  @include breakpoint('xlarge') {

    .tiles__header {
      @include grid-column(4, 5);
    }
    .tiles__content {
      @include grid-column(10, 12);
    }
  }

  @include breakpoint('xxlarge') {
    padding-top: var(--spacing-11);

    .tiles__header {
      @include grid-column(5, 5);
    }
    .tiles__content {
      @include grid-column(11, 10);
    }
  }
}

.tiles--hide-mobile {
  @include breakpoint('small-') {
    display: none;
  }
}

