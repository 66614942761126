.card-membership {
  height: 100%;
}

.card-membership__container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  padding: var(--spacing-05);

  @include breakpoint('medium+') {
    padding: var(--spacing-06);
  }
}

.card-membership__title {
  @include f-heading-03;
}

.card-membership__price {
  @include f-heading-04-alt;
  margin-top: var(--spacing-03);
  color: var(--color-text-alt);
}

.card-membership__wysiwyg {
  @include f-body;
  margin-top: var(--spacing-06);
}

.card-membership__btns {
  margin-top: var(--spacing-05);

  .button + .button {
    margin-top: var(--spacing-03);
    margin-left: var(--spacing-01);
  }
}
