.block-flv-play {
  overflow: hidden;

  .block__header {
    @include grid;
    margin-bottom: var(--spacing-04);

    @include breakpoint('medium+') {
      margin-bottom: calc(-1 * var(--spacing-07));
    }
  }

  .block__title {
    @include grid-column(1, 8);
    @include f-heading-02;
    margin-bottom: 0;

    @include breakpoint('medium+') {
      @include grid-column(1, 24);
    }
  }
  .block__description {
    @include grid-column(1, 8);
    @include f-subheading-02;
    margin-top: var(--spacing-04);
    margin-bottom: 0;

    @include breakpoint('medium') {
      @include grid-column(1, 16);
    }

    @include breakpoint('large') {
      @include grid-column(1, 12);
    }

    @include breakpoint('xlarge+') {
      @include grid-column(1, 10);
    }
  }

  .carousel {

    .carousel__item {
      bottom: auto;
      max-width: 880px;
      opacity: 0.4;
      top: 0;
      width: 100%;
      transition: opacity 0.2s linear;

      &.is-selected {
        opacity: 1;
      }

      @include breakpoint('medium+') {
        width: 60vw;
      }
    }

  }
}
