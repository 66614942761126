
.works-grid-cascading__item {
  transition: opacity 0.3s ease-out;

  & + & {
    margin-top: var(--spacing-04);

    @include breakpoint('medium+') {
      margin-top: var(--spacing-06);
    }

    @include breakpoint('large+') {
      margin-top: var(--spacing-07);
    }
  }

  &.is-entering {
    opacity: 0;
  }
}

.works-grid-cascading__footer {
  display: flex;
  justify-content: center;
  margin-top: var(--spacing-08);
}

///////////////////////
// Bricklayer overrides

.works-grid-cascading {
  .bricklayer-column-sizer,
  .bricklayer-column {
    width: 50%;
  }

  @each $bp, $gutter in $inner-gutters {
    @include breakpoint($bp) {
      .bricklayer {
        margin: {
          left: -$gutter / 2;
          right: -$gutter / 2;
        }
      }

      .bricklayer-column {
        padding: {
          left: $gutter / 2;
          right: $gutter / 2;
        }
      }
    }
  }
}
