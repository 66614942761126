.hero-standard {
  padding-top: var(--spacing-07);

  @include breakpoint('medium') {
    padding-top: var(--spacing-08);
  }

  @include breakpoint('large') {
    padding-top: var(--spacing-10);
  }

  @include breakpoint('xlarge+') {
    padding-top: var(--spacing-11);
  }
}

.hero-standard__title {
  @include grid-column(1, 8);
  @include f-display-02;

  @include breakpoint('medium+') {
    @include grid-column(1, 24);
  }

  @include breakpoint('xlarge+') {
    @include grid-column(1, 18);
  }

  @include breakpoint('xxlarge') {
    @include grid-column(1, 16);
  }
}

.hero-standard__img {
  @include grid-column(1, 8);
  @include img-container($ratio-1x1);
  width: calc(100% + 32px);
  margin-top: var(--spacing-03);
  margin-left: -16px;

  @include breakpoint('medium+') {
    @include grid-column(1, 24);
    @include img-container($ratio-13x5);
  }
}

.hero-standard__nav {
  @include grid-column(1, 8);
  margin-top: var(--spacing-07);

  @include breakpoint('medium+') {
    @include grid-column(1, 24);
    margin-top: var(--spacing-08);
  }
}
.hero-standard__text {
  @include grid-column(1, 8);
  @include f-subheading-01;
  margin-top: var(--spacing-04);

  @include breakpoint('medium+') {
    @include grid-column(1, 20);
  }

  @include breakpoint('large') {
    @include grid-column(1, 20);
  }

  @include breakpoint('xlarge') {
    @include grid-column(1, 18);
  }

  @include breakpoint('xxlarge') {
    @include grid-column(1, 16);
  }
}
