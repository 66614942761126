
.modal-video {

  --video-ratio: 100%;

  background: rgba(0,0,0,0.8);
  bottom: 0;
  display: none;
  left: 0;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1000;

  &.is-visible {
    display: block;
  }

  &__close {
    cursor: pointer;
    position: absolute;
    right: var(--spacing-03);
    top: var(--spacing-03);
    z-index: 10;
  }

  &__iframe {
    background: var(--color-black);
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;

    &:after {
      content: "";
      display: block;
      padding-bottom: var(--video-ratio);
    }

    iframe {
      height: 100%;
      position: absolute;
      width: 100%;
    }
  }
}
