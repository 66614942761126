/*
  Shades.
  Don't use them directly inside code.
*/

:root {
  --color-garden-05: #CCF0DC;
  --color-garden-10: #99E1B8;
  --color-garden-20: #66D395;
  --color-garden-30: #33C471;
  --color-garden-40: #00B54E;
  --color-garden-50: #009E44;
  --color-garden-60: #00883B;
  --color-garden-70: #007131;
  --color-garden-80: #004F22;
  --color-garden-90: #002911;

  --color-azur-05: #CCEDF6;
  --color-azur-10: #99DBEC;
  --color-azur-20: #66CAE3;
  --color-azur-30: #33B8D9;
  --color-azur-40: #00A6D0;
  --color-azur-50: #0091B6;
  --color-azur-60: #007D9C;
  --color-azur-70: #006882;
  --color-azur-80: #00495C;
  --color-azur-90: #002933;

  --color-electric-05: #E7EEFF;
  --color-electric-10: #A3CAF7;
  --color-electric-20: #76AFF3;
  --color-electric-30: #4895EF;
  --color-electric-40: #1A7AEB;
  --color-electric-50: #176BCE;
  --color-electric-60: #145CB0;
  --color-electric-70: #104C93;
  --color-electric-80: #0C3669;
  --color-electric-90: #082649;

  --color-white: #FFFFFF;
  --color-gray-05: #F6F6F5;
  --color-gray-10: #E9E9E7;
  --color-gray-20: #DDDDDA;
  --color-gray-30: #D0D0CC;
  --color-gray-40: #B0B3AF;
  --color-gray-50: #888C8C;
  --color-gray-60: #747676;
  --color-gray-70: #5A5C5C;
  --color-gray-80: #303030;
  --color-gray-90: #1D1D1D;
  --color-black: #000000;
}

/*
  Usages.
  Use these ones inside your .scss files.
*/

:root {
  --color-background: var(--color-gray-10);
  --color-background-dark: var(--color-gray-90);
  --color-background-darker: var(--color-gray-10);
  --color-background-light: var(--color-white);
  --color-background-highlight: var(--color-garden-30);

  --color-layer: rgba(0, 0, 0, 0.2);

  --color-text: var(--color-gray-90);
  --color-text-light: var(--color-white);
  --color-text-alt: var(--color-gray-60);
  --color-text-disable: var(--color-gray-40);
  --color-text-highlight: var(--color-garden-30);
  --color-text-online: var(--color-electric-40);

  --color-border: var(--color-gray-20);
  --color-border-dark: var(--color-gray-60);

  --color-error: #FF0033;

  --color-sticker: var(--color-electric-40);

  --color-focus: #119BFF;

  --affluence-low: var(--color-gray-20);
  --affluence-regular: var(--color-gray-40);
  --affluence-busy: var(--color-gray-60);
  --affluence-full: var(--color-gray-90);
}
