.hero-explore-online-detail {
  padding-top: var(--spacing-07);

  @include breakpoint('medium') {
    padding-top: var(--spacing-08);
  }

  @include breakpoint('large+') {
    padding-top: var(--spacing-10);
  }

  @include breakpoint('xlarge+') {
    padding-top: var(--spacing-11);
  }
}

.hero-explore-online-detail__title {
  @include f-display-04;
  margin-bottom: var(--spacing-07);
  word-break: break-word;

  @include breakpoint('small') {
    @include grid-column(1, 8);
  }

  @include breakpoint('medium+') {
    @include grid-column(3, 16);
    margin-bottom: var(--spacing-06);
  }

  @include breakpoint('large') {
    @include grid-column(3, 16);
    margin-bottom: var(--spacing-07);
  }

  @include breakpoint('xlarge') {
    @include grid-column(4, 14);
    margin-bottom: var(--spacing-08);
  }

  @include breakpoint('xxlarge') {
    @include grid-column(5, 14);
    margin-bottom: var(--spacing-09);
  }
}

.hero-explore-online-detail__details {
  display: flex;
  flex-direction: column;
  margin-top: var(--spacing-06);
  margin-bottom: var(--spacing-06);

  @include breakpoint('small') {
    @include grid-column(1, 8);
  }

  @include breakpoint('medium') {
    @include grid-column(3, 20);
  }

  @include breakpoint('medium+') {
    position: relative;
    margin-top: var(--spacing-09);
    margin-bottom: var(--spacing-06);
  }

  @include breakpoint('large+') {
    flex-direction: row;
    @include grid-column(3, 20);
  }

  @include breakpoint('xlarge') {
    @include grid-column(4, 18);
  }

  @include breakpoint('xxlarge') {
    @include grid-column(5, 16);
  }
}

.hero-explore-online-detail__details-video {
  flex-shrink: 0;

  @include breakpoint('large+') {
    width: 33%;
  }
}

.hero-explore-online-detail__label {
  @include f-body;
  color: var(--color-gray-60);
  float: left;
  width: 33%;

  @include breakpoint('large+') {
    float: none;
    width: 100%;
  }
}
.hero-explore-online-detail__value {
  @include f-body;
  margin-bottom: var(--spacing-05);
}

.hero-explore-online-detail__text {
  @include f-subheading-02;

  a {
    border-bottom: 1px solid var(--color-white);

    &:hover {
      border-bottom: none;
    }
  }
  ul {
    list-style: disc;
    list-style-position: inside;
  }
}

.hero-explore-online-detail__text-value {
  display: block;

  & + & {
    margin-top: var(--spacing-01);
  }

  &--category {
    color: var(--color-text-alt);
  }

  @include breakpoint('large+') {
    & + & {
      margin-top: var(--spacing-02);
    }
  }
}

.hero-explore-online-detail__media {
  position: relative;

  @include breakpoint('small') {
    @include grid-column(1, 8);
    @include img-container($ratio-1x1);
  }

  @include breakpoint('medium+') {
    @include grid-column(3, 20);
    @include img-container($ratio-2x1);
  }

  @include breakpoint('xlarge+') {
    @include grid-column(4, 18);
  }
  @include breakpoint('xxlarge') {
    @include grid-column(5, 16);
  }
}

// Taken from .hero-standard__text
.hero-explore-online-detail__chapeau {
  @include f-subheading-03;
  margin-bottom: var(--spacing-06);
}

.hero-explore-online-detail__text {
  @include f-body;
}

