.program__item {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  padding: var(--spacing-05) 0;
  border-top: 1px solid var(--color-border);

  &:last-child {
    border-bottom: 1px solid var(--color-border);
  }
}

.program__left {
  width: 165px;

  @include breakpoint('medium+') {
    padding-right: var(--spacing-05);
  }
}

.program__right {
  flex: 1;
}

.program__subitem {
  & + & {
    margin-top: var(--spacing-05);
    padding-top: var(--spacing-05);
    border-top: 1px solid var(--color-border);
  }
}

.program__subitem-container {
  position: relative;
  padding-right: 170px;
}

.program__date {
  @include f-heading-05;
}

.program__hour {
  @include f-label;
  padding-top: 2px;
  color: var(--color-text-alt);
}

.program__subtitle {
  margin-top: var(--spacing-02);
}

.program__link {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
