.dropdown {
  position: relative;
  display: inline-flex;

  &:before {
    content: '';
    position: absolute;
    top: -4px;
    left: -4px;
    bottom: -4px;
    right: -4px;
    display: none;
    border: 2px solid var(--color-focus);
    border-radius: 40px;
  }

  &.is-focus {
    &:before {
      display: block;
    }
  }
}

.dropdown__input {
  @include reset-input;
  @include f-label;
  color: var(--color-text);
  display: block;
  width: 100%;
  height: 40px;
  padding: 0 var(--spacing-07) 0 var(--spacing-05);
  border: 1px solid var(--color-border);
  border-radius: 40px;
}

.dropdown__custom {
  position: relative;
}

.dropdown__custom-btn {
  @include reset-btn;
  @extend .link;
  @extend .link--icon;
}

.dropdown__custom-box {
  position: absolute;
  top: calc(100% + var(--spacing-03));
  left: 0;
  width: 228px;
  padding: var(--spacing-04);
  opacity: 0;
  pointer-events: none;
  background-color: var(--color-background-light);
  box-shadow: 0 0 10px rgba(0, 0, 0, .15);
  transform: translateY(5px);
  transition: opacity var(--transition-link), transform var(--transition-link);
  z-index: 10;

  .button {
    width: 100%;
  }
}

.dropdown__custom-item {
  & + & {
    margin-top: var(--spacing-02);
  }
}

/*********************
      State
*********************/

.dropdown {
  &.is-open {
    .icon--chevron-down {
      transform: rotate(180deg);
    }

    .dropdown__custom-panel,
    .dropdown__custom-box {
      opacity: 1;
      pointer-events: auto;
      transform: none;
    }
  }
}

/*********************
      Modifiers
*********************/

.dropdown--native {
  .icon--chevron-down {
    position: absolute;
    top: var(--spacing-02);
    right: var(--spacing-03);
    pointer-events: none;
  }
}
