
.card-flv-play-video {
  height: 100%;

  .card-flv-play-video__img {
    height: 100%;
    position: relative;
    width: auto;

    &:before {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
      bottom: 0;
      content: "";
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      transition: background var(--transition-card-flv);
    }

    .icon {
      height: 30px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 30px;

      @include breakpoint('medium+') {
        height: 70px;
        width: 70px;
      }
    }
  }

  &:hover {
    .card-flv-play-video__img:before {
      background: transparent;
    }
  }

  iframe {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    height: 100%;
    width: 100%;
  }

  &.show-video iframe {
    display: block;
  }

  img {
    max-height: 100%;
  }

  @include breakpoint('small') {
    .is-mobile-only {
      display: block;
    }
    .is-desktop-only {
      display: none;
    }
  }

  @include breakpoint('medium+') {
    .is-mobile-only {
      display: none;
    }
    .is-desktop-only {
      display: block;
    }
  }

  .card-flv-play-video__caption {
    @include f-caption;
    margin-top: var(--spacing-02);
    max-width: 200px;

    @include breakpoint('medium') {
      max-width: 275px;
    }

    @include breakpoint('large+') {
      max-width: 343px;
    }
  }
}
