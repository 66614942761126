.hero-functional {
  padding-top: var(--spacing-07);

  @include breakpoint('medium') {
    padding-top: var(--spacing-08);
  }

  @include breakpoint('large') {
    padding-top: var(--spacing-10);
  }

  @include breakpoint('xlarge+') {
    padding-top: var(--spacing-11);
  }
}

.hero-functional__title {
  @include f-display-03;
  @include grid-column(1, 8);

  @include breakpoint('medium+') {
    @include grid-column(3, 20);
  }

  @include breakpoint('xlarge') {
    @include grid-column(4, 18);
  }

  @include breakpoint('xxlarge') {
    @include grid-column(5, 16);
  }
}

.hero-functional__text,
.hero-functional__secondary-text,
.hero-functional__link {
  @include grid-column(1, 8);
  margin-top: var(--spacing-06);

  @include breakpoint('medium+') {
    @include grid-column(3, 20);
    margin-top: var(--spacing-07);
  }

  @include breakpoint('medium+') {
    @include grid-column(3, 16);
  }

  @include breakpoint('xlarge') {
    @include grid-column(4, 13);
  }

  @include breakpoint('xxlarge') {
    @include grid-column(5, 12);
  }
}

.hero-functional__secondary-text {
  margin-top: var(--spacing-03);

  @include breakpoint('medium+') {
    margin-top: var(--spacing-04);
  }
}

.hero-functional__anchor {
  @include grid-column(1, 8);

  @include breakpoint('medium+') {
    @include grid-column(3, 20);
  }

  @include breakpoint('xlarge') {
    @include grid-column(4, 18);
  }

  @include breakpoint('xxlarge') {
    @include grid-column(5, 16);
  }
}
