.hero-artwork__grid {
  @include breakpoint('medium+') {
    @include grid;
    grid-template-rows: grid-repeat(1, 1fr);
    align-items: center;
  }
}

.hero-artwork__image-container {
  @include img-container($ratio: 1);
  position: relative;
  display: flex;
  padding-top: var(--spacing-07);
  padding-bottom: var(--spacing-07);

  &__inner {
    display: flex;
    align-items: center;
    width: 100%;
    transition: opacity 0.3s ease-out;
    position: relative;

    &.is-fading {
      opacity: 0;
    }

    @include breakpoint('small') {
      // Prevent relayout on image switch
      padding-top: 100%;

      .image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    @include breakpoint('medium+') {
      height: 100%;
    }
  }

  .image__container {
    // Removes preload, as it looks wrong with object-fit
    background-image: none !important;
  }

  .image img {
    object-fit: contain;
    transition: none;

    &.is-loading {
      opacity: 0;
    }
  }


  @include breakpoint('medium+') {
    @include grid-column(15, 8);
    grid-row: 1;
    transition: opacity var(--transition-panel);
    height: calc(100vh - var(--height-header));

    .is-zoomed & {
      opacity: 0;
      pointer-events: none;
    }

    .image {
      height: 100%;
      // pos:abs for hero-artwork.js
      // (stacked images when fading/switching)
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;

      &__container {
        padding-bottom: 0 !important;
        height: 100%;
      }
    }
  }

  @include breakpoint('large') {
    @include grid-column(14, 9);
  }

  @include breakpoint('xlarge+') {
    @include grid-column(12, 12);
  }
}


.hero-artwork__zoom-container {
  position: relative;

  @include breakpoint('small') {
    display: none !important;
  }

  @include breakpoint('medium+') {
    @include grid-column(14, 11);
    grid-row: 1;
    height: calc(100vh - var(--height-header));
    overflow: hidden;
    transition: opacity var(--transition-panel);

    &__inner {
      height: 100%;
      display: flex;
      align-items: flex-start;
    }

    .image {
      opacity: 0;
      pointer-events: none;

      .is-zoomed & {
        opacity: 1;
      }
    }
  }

  @include breakpoint('large+') {
    @include grid-column(12, 13);
  }

  .image {
    width: 100%;
    height: 100%;

    &__container {
      width: 100%;
      height: 100%;
      overflow-x: hidden;
      overflow-y: hidden;
      background-image: none !important;
    }

    &__img {
      width: auto;
      height: auto;
    }

    &.is-portrait .image__img {
      min-width: 100%;
    }

    &.is-landscape .image__img {
      min-height: 100%;
    }
  }
}

.hero-artwork__zoom-mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  cursor: none;
  user-select: none;
  z-index: 9999;
}

.hero-artwork__zoom-cursor {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.3s ease;

  .is-hovered & {
    opacity: 1;
  }

  .icon--minus {
    display: none;
  }

  .is-zoomed & {
    .icon--plus {
      display: none;
    }

    .icon--minus {
      display: block;
    }
  }
}

.hero-artwork__zoom-cursor__inner {
  transform: translate(-50%, -50%);
  color: #fff;
  width: 44px;
  height: 44px;
  border: 1px solid #fff;
  border-radius: 999px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-artwork__body {
  @include breakpoint('small') {
    margin-top: var(--spacing-05);
  }

  // width: 50%;

  @include breakpoint('medium+') {
    grid-row: 1;
  }

  @include breakpoint('medium') {
    @include grid-column(3, 11);
  }

  @include breakpoint('large') {
    @include grid-column(3, 9);
  }

  @include breakpoint('xlarge+') {
    @include grid-column(4, 7);
  }
}

.hero-artwork__title {
  @include f-heading-02;
}

.hero-artwork__description-main {
  @include f-body;
  margin-top: var(--spacing-02);
}

.hero-artwork__description-aux {
  @include f-caption;
  color: var(--color-text-alt);
  margin-top: var(--spacing-02);
}

.hero-artwork__thumbs {
  margin-left: calc(0px - var(--spacing-04));
  margin-top: calc(0px - var(--spacing-04));
  padding-top: var(--spacing-07);
  display: flex;
  flex-wrap: wrap;

  li {
    width: 60px;
    margin-left: var(--spacing-04);
    margin-top: var(--spacing-04);
  }
}

.hero-artwork__thumb {
  .image {
    position: relative;
    overflow: visible;

    &::after {
      content: '';
      height: 1px;
      bottom: calc(0px - var(--spacing-02));
      position: absolute;
      left: 0;
      right: 0;
      background-color: var(--color-black);
      opacity: 0;
      transition: opacity 0.1s ease;
    }
  }

  &.is-current .image::after {
    opacity: 1;
  }
}
