.quote__text {
  @include f-subheading-01;
}

.quote__footer {
  margin-top: var(--spacing-05);
}

.quote__img {
  display: block;
  width: 140px;

  .image {
    display: block;
    max-width: 100%;
    width: auto;
  }
}
