
.works-grid-tabbed__tabs {
  display: flex;
}

.works-grid-tabbed__tab-panel {
  display: none;
  margin-top: var(--spacing-07);

  @include breakpoint('large+') {
    margin-top: var(--spacing-08);
  }

  &.is-visible {
    display: block;
  }
}
