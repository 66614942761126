:root {
  --tooltip-safe-area: 50px;
}

.tooltip {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-left: var(--spacing-02);

  &.is-open {
    z-index: 4;

    .tooltip__btn {
      &::before {
        opacity: 1;
        pointer-events: auto;
      }
    }

    .tooltip__content {
      opacity: 1;
      transform: translate(-50%, 0);
      pointer-events: auto;
    }
  }

  @include breakpoint('small') {
    &.is-open {
      .tooltip__content {
        transform: none;
      }
    }
  }
}

.tooltip__btn {
  @include reset-btn;
  position: relative;
  display: block;
  z-index: 3;

  .icon {
    display: block;
  }
}

.tooltip__content {
  @include f-caption;
  display: block;
  opacity: 0;
  z-index: 2;
  pointer-events: none;

  @include breakpoint('small') {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 0 var(--spacing-06);
    background-color: var(--color-layer);
    backdrop-filter: blur(var(--backdrop-filter-blur));
    z-index: 200;
  }

  @include breakpoint('medium+') {
    position: absolute;
    bottom: calc(100% + var(--spacing-04));
    left: 50%;
    transform: translate(-50%, 5px);

    &::before {
      content: '';
      position: absolute;
      bottom: calc(var(--tooltip-safe-area) * -1);
      left: 0;
      display: block;
      width: 100%;
      height: var(--tooltip-safe-area);
      background-color: transparent;
      z-index: -1;
    }
  }
}

.tooltip__triangle {
  @include breakpoint('medium+') {
    position: absolute;
    bottom: -4px;
    left: 50%;
    display: block;
    width: 12px;
    height: 12px;
    background-color: var(--color-background);
    transform: translate(-50%, 0) rotate(45deg);

    &--top {
      background-color: var(--color-background);
      z-index: 3;
    }
  }
}

.tooltip__content-container {
  position: relative;
  display: block;
  color: var(--color-text-alt);
  border-radius: 4px;
  z-index: 2;

  p + p {
    margin-top: var(--spacing-02);
  }

  strong {
    color: var(--color-text);
  }

  @include breakpoint('small') {
    width: 100%;
    padding: var(--spacing-07) var(--spacing-05);
    background-color: var(--color-background-light);
    transform: none !important; // Re-write JS behavior
  }

  @include breakpoint('medium+') {
    width: 295px;
    padding: var(--spacing-05);
    background-color: var(--color-background);
  }
}

.tooltip__close {
  @include reset-btn;
  position: absolute;
  top: var(--spacing-04);
  right: var(--spacing-04);
  color: var(--color-text);

  .icon {
    display: block;
  }

  @include breakpoint('medium+') {
    display: none;
  }
}

/*******************
    Modifiers
*******************/

.tooltip--right {
  @include breakpoint('medium+') {
    .tooltip__content {
      top: 50%;
      left: calc(100% + var(--spacing-04));
      transform: translate(0, -50%);

      &::before {
        top: 0;
        left: calc(var(--tooltip-safe-area) * -1);
        width: var(--tooltip-safe-area);
        height: 100%;
      }
    }

    .tooltip__triangle {
      top: 50%;
      left: -4px;
      transform: translate(0, -50%) rotate(45deg);
    }
  }
}

.tooltip--left {
  @include breakpoint('medium+') {
    .tooltip__content {
      top: 50%;
      left: auto;
      right: calc(100% + var(--spacing-04));
      transform: translate(0, -50%);

      &::before {
        top: 0;
        left: auto;
        right: calc(var(--tooltip-safe-area) * -1);
        width: var(--tooltip-safe-area);
        height: 100%;
      }
    }

    .tooltip__triangle {
      top: 50%;
      left: auto;
      right: -4px;
      transform: translate(0, -50%) rotate(45deg);
    }
  }
}

.tooltip--bottom {
  @include breakpoint('medium+') {
    .tooltip__content {
      bottom: auto;
      top: calc(100% + var(--spacing-04));

      &::before {
        bottom: auto;
        top: calc(var(--tooltip-safe-area) * -1);
      }
    }

    .tooltip__triangle {
      bottom: auto;
      top: -4px;
    }
  }
}
