.block--quick_access {
  .block__cell {
    @include grid-column(1, 8);
  }

  .block-quick-access {
    @include grid-container;
    @include grid-columns(grid-repeat(2, 1fr));
    @include grid-column-gap(var(--spacing-04));
    @include grid-row-gap(var(--spacing-07));
  }

  @include breakpoint('medium+') {
    .block__cell {
      @include grid-column(3, 20);
    }
  }

  @include breakpoint('xlarge+') {
    .block__cell {
      @include grid-column(4, 18);
    }
  }
}
