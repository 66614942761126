/**
 * @TODO -- See if it's possible to import a css file, treated as a sass file
 * and use '../node_modules/bricklayer/dist/bricklayer.min'
 * in the app.scss
 */

// https://raw.githubusercontent.com/ademilter/bricklayer/master/dist/bricklayer.css
// version 0.4.3

.bricklayer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.bricklayer-column-sizer {
  width: 100%;
  display: none;
}

@media screen and (min-width: 640px) {
  .bricklayer-column-sizer {
    width: 50%;
  }
}

@media screen and (min-width: 980px) {
  .bricklayer-column-sizer {
    width: 33.333%;
  }
}

@media screen and (min-width: 1200px) {
  .bricklayer-column-sizer {
    width: 25%;
  }
}

.bricklayer-column {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding-left: 5px;
  padding-right: 5px;
}
