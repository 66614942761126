.pagination {
  margin-top: var(--spacing-07);

  @include breakpoint('medium+') {
    margin-top: var(--spacing-09);
  }
}

.pagination__list {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

.pagination__item {
  margin: 0 var(--spacing-01);
}

.pagination__link {
  @include f-caption;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  border: 1px solid transparent;
  border-radius: 50%;
  transition: color var(--transition-link);

  &:hover {
    color: var(--color-text-alt);
  }

  &.is-active {
    border-color: var(--color-border);
  }
}
