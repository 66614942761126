.tool__container {
  @include grid;
}

.tool__top {
  @include grid-column(1, 8);

  @include breakpoint('medium') {
    @include grid-column(3, 20);
  }

  @include breakpoint('large') {
    @include grid-column(9, 14);
  }

  @include breakpoint('xlarge+') {
    @include grid-column(9, 13);
  }
}

.tool__title {
  @include f-heading-04;
  position: relative;

  .icon {
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    transform: translateY(-50%);
  }
}

.tool__text {
  margin-top: var(--spacing-05);
}

.tool__action {
  margin-top: var(--spacing-03);

  .link + .link {
    margin-left: var(--spacing-05);
  }
}

.tool__item {
  margin-top: var(--spacing-06);
}

/*********************
      Modifiers
*********************/

.tool--icon {
  .tool__title {
    padding-left: 54px;
  }
}

.blocks--detail {
  .tool--icon {
    .tool__top {
      padding-left: 72px;
    }
    .tool__title {
      @include f-heading-05;
      padding-left: 0;

      .icon {
        left: -72px;
        top: 0;
        transform: none;
      }
    }
    .tool__text {
      @include f-body;
      margin-top: var(--spacing-02);
    }
  }
}
