.caption {
  @include f-caption;
  color: var(--color-text-alt);

  & span[data-caption-index] {
    transition: all var(--transition-panel);
    opacity: 0;
    position: absolute;
  }

  & span[data-caption-index].is-active {
    opacity: 1;
  }
}
