.video.is-pointer-disabled {
  pointer-events: none;
}

.video__container {
  position: relative;
  width: 100%;
  padding-bottom: #{100 / $ratio-16x9 + '%'};
  background-color: var(--color-background);

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
  }
}

.video__cover {
  // Above iframe
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: var(--color-text-light);
  background-color: var(--color-gray-90);
  overflow: hidden;
  display: flex;
  align-items: center;
  transition: opacity .35s ease;
  cursor: pointer;
}

.video.is-playing .video__cover {
  opacity: 0;
  pointer-events: none;
}

.video__cover::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--color-layer);
}

.video__cover .icon--play-large {
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.video__credit {
  @include f-caption;
  color: var(--color-text-alt);
  margin-top: var(--spacing-02);
}

.video__content {
  @include f-caption;
  @include grid;
  margin-top: var(--spacing-04);
}
.video__caption {
  color: var(--color-gray-40);
  @include grid-column(1, 14);

  @include breakpoint('medium') {
    @include grid-column(1, 24);
  }
}
