.nav {
  @include breakpoint('small') {
    width: 100%;
    padding: var(--spacing-06) var(--spacing-04);
  }

  @include breakpoint('medium+') {
    width: 390px;
    padding: var(--spacing-08) var(--spacing-06);
  }

  @include breakpoint('large-') {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    background-color: var(--color-background-light);
    overflow: hidden;
    z-index: var(--z-index-nav-mobile);
  }

  @include breakpoint('xlarge+') {
    width: 100%;
  }
}

.nav__close {
  display: none;

  @include breakpoint('medium+') {
    @include reset-btn;
    position: absolute;
    top: 10px;
    right: 6px;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
  }

  @include breakpoint('xlarge+') {
    display: none;
  }
}

.nav__layer {
  display: none;

  @include breakpoint('medium+') {
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: var(--color-layer);
    opacity: 0;
    pointer-events: none;
    z-index: var(--z-index-nav-mobile) - 1;
    transition: opacity var(--transition-sidebar);

    .is-nav-open & {
      opacity: 1;
      pointer-events: auto;
    }
  }

  @include breakpoint('xlarge+') {
    display: none;
  }
}

/* Nav tablet (duplicated only for large breakpoint) */

.nav__tablet {
  display: none;

  @include breakpoint('large') {
    display: block;
  }
}

.nav__tablet-list {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.nav__tablet-item {
  & + & {
    margin-left: var(--spacing-05);
  }
}

.nav__tablet-item--primary {
  &:nth-child(3),
  &:nth-child(4),
  &:nth-child(5) {
    display: none;
  }
}

.nav__tablet-link {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 100%;
    display: block;
    height: 1px;
    background-color: currentColor;
    transition: right var(--transition-link);
  }

  &:hover,
  &.is-active {
    &:after {
      right: 0;
    }
  }
}

.nav__tablet-link--secondary {
  color: var(--color-text-alt);
}

/* Main nav */

.nav__list {
  @include breakpoint('xlarge+') {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  }
}

.nav__item {
  &--secondary {
    margin-top: var(--spacing-06);
  }

  @include breakpoint('large-') {
    & + & {
      margin-top: var(--spacing-04);
    }

    &.nav__item--secondary {
      margin-top: var(--spacing-06);

      & + & {
        margin-top: var(--spacing-04);
      }
    }
  }

  @include breakpoint('xlarge+') {
    & + & {
      margin-left: var(--spacing-05);
    }

    &.nav__item--secondary {
      margin-top: 0;

      & + & {
        margin-top: 0;
      }
    }

    &--mobile {
      display: none;
    }
  }
}

.nav__link {
  @include f-nav-link;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 100%;
    display: block;
    height: 1px;
    background-color: currentColor;
    transition: right var(--transition-link);
  }

  &:hover,
  &.is-active {
    &:after {
      right: 0;
    }
  }

  & + & {
    margin-left: var(--spacing-06);
  }

  &--secondary {
    @include f-nav-link-secondary;

    @include breakpoint('large+') {
      color: var(--color-text-alt);
    }
  }

  &--lang {
    @include f-nav-link-secondary;
    color: var(--color-text-alt);

    &:hover,
    &.is-active {
      color: var(--color-text);
    }
  }

  @include breakpoint('large+') {
    display: inline-block;

    &:before {
      content: '';
      position: absolute;
      top: -4px;
      left: -4px;
      bottom: 0;
      right: -4px;
      display: none;
      border: 1px solid var(--color-text);
    }

    @include focus {
      &:before {
        display: block;
      }
    }
  }
}


/*********************
       State
*********************/

.nav {
  @include breakpoint('small') {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: opacity .35s ease, visibility .35s ease;
  }

  @include breakpoint('medium+') {
    transform: translateX(100%);
    transition: transform var(--transition-sidebar);
  }

  @include breakpoint('large-') {
    .nav__item {
      opacity: 0;
      transform: translateY(-10px);
    }
  }

  @include breakpoint('xlarge+') {
    padding: 0;
    transform: none;
  }
}

.is-nav-open {
  @include breakpoint('small') {
    .nav {
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
    }
  }

  @include breakpoint('medium+') {
    .nav {
      transform: translateX(0px);
    }
  }

  @include breakpoint('large-') {
    .nav__item {
      opacity: 1;
      transform: translateY(0);
      transition: opacity .3s ease, transform .3s ease;

      @for $i from 1 through 10 {
        &:nth-child(#{$i}) {
          transition-delay: 0.075s*$i + 0.25s;
        }
      }
    }

    .header__toggle-icon {
      .icon--burger {
        opacity: 0;
      }

      .icon--close {
        opacity: 1;
      }
    }

    .header__toggle-label-open {
      display: none;
    }

    .header__toggle-label-close {
      display: block;
    }
  }
}
