.cart {
  margin-top: var(--spacing-05);
  opacity: 0;

  .page__products {
    margin-top: 0;
  }

  @include breakpoint('medium+') {
    margin-top: var(--spacing-07);
  }
}

.cart__left {
  @include grid-column(1, 8);

  @include breakpoint('medium') {
    @include grid-column(3, 20);
  }

  @include breakpoint('large') {
    @include grid-column(3, 13);
  }

  @include breakpoint('xlarge') {
    @include grid-column(4, 12);
  }

  @include breakpoint('xxlarge') {
    @include grid-column(5, 11);
  }
}

.cart__empty {
  display: none;
}

.cart__right {
  @include grid-column(1, 8);
  margin-top: var(--spacing-08);

  @include breakpoint('medium+') {
    @include grid-column(7, 16);
  }

  @include breakpoint('large+') {
    @include grid-column(17, 6);
    margin-top: 0;
  }

  @include breakpoint('xlarge') {
    @include grid-column(17, 5);
  }

  @include breakpoint('xxlarge') {
    @include grid-column(17, 4);
  }
}

.cart__item {
  padding-top: var(--spacing-06);
  border-top: 1px solid var(--color-border);

  & + & {
    margin-top: var(--spacing-07);
  }
}


/* Aside */

.cart__aside {
  padding: var(--spacing-04);
  border: 1px solid var(--color-border);
}

.cart__aside-title {
  @include f-heading-05;
}

.cart__aside-total {
  @include f-caption;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-top: var(--spacing-04);
  padding-top: var(--spacing-04);
  border-top: 1px solid var(--color-border-dark);
}

.cart__aside-btn {
  margin-top: var(--spacing-06);
}

.cart__aside-text {
  @include f-caption;
  margin-top: var(--spacing-06);
  color: var(--color-text-alt);
}


/*********************
       State
*********************/

.cart.is-visible {
  opacity: 1;
}

.cart.is-empty {
  .cart__empty {
    display: block;
  }

  .cart__aside,
  .cart__aside-btn,
  .cart__aside-text {
    display: none;
  }
}
