.accordion {
  padding: var(--spacing-05) 0;
  border-bottom: 1px solid var(--color-border);

  &:first-of-type {
    border-top: 1px solid var(--color-border);
  }
}

.accordion__btn {
  @include reset-btn;
  @include f-heading-05;
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  align-items: top;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  padding-right: 40px;
  white-space: normal;

  @include focus {
    outline: 1px solid var(--color-text);
  }
}

.accordion__btn-title {
  padding-right: var(--spacing-05);
  text-align: left;
}

.accordion__btn-value {
  text-align: right;
}

.accordion__btn-icon {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 24px;
  height: 24px;

  .icon {
    position: absolute;
    top: -2px;
    left: 0;
    display: block;
    transition: opacity var(--transition-accordion);
  }

  .icon--minus {
    opacity: 0;
  }

  @include breakpoint('medium+') {
    .icon {
      top: 1px;
    }
  }

  @include breakpoint('xxlarge') {
    .icon {
      top: 3px;
    }
  }
}

.accordion__content {
  height: 0;
  overflow: hidden;
  transition: height var(--transition-accordion);

  .button {
    margin-top: var(--spacing-05);
    margin-bottom: var(--spacing-02);
  }
}

.accordion__wrapper {
  padding-bottom: 2px;
}

.accordion__text {
  padding-top: var(--spacing-05);
  opacity: 0;
  transition: opacity var(--transition-accordion);
}

.accordion__video {
  margin-top: var(--spacing-08);
  opacity: 0;
  pointer-events: none;
  transition: opacity var(--transition-accordion);
}

/*********************
      Modifiers
*********************/

.accordion {
  &.is-open {
    .accordion__text,
    .accordion__video {
      opacity: 1;
      pointer-events: auto;
      transition-delay: 0.25s;
    }

    .accordion__btn-icon {
      .icon--plus {
        opacity: 0;
      }

      .icon--minus {
        opacity: 1;
      }
    }
  }
}
