.card-resource__container {
  @include grid;
}

.card-resource__left {
  @include grid-column(1, 5);

  @include breakpoint('medium') {
    @include grid-column(3, 13);
  }

  @include breakpoint('large') {
    @include grid-column(9, 9);
  }

  @include breakpoint('xlarge+') {
    @include grid-column(9, 8);
  }
}

.card-resource__img {
  @include img-container($ratio-1x1);

  @include breakpoint('medium+') {
    @include img-container($ratio-3x2);
  }
}

.card-resource__right {
  @include grid-column(6, 3);

  @include breakpoint('medium') {
    @include grid-column(17, 6);
  }

  @include breakpoint('large') {
    @include grid-column(19, 4);
  }

  @include breakpoint('xlarge+') {
    @include grid-column(18, 4);
  }
}

.card-resource__title {
  @include f-heading-05;
}

.card-resource__text {
  margin-top: var(--spacing-03);
}

.card-resource__link {
  margin-top: var(--spacing-03);
}
