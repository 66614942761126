.list-event__item {
  & + & {
    margin-top: var(--spacing-08);
  }

  @include breakpoint('medium+') {
    & + & {
      margin-top: var(--spacing-10);
    }
  }
}

.list-event__title {
  @include grid-column(1, 8);
  @include f-heading-04;
  margin-bottom: var(--spacing-06);

  @include breakpoint('medium+') {
    @include grid-column(3, 20);
    margin-bottom: var(--spacing-09);
  }

  @include breakpoint('xlarge') {
    @include grid-column(4, 18);
  }

  @include breakpoint('xxlarge') {
    @include grid-column(5, 16);
  }
}

.list-event__footer {
  margin-top: var(--spacing-07);

  @include breakpoint('large+') {
    margin-top: var(--spacing-08);
  }
}

.list-event__message {
  margin-top: var(--spacing-08);
}


/*********************
      Modifiers
*********************/

.list-event--pad {
  padding-top: var(--spacing-07);

  @include breakpoint('medium+') {
    padding-top: var(--spacing-10);
  }

  @include breakpoint('xlarge+') {
    padding-top: var(--spacing-12);
  }
}

.list-event--program {
  padding-top: var(--spacing-08);

  @include breakpoint('large') {
    padding-top: var(--spacing-09);
  }

  @include breakpoint('xlarge+') {
    padding-top: var(--spacing-10);
  }
}

.list-event--featured {
  padding-top: 25vh;

  .list-event__item + .list-event__item {
    margin-top: var(--spacing-12);
  }
}

.push-newsletter + .list-event {
  margin-top: var(--spacing-09);

  @include breakpoint('medium+') {
    margin-top: var(--spacing-11);
  }
}
