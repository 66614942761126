.summary__text {
  @include f-subheading-02;

  > * + * {
    margin-top: var(--spacing-04);
  }
}

.summary__btn {
  margin-top: var(--spacing-05);
  display: flex;
}

.summary__links {
  margin-top: var(--spacing-06);

  @include breakpoint('medium+') {
    list-style: none;
    display: flex;
    margin-left: calc(0px - var(--spacing-04));

    > * {
      margin-left: var(--spacing-04);
    }
  }
}

.summary__sidebar-title {
  @include f-heading-04;
  margin-top: var(--spacing-07);

  @include breakpoint('medium+') {
    margin-top: var(--spacing-09);
  }
}

.summary__sidebar-text {
  margin-top: var(--spacing-05);
}
