.hero-program {
  padding-top: var(--spacing-07);

  @include breakpoint('medium') {
    padding-top: var(--spacing-08);
  }

  @include breakpoint('large') {
    padding-top: var(--spacing-10);
  }

  @include breakpoint('xlarge+') {
    padding-top: var(--spacing-11);
  }
}

.hero-program__title {
  @include grid-column(1, 8);
  @include f-display-02;

  @include breakpoint('medium+') {
    @include grid-column(1, 24);
  }
}

.hero-program__nav,
.hero-program__filter {
  @include grid-column(1, 8);
  width: calc(100% + 32px);
  margin-left: -16px;

  @include breakpoint('medium+') {
    @include grid-column(1, 24);
  }
}
