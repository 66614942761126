.image {
  width: 100%;
  min-height: 10px;
  overflow: hidden;

  source {
    display: block;
  }

  source,
  img {
    min-height: 10px;
    min-width: 10px;
  }
}

.image__container {
  background-size: cover;
  background-position: 50%;
}

.image__img {
  display: block;
  width: 100%;
}

.image__content {
  @include f-caption;
  margin-top: var(--spacing-04);
}

.image__caption {
  color: var(--color-text-alt);
}

.image__alt + .image__caption {
  margin-top: var(--spacing-01);
}

.image__content-btn {
  display: none;

  .icon--info {
    color: var(--color-text-light);
  }
}

/*********************
      Modifiers
*********************/

.image--ratio {
  @include img-container;
}

.image--caption-on-top {
  position: relative;

  .image__caption {
    position: absolute;
    bottom: 36px;
    right: 0;
    width: 280px;
    padding: var(--spacing-04);
    text-align: left;
    background-color: var(--color-background-light);
    pointer-events: none;
    opacity: 0;
    transition: opacity var(--transition-link);
  }

  .image__content {
    position: absolute;
    bottom: var(--spacing-04);
    right: var(--spacing-04);
    z-index: 2;

    &:hover {
      .image__caption {
        opacity: 1;
      }
    }
  }

  .image__content-btn {
    @include reset-btn;
    display: inline-block;
    margin: var(--spacing-02) 0 0 auto;
  }
}
