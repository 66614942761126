.nav-internal {
  @include scroll-indicator;
  position: relative;
  display: flex;
  margin-top: var(--spacing-02);

  @include breakpoint('large+') {
    margin-top: var(--spacing-04);
  }
}

.nav-internal__scroll {
  @include custom-scrollbar;
  white-space: nowrap;
  overflow-x: auto;

  @include breakpoint('medium+') {
    padding-top: var(--spacing-04);
  }
}

.nav-internal__list {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.nav-internal__item {
  margin-right: var(--spacing-06);
  padding-top: var(--spacing-04);
  padding-bottom: var(--spacing-01);

  &:last-child {
    padding-right: var(--spacing-06);
  }
}

.nav-internal__link {
  @include reset-btn;
  @include f-heading-05;
  position: relative;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding-bottom: var(--spacing-01);
  color: var(--color-text-alt);
  border-bottom: 2px solid transparent;
  transition: color var(--transition-link), border-color var(--transition-link);

  .icon {
    position: absolute;
    top: 0;
    right: 0;
    margin-left: var(--spacing-01);
  }

  &--icon {
    padding-right: var(--spacing-06);
  }

  &:hover {
    color: var(--color-text);
  }

  &.is-active {
    color: var(--color-text);
    border-color: currentColor;
  }
}

.nav-internal__label {
  border-bottom: 2px solid transparent;

  &.is-active {
    color: var(--color-text);
    border-color: currentColor;
  }
}
