
.visit__prices-footer {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: var(--spacing-05);

  @include breakpoint('medium+') {
    flex-direction: row;
  }
}

.visit__prices-text {
  @include f-caption;
  color: var(--color-text-alt);
  max-width: 340px;
}

.visit__prices-btn {

  @include breakpoint('small') {
    margin-top: var(--spacing-04);
  }

  @include breakpoint('medium+') {
    margin-left: var(--spacing-04);
  }
}

.visit__affluence {
  & .list__item:last-child {
    border-bottom: none;
  }
}

.tpl-visit-landing {

  .list--bordered {
    border-bottom: 1px solid var(--color-border);

    .list__label {
      border-top: 1px solid var(--color-border);

      .link {
        @include f-heading-05;
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding: var(--spacing-05) 0;
      }
    }
  }

  .blocks--detail {
    margin-top: var(--spacing-09);

    @include breakpoint('large+') {
      margin-top: var(--spacing-10);
    }
    @include breakpoint('xxlarge') {
      margin-top: var(--spacing-11);
    }

    .block--tool {
      margin-top: var(--spacing-06);

      @include breakpoint('medium+') {
        margin-top: var(--spacing-07);
      }
    }
    .block--title + .block--tool {

      @include breakpoint('xlarge+') {
        margin-top: var(--spacing-10);
      }
      @include breakpoint('xxlarge') {
        margin-top: var(--spacing-11);
      }
    }

    .block--title {
      margin-top: var(--spacing-09);
      width: 100%;

      .block__title {
        @include f-heading-03;
      }
      @include breakpoint('large+') {
        margin-top: var(--spacing-10);
      }
      @include breakpoint('xlarge+') {
        position: absolute;
      }
      @include breakpoint('xxlarge') {
        margin-top: var(--spacing-11);
      }

      .block__cell {
        @include grid-column(1, 8);

        @include breakpoint('medium+') {
          @include grid-column(3, 20);
        }

        @include breakpoint('large+') {
          @include grid-column(9, 12);
        }

        @include breakpoint('xlarge+') {
          @include grid-column(4, 5);
        }

        @include breakpoint('xxlarge+') {
          @include grid-column(5, 5);
        }
      }
    }

    .block--faq,
    .block--tool {

      .block__cell {
        @include grid-column(1, 8);

        @include breakpoint('medium') {
          @include grid-column(3, 20);
        }

        @include breakpoint('large') {
          @include grid-column(9, 14);
        }

        @include breakpoint('xlarge') {
          @include grid-column(10, 12);
        }

        @include breakpoint('xxlarge') {
          @include grid-column(11, 10);
        }
      }

      .tool__top {
        @include grid-column(1, 8);

        @include breakpoint('medium+') {
          @include grid-column(1, 24);
        }
      }

    }
  }

  .footer__content {
    @include breakpoint('large+') {
      padding-top: var(--spacing-13);
    }
  }
}
