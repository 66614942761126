body {
  .optanon-alert-box-wrapper {
    backdrop-filter: blur(var(--backdrop-filter-blur));
    background-color: rgba(#fff, 0.9) !important;
  }

  .optanon-alert-box-wrapper .optanon-alert-box-bg p,
  .optanon-alert-box-wrapper .optanon-button-more .optanon-alert-box-button-middle button,
  .optanon-alert-box-wrapper .optanon-button-more .optanon-alert-box-button-middle button:before {
    color: #000 !important;
  }

  .optanon-alert-box-wrapper .optanon-alert-box-bg p {
    @include f-body;
  }

}
