.dropdown-panel-list {
  @include f-body-ui;
  border: 1px solid var(--color-border);
  background-color: var(--color-background-light);
  color: var(--color-text);
  transform: translate(0px, 15px);
  transition: transform var(--transition-link), opacity var(--transition-link);
  opacity: 0;
  pointer-events: none;

  .is-open & {
    transform: translate(0px, 10px);
    opacity: 1;
    pointer-events: auto;
  }
}

.dropdown-panel-list__inner {
  max-height: 192px;
  overflow-y: auto;
  padding: var(--spacing-03);
}

.dropdown-panel-list a {
  display: block;
  color: var(--color-text-alt);

  &:hover, &.is-selected {
    color: var(--color-text);
  }
}
