.list__item {
  @include grid-container;
  @include grid-columns(grid-repeat(2, 1fr));
  @include grid-column-gap(var(--spacing-04));
  padding: var(--spacing-07) 0;
  border-top: 1px solid var(--color-border-dark);

  &:last-child {
    border-bottom: 1px solid var(--color-border-dark);
  }
}

.list__label,
.list__value {
  display: block;
  width: 100%;
}

.list__value {
  color: var(--color-text-alt);
}

.list__text {
  @include f-caption;
  display: block;
  width: 100%;
  margin-top: var(--spacing-02);
  color: var(--color-text-alt);
}


/*********************
      Modifiers
*********************/

.list--details {
  .list__item {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding: var(--spacing-05) 0;
    border-color: var(--color-border);
  }

  .list__label {
    width: auto;
    flex: 1;
  }

  .list__value {
    width: 160px;
    text-align: right;
  }
}

.list--infos {
  .list__item {
    display: block;
    margin-bottom: var(--spacing-04);
    padding: 0;
    border: 0;

    &:last-child {
      margin-bottom: 0;
      border: 0;
    }
  }

  .list__label {
    color: var(--color-text-alt);
  }

  .list__value {
    color: var(--color-text);
  }

  @include breakpoint('medium-') {
    .list__item {
      display: flex;
      flex-flow: row wrap;
    }

    .list__label {
      display: inline-flex;
      flex: 1;
      width: 33.33%;
      padding-right: var(--spacing-04);
    }

    .list__value {
      display: inline-flex;
      width: 66.66%;
    }
  }
}
