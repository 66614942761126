.block-carousel {
  .container {
    width: 100%;
    padding: 0;
  }
}

/*********************
     Block editor
*********************/

.block--carousel {
  @include breakpoint('medium+') {
    .block__cell {
      @include grid-column(1, 24);
    }
  }
}
