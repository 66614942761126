.footer {
  position: relative;
  background-color: var(--color-background-light);
  z-index: var(--z-index-footer);

  @include breakpoint('large-') {
    .tpl-homepage & {
      padding-bottom: 0;
    }
  }
}

.footer__content {
  padding-top: var(--spacing-09);

  @include breakpoint('medium+') {
    padding-top: var(--spacing-12);
  }
}

.footer__bottom {
  margin-top: var(--spacing-08);
  padding-bottom: var(--spacing-04);

  @include breakpoint('medium') {
    margin-top: var(--spacing-11);
  }

  @include breakpoint('large+') {
    margin-top: var(--spacing-12);
  }
}

.footer__left {
  @include grid-column(1, 8);

  @include breakpoint('medium') {
    @include grid-column(1, 10);
  }

  @include breakpoint('large+') {
    @include grid-column(1, 8);
  }
}

.footer__right {
  @include grid-column(1, 8);

  @include breakpoint('medium') {
    @include grid-column(13, 10);
  }

  @include breakpoint('large+') {
    @include grid-column(12, 13);
  }

  @include breakpoint('xlarge+') {
    @include grid-column(13, 9);
  }
}

.footer__text {
  & + & {
    margin-top: var(--spacing-04);
  }

  &--hours {
    color: var(--color-text-highlight);
  }
}

.footer__text-value {
  display: block;
}

/* Nav Primary */

.footer__nav-item {
  & + & {
    margin-top: var(--spacing-02);
  }
}

.footer__nav-link {
  @include f-heading-02;
  transition: color var(--transition-link);

  &:hover {
    color: var(--color-text-alt);
  }

  @include focus {
    outline: 1px solid var(--color-text);
  }
}

/* Nav Social */

.footer__nav-social {
  margin-top: var(--spacing-05);

  @include breakpoint('medium+') {
    margin-top: var(--spacing-06);
  }
}

.footer__nav-social-list {
  display: flex;
  flex-flow: row wrap;
  gap: var(--spacing-04);
}

// .footer__nav-social-item {
//   & + & {
//     margin-left: var(--spacing-04);
//   }
// }

.footer__nav-social-link {
  display: block;
  transition: color var(--transition-link);

  .icon {
    display: block;
  }

  &:hover {
    color: var(--color-text-alt);
  }

  @include focus {
    outline: 1px solid var(--color-text);
  }
}

/* Newsletter */

.footer__newsletter {
  @include breakpoint('small') {
    margin-top: var(--spacing-08);
  }
}

.footer__newsletter-form {
  position: relative;
  display: block;
}

.footer__newsletter-input {
  @include reset-input;
  display: block;
  width: 100%;
  height: var(--height-input);
  padding-right: var(--spacing-06);
  border-bottom: 1px solid var(--color-border);
}

.footer__newsletter-submit {
  @include reset-btn;
  position: absolute;
  top: var(--spacing-03);
  right: 0;
  display: block;

  .icon {
    display: block;
  }

  @include focus {
    outline: 1px solid var(--color-text);
  }
}

/* Infos */

.footer__infos {
  @include breakpoint('medium-') {
    margin-top: var(--spacing-07);
  }

  @include breakpoint('large+') {
    @include grid-container;
    @include grid-columns(grid-repeat(13, 1fr));
    @include grid-column-gap(16px);
    margin-top: var(--spacing-08);
  }

  @include breakpoint('xlarge+') {
    @include grid-columns(grid-repeat(9, 1fr));
  }
}

.footer__infos-cell {
  @include breakpoint('medium-') {
    & + & {
      margin-top: var(--spacing-07);
    }
  }

  @include breakpoint('large') {
    &:nth-child(1) {
      @include grid-column(1, 6);
    }

    &:nth-child(2) {
      @include grid-column(8, 6);
    }
  }

  @include breakpoint('xlarge') {
    &:nth-child(1) {
      @include grid-column(1, 5);
    }

    &:nth-child(2) {
      @include grid-column(6, 4);
    }
  }

  @include breakpoint('xxlarge') {
    &:nth-child(1) {
      @include grid-column(1, 4);
    }

    &:nth-child(2) {
      @include grid-column(6, 5);
    }
  }
}

.footer__infos-link {
  margin-top: var(--spacing-04);
}

/* Nav Secondary */

.footer__nav-secondary {
  @include breakpoint('small') {
    padding-bottom: var(--spacing-10);
  }
}

.footer__nav-secondary-list {
  display: flex;
  flex-flow: row wrap;
}

.footer__nav-secondary-item {
  margin-right: var(--spacing-05);

  &--toggle {
    display: none;
    margin-right: 0;
  }

  @include breakpoint('small') {
    &--legal {
      display: none;
    }

    &--toggle {
      display: block;
      width: 100%;
    }

    &--lang {
      width: 100%;
      margin-bottom: var(--spacing-04);
    }
  }
}

.footer__nav-secondary-toggle {
  @include reset-btn;
  @include f-caption;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  width: calc(100% + 32px);
  margin-left: calc(var(--spacing-04) * -1);
  margin-top: var(--spacing-04);
  padding: var(--spacing-04) var(--spacing-04) var(--spacing-02);
  color: var(--color-text-alt);
  border-top: 1px solid var(--color-border);

  .icon {
    margin-left: var(--spacing-01);
  }
}

.footer__nav-secondary-link {
  @include f-caption;
  @include reset-btn;
  display: inline-block;
  color: var(--color-text-alt);
  transition: color var(--transition-link);

  &:hover,
  &.is-active {
    color: var(--color-text);
  }

  @include focus {
    outline: 1px solid var(--color-text);
  }
}

.footer__nav-secondary-separator {
  @include f-caption;
  color: var(--color-text-alt);
}

.footer__lang {
  position: relative;
  margin-right: -8px;
  color: var(--color-text-alt);

  .icon {
    position: absolute;
    top: -2px;
    right: 0;
    pointer-events: none;
  }
}

.footer__lang-input {
  @include reset-input;
  @include f-caption;
  display: block;
  padding-right: var(--spacing-05);
  color: var(--color-text-alt);
}

.footer__banner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: none;
  color: var(--color-text-light);
  opacity: 0;
  overflow: hidden;
  will-change: transform;
  transition: opacity .2s ease;
  z-index: 0;

  &.is-visible {
    opacity: 1;
  }

  // Show only on homepage.

  .tpl-homepage & {
    display: block;
  }
}

.tpl-homepage .footer {
  margin-bottom: 600px;

  @include breakpoint('xxlarge') {
    margin-bottom: 800px;
  }
}

.footer__banner-background {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 200%;
  height: 200%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(180deg, rgba(0, 148, 255, 0.6) 0%, rgba(0, 148, 255, 0) 100%);
  animation: bannerGradient 10s linear infinite alternate;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.footer__banner-gradient {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(180deg, rgba(0, 148, 255, 0.6) 0%, rgba(0, 148, 255, 0) 100%);
  transform: translate(-50%, -50%);
  z-index: 2;
}

@keyframes bannerGradient {
  0% {
    top: 0%;
    left: 0%;
  }

  50% {
    top: 100%;
    left: 100%;
  }

  100% {
    top: 50%;
    left: 50%;
  }
}

.footer__banner-content {
  position: relative;
  z-index: 2;
}

.footer__banner-title {
  @include f-display-02;
  @include grid-column(1, 8);

  @include breakpoint('medium+') {
    @include grid-column(1, 14);
  }
}

.footer__banner-text {
  @include grid-column(1, 8);
  margin-top: var(--spacing-05);
  margin-bottom: var(--spacing-04);

  @include breakpoint('medium+') {
    @include grid-column(1, 12);
  }
}

.is-legals-open {
  @include breakpoint('small') {
    .footer__nav-secondary-toggle {
      .icon {
        transform: rotate(180deg);
      }
    }

    .footer__nav-secondary-item--legal {
      display: block;
      width: 100%;
      margin-right: 0;
      margin-top: var(--spacing-03);
    }
  }
}


/*********************
      Modifiers
*********************/

.tpl-explore-online,
.tpl-explore-online-detail {
  .footer {
    background-color: var(--color-black);
    color: var(--color-white);
  }
  .footer__nav-secondary-link.is-active, .footer__nav-secondary-link:hover {
    color: var(--color-white);
  }
}
