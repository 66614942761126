.block-date__kicker {
  @include f-heading-05;
  color: var(--color-text-alt);
}

.block-date__title {
  @include f-heading-03;
}

.block-date__kicker + .block-date__title {
  margin-top: var(--spacing-01);
}

.block-date__wysiwyg {
  margin-top: var(--spacing-05);
}
