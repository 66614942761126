.button {
  @include reset-btn;
  @include f-label;
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 10px 20px;
  color: var(--color-text-light);
  background-color: var(--color-text);
  border: 1px solid var(--color-text);
  border-radius: 40px;
  transition: color var(--transition-link), background-color var(--transition-link), border-color var(--transition-link), right var(--transition-link-slow);

  .icon {
    margin-left: var(--spacing-01);
  }

  &:before {
    content: '';
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    display: none;
    border: 2px solid var(--color-focus);
    border-radius: 40px;
  }

  &:hover {
    color: var(--color-text);
    background-color: var(--color-background-light);
  }

  &[disabled] {
    color: var(--color-text-light);
    background-color: var(--color-border);
    border-color: var(--color-background-light);
    cursor: not-allowed;

    &:hover {
      color: var(--color-text-light);
      background-color: var(--color-border);
      border-color: var(--color-background-light);
    }
  }

  @include focus {
    &:before {
      display: block;
    }
  }
}


/*********************
      Modifiers
*********************/

.button--secondary {
  color: var(--color-text);
  background-color: var(--color-background-light);

  &:hover {
    color: var(--color-text-light);
    background-color: var(--color-background-dark);
  }
}

.button--secondary-light {
  color: var(--color-text-light);
  background-color: transparent;
  border-color: var(--color-background-light);
}

.button--tertiary {
  padding: 0;
  color: var(--color-text);
  background-color: transparent;
  border: 0;

  .button__label {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: auto;
      bottom: 0;
      left: 0;
      right: 100%;
      display: block;
      height: 1px;
      background-color: currentColor;
      border: 0;
      transition: right var(--transition-link-slow);
      pointer-events: none;

      @media screen and (pointer: fine) {
        display: none;
      }
    }
  }

  &:hover {
    color: currentColor;

    .button__label:before {
      right: 0;
    }
  }

  &[disabled] {
    color: var(--color-text-disable);
    background-color: transparent;

    &:hover {
      color: var(--color-text-disable);
      background-color: transparent;

      .button__label {
        &:before {
          right: 100%;
        }
      }
    }
  }
}

.button--reverse {
  color: var(--color-text);
  background-color: var(--color-background-light);
  border-color: var(--color-background-light);

  &:hover {
    color: var(--color-text-light);
    background-color: var(--color-background-dark);
    border-color: var(--color-background-dark);
  }
}

.button__small-icon {
  margin-left: 8px;
  display: flex;
}
