.list-product__item {
  transition: opacity var(--transition-link);

  &.is-loading {
    opacity: .3;
    pointer-events: none;
  }

  &.is-hidden {
    display: none;
  }
}

/*********************
      Modifiers
*********************/

.list-product--condensed {
  .list-product__item + .list-product__item {
    margin-top: var(--spacing-05);
  }
}

.list-product--featured {
  .list-product__item {
    padding-top: var(--spacing-06);
    border-top: 1px solid var(--color-border);
  }

  .list-product__item + .list-product__item {
    margin-top: var(--spacing-07);
  }
}
