/*********************
     Block editor
*********************/

.block--image-full {
  .block-image {
    margin-left: -16px;
    margin-right: -16px;
  }

  .image__content {
    @include grid;
    padding-left: var(--spacing-04);
  }

  .image__title{
    @include grid-column(1, 8);
  }

  .image__content-btn {
    @include grid-column(24, 1);
  }

  .image__caption {
    @include grid-column(1, 8);
  }

  @include breakpoint('medium+') {
    .block__cell {
      @include grid-column(1, 24);
    }

    .image__title {
      @include grid-column(3, 20);
    }

    .image__caption {
      @include grid-column(3, 20);
    }
  }

  @include breakpoint('large+') {
    .image__title {
      @include grid-column(9, 12)
    }

    .image__caption {
      @include grid-column(9, 12);
    }
  }
}
