.search-dialog {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: var(--z-index-search);
  display: flex;
  justify-content: center;
  background-color: var(--color-layer);
  opacity: 0;
  align-items: flex-start;
  pointer-events: none;
  transition: opacity var(--transition-cart-mini), backdrop-filter var(--transition-cart-mini);

  @include breakpoint('medium+') {
    align-items: center;
  }
}

.search-dialog.is-visible {
  opacity: 1;
  pointer-events: auto;
  backdrop-filter: blur(var(--backdrop-filter-blur));
}

.search-dialog__inner {
  position: relative;
  max-width: 656px;
  width: 100%;

  @include breakpoint('small') {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

.search-dialog__form {
  display: flex;
  align-items: center;
  position: relative;
  background-color: var(--color-background-light);


}

.search-dialog__input {
  @include f-subheading-02;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 0;
  height: 64px;
  padding: 16px;
  padding-left: 56px;
  flex-grow: 1;
}

.search-dialog__input:focus {
  outline: 0;
}

.search-dialog__btn {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 0;
  background: transparent;
  flex-shrink: 0;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 16px;
  align-items: center;
  cursor: pointer;
  padding: 0;
}

.search-dialog__btn[disabled] {
  opacity: 0.3;
  cursor: default;
}

.search-dialog__close-btn {
  position: absolute;
  right: 16px;
  top: 0;
  webkit-appearance: none;
  -moz-appearance: none;
  border: 0;
  background: transparent;
  padding: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}

.search-dialog__results {
  @include f-subheading-02;
  box-shadow: inset 0px 1px 0px var(--color-border);
  padding-top: 16px;
  overflow-y: auto;
  padding-bottom: 16px;
  display: none;
  background-color: var(--color-background-light);

  @include breakpoint('medium+') {
    height: 224px;
  }
}

.search-dialog__results.is-visible {
  display: block;

  @include breakpoint('small') {
    flex-grow: 1;
  }
}

.search-dialog__results p {
  padding-left: 16px;
  padding-right: 16px;
}

.search-dialog-results a {
  display: block;
  padding-left: 16px;
  padding-right: 16px;
  color: var(--color-text-alt);

  &:hover {
    background-color: var(--color-background);
  }

  .highlight {
    color: var(--color-text);
    font-weight: inherit;
  }

  &:focus {
    outline: 1px solid var(--color-text);
  }
}

.search-dialog:not(.has-focused-result) .search-dialog-results li:first-child a {
  background-color: var(--color-background);
}
