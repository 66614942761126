.partner__title {
  @include f-heading-05;
}

.partner__text {
  margin-top: var(--spacing-02);
}

.partner__list {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin-top: var(--spacing-06);
  margin-bottom: calc(var(--spacing-04) * -1);
}

.partner__item {
  margin-right: var(--spacing-04);
  margin-bottom: var(--spacing-04);
}

.partner__single {
  position: relative;

  .image {
    width: 100%;
    height: 100%;

    img {
      position: static;
      width: auto;
      height: auto;
      max-width: 130px;
      max-height: 130px;
      margin: 0 auto;
    }
  }

  .image__container {
    padding-bottom: 0 !important; // Re-write data from BE.
  }
}
