.filter {
  @include custom-scrollbar;
  padding-top: var(--spacing-07);
  padding-bottom: var(--spacing-01);
  white-space: nowrap;
  overflow-x: auto;

  @include breakpoint('medium+') {
    @include scroll-indicator;
    padding-top: var(--spacing-05);
  }
}

.filter__desktop {
  @include breakpoint('small') {
    display: none;
  }
}

.filter__mobile {
  .dropdown {
    width: 100%;
  }

  @include breakpoint('medium+') {
    display: none;
  }
}

.filter__list {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.filter__item {
  &:not(:first-child) {
    margin-left: var(--spacing-02);
  }
}

.filter__toggle {
  &--mobile {
    padding-top: var(--spacing-06);
    padding-left: var(--spacing-04);

    @include breakpoint('large+') {
      display: none;
    }
  }

  &--desktop {
    display: none;

    @include breakpoint('large+') {
      display: flex;
      margin-left: 40px;
      z-index: var(--z-index-filter-toggle);
    }
  }
}
