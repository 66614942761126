.nav-anchor-sticky__container {
  .blocks {
    @include grid-column(1, 8);

    @include breakpoint('medium') {
      @include grid-column(3, 20);
    }

    @include breakpoint('large') {
      @include grid-column(9, 14);
    }

    @include breakpoint('xlarge') {
      @include grid-column(10, 12);
    }

    @include breakpoint('xxlarge') {
      @include grid-column(11, 10);
    }
  }
}

.nav-anchor-sticky {
  position: sticky;
  align-self: start;
  top: var(--spacing-10);
  margin-top: var(--spacing-08);
  z-index: var(--z-index-sticky-nav);


  @include breakpoint('small') {
    @include grid-column(1, 8);
    top: 20px;
  }

  @include breakpoint('medium') {
    @include grid-column(3, 20);
    top: var(--spacing-03);
  }

  @include breakpoint('medium+') {
    &.with-visible-header {
      top: var(--spacing-09);
    }
  }

  @include breakpoint('large+') {
    margin-top: var(--spacing-11)
  }

  @include breakpoint('large') {
    @include grid-column(3, 5);
  }

  @include breakpoint('xlarge') {
    @include grid-column(4, 5);
  }

  @include breakpoint('xxlarge') {
    @include grid-column(5, 5);
  }
}

.nav-anchor-sticky__list {
  @include breakpoint('medium-') {
    display: none;
  }
}

.nav-anchor-sticky__dropdown {
  @include breakpoint('large+') {
    display: none;
  }

  .dropdown {
    width: 100%;
    background-color: var(--color-background-light);
  }
}

.nav-anchor-sticky__item {
  @include f-heading-05;
  margin-top: var(--spacing-03);
  color: var(--color-text-alt);

  &.is-active {
    color: var(--color-text);
    border-color: currentColor;
  }
}

.nav-anchor-sticky__item:first-child {
  margin-top: 0;
}
