:root {
  --spacing-01: 4px;
  --spacing-02: 8px;
  --spacing-03: 12px;
  --spacing-04: 16px;
  --spacing-05: 24px;
  --spacing-06: 32px;
  --spacing-07: 48px;
  --spacing-08: 60px;
  --spacing-09: 80px;
  --spacing-10: 100px;
  --spacing-11: 120px;
  --spacing-13: 220px;

  --spacing-12: 96px;

  @include breakpoint('large') {
    --spacing-12: 120px;
  }

  @include breakpoint('xlarge+') {
    --spacing-12: 160px;
  }
}
