.block-chapter__title {
  @include f-display-03;
}


/*********************
     Block editor
*********************/

.block--chapter {
  @include breakpoint('small') {
    .block__cell {
      @include grid-column(1, 8);
    }
  }

  @include breakpoint('medium') {
    .block__cell {
      @include grid-column(1, 18);
    }
  }

  @include breakpoint('large+') {
    .block__cell {
      @include grid-column(1, 16);
    }
  }
}
