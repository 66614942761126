.block-gallery {
  @include breakpoint('small') {
    .image + .image {
      margin-top: var(--spacing-07);
    }
  }
}

.block-gallery__container {
  @include grid-container;
  @include grid-column-gap(var(--spacing-04));
  align-items: flex-end;
}


/*********************
     Fixed width
*********************/

.block--gallery-fixed-width {
  @include breakpoint('medium+') {
    .block-gallery--count-1,
    .block-gallery--count-2 {
      .block-gallery__container {
        @include grid-columns(grid-repeat(2, 1fr));
      }
    }

    .block-gallery--count-3 {
      .block-gallery__container {
        @include grid-columns(grid-repeat(3, 1fr));
      }
    }

    .block-gallery--count-4 {
      .block-gallery__container {
        @include grid-columns(grid-repeat(4, 1fr));
      }
    }
  }
}

/*********************
     Variable width
*********************/

.block--gallery-variable-width {
  @include breakpoint('medium+') {
    .block-gallery__container {
      @include grid-columns(grid-repeat(3, 1fr));
      @include grid-row-gap(var(--spacing-08));
      align-items: flex-start;
    }

    .image:nth-child(1) {
      @include grid-column(1, 1);
    }

    .image:nth-child(2) {
      @include grid-column(2, 2);
    }

    .image:nth-child(3) {
      @include grid-column(1, 2);
    }

    .image:nth-child(4) {
      @include grid-column(3, 1);
    }
  }
}


/*********************
     Block editor
*********************/

.block--gallery {
  @include breakpoint('small') {
    .block__cell {
      @include grid-column(1, 8);
    }
  }

  @include breakpoint('medium+') {
    .block__cell {
      @include grid-column(1, 24);
    }
  }
}
