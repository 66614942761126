
.block-see-more {
  .button {
    display: block;
    margin: var(--spacing-07) auto var(--spacing-05);

    @include breakpoint('medium+') {
      // margin-bottom: var(--spacing-08);
      margin: var(--spacing-12) auto var(--spacing-08);
    }
  }

  &.block-see-more__1 {
    .button {
      display: none;
    }
  }

  &.is-visible-full {
    .button {
      display: none;
    }

    .block-see-more__list .card-flv-play:nth-child(n+2) {
      display: block;
    }
  }

  .block-see-more__list .card-flv-play:nth-child(n+2),
  .block-see-more__list .card-flv-play:nth-child(n+3) {
    display: block;
  }
  .block-see-more__list .card-flv-play:nth-child(n+4) {
    display: none;
  }

  .block-see-more.is-visible-full {
    .block-see-more__list .card-flv-play:nth-child(n+4) {
      display: block;
    }
  }

  &.block-see-more__2,
  &.block-see-more__3 {
    .button {
      display: none;
    }
  }


  @include breakpoint('medium') {

    .block-see-more__list .card-flv-play:nth-child(n+2) {
      display: block;
    }
    .block-see-more__list .card-flv-play:nth-child(n+3) {
      display: none;
    }

    &.block-see-more__2 {
      .button {
        display: none;
      }
    }

    &.block-see-more__3 {
      .button {
        display: block;
      }
    }

    &.is-visible-full {
      .block-see-more__list .card-flv-play:nth-child(n+3) {
        display: block;
      }

      &.block-see-more__3 {
        .button {
          display: none;
        }
      }
    }
  }
}

.block-see-more__list {
  @include grid-container;
  @include grid-column-gap(var(--spacing-04));
  @include grid-row-gap(var(--spacing-04));
  @include grid-columns(grid-repeat(1, 1fr));
  // margin-bottom: var(--spacing-07);

  @include breakpoint('medium+') {
    @include grid-columns(grid-repeat(2, 1fr));
    // margin-bottom: var(--spacing-12);
  }

  @include breakpoint('large+') {
    @include grid-columns(grid-repeat(3, 1fr));
  }
}
