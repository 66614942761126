.sections-with-image {
  // Design uses 12 column grid on small...
  @include grid-container;
  @include grid-columns(grid-repeat(12, 1fr));
  @include grid-column-gap(16px);

  @include breakpoint('medium+') {
    @include grid-columns(grid-repeat(24, 1fr));
  }
}

.sections-with-image__col {

  @include breakpoint('small') {
    @include grid-column(1, 12);
  }

  @include breakpoint('medium') {
    @include grid-column(3, 20);
  }

  @include breakpoint('large') {
    @include grid-column(9, 14);
  }

  @include breakpoint('xlarge+') {
    @include grid-column(9, 13);
  }
}

.sections-with-image__section {
  & + & {
    margin-top: var(--spacing-07);
  }

  &__title {
    @include f-heading-05;
  }

  &__content {
    margin-top: var(--spacing-03);
  }
}


.sections-with-image__image {
  // For __main
  @include grid-column(4, 6);

  @include breakpoint('medium-') {
    margin-top: var(--spacing-08);
  }

  @include breakpoint('medium+') {
    @include grid-column(3, 6);
  }

  @include breakpoint('large+') {
    @include grid-column(4, 4);
    grid-row: 1;
  }
}

