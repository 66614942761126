.jump-links {
  margin-top: var(--spacing-08);

  @include breakpoint('medium+') {
    margin-top: var(--spacing-11);
  }
}

.jump-links__item {
  @include f-display-03;

  & + & {
    margin-top: var(--spacing-03);

    @include breakpoint('medium+') {
      margin-top: var(--spacing-05);
    }
  }

  a {
    display: inline-flex;
    align-items: center;
  }
}

.jump-links__item {
  a {
    transition: color var(--transition-link);

    &:hover {
      color: var(--color-text-alt);
    }

    @include focus {
      outline: 1px solid var(--color-text);
    }
  }
}

.jump-links__icon {
  @include breakpoint('small') {
    margin-left: var(--spacing-02);
  }

  @include breakpoint('medium') {
    margin-left: var(--spacing-02);
  }

  @include breakpoint('large') {
    margin-left: var(--spacing-02);
  }

  @include breakpoint('xlarge+') {
    margin-left: var(--spacing-03);
  }

  .icon {
    display: block;
  }

  .icon--arrow-recirculation-l {
    @include breakpoint('medium-') {
      display: none;
    }
  }

  .icon--arrow-recirculation-s {
    @include breakpoint('large+') {
      display: none;
    }
  }
}
