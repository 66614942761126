
.block-flv-play-series {

  .block__background-image {
    @include img-container($ratio-4x3);
    position: relative;
    z-index: 1;

    @include breakpoint('medium+') {
      @include img-container($ratio-2x1);
    }
  }
  .block__background-gradient {
    background: linear-gradient(#00000000, #000000);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
  }

  .block__header {
    overflow: hidden;
    position: relative;
    margin-bottom: var(--spacing-06);
    margin-left: -16px;
    margin-right: -16px;
  }

  .block__header-content {
    @include grid;

    bottom: var(--spacing-04);
    position: absolute;
    left: var(--spacing-04);
    z-index: 5;
  }

  .block__title {
    @include f-heading-02;
    margin-bottom: var(--spacing-01);
    @include grid-column(1, 8);

    @include breakpoint('small+') {
      @include grid-column(1, 24);
    }
  }
  .block__description {
    @include f-body;
    @include grid-column(1, 8);

    @include breakpoint('medium') {
      @include grid-column(1, 16);
    }

    @include breakpoint('large+') {
      @include grid-column(1, 12);
    }
  }
}
