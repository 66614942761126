.payment-form {
  &__actions {
    margin-top: var(--spacing-08);
    display: flex;
    justify-content: flex-end;
  }

  .form__element {
    margin-top: var(--spacing-06);
  }

  .adyen-checkout__label__text {
    @include f-body-ui;
    overflow: visible;
    color: var(--color-text-alt);
  }

  .adyen-checkout__card__form {
    margin-top: 10px;
  }

  .adyen-checkout__input {
    border-radius: 0;
    border-color: var(--color-border);
    caret-color: #000;
    height: var(--height-input);
    position: relative;
    transition: none;
  }

  .adyen-checkout__threeds2__challenge {
    margin-top: 20px;
  }

  .adyen-checkout__input:hover {
    z-index: 1;
    border-color: var(--color-text);
    // box-shadow: 0 0 0 1px var(--color-text) inset;
  }

  .adyen-checkout__input--focus,
  .adyen-checkout__input--focus:hover,
  .adyen-checkout__input:active,
  .adyen-checkout__input:active:hover,
  .adyen-checkout__input:focus,
  .adyen-checkout__input:focus:hover {
    border-color: transparent; //var(--color-text);
    // border-width: 2px;
    box-shadow: 0 0 0 2px var(--color-text) inset;
    // box-shadow: none;
    z-index: 1;
  }

  .adyen-checkout__card__cvc__hint__wrapper {
    display: none;
  }

  .adyen-checkout__card__cardNumber__input {
    padding-left: 50px;
  }

  .adyen-checkout__card__cardNumber__brandIcon {
    position: absolute;
    left: 0;
    right: auto;
    margin-right: 7px;
    // margin-left: 0;
  }

  .adyen-checkout__label--focused .adyen-checkout__label__text {
    color: var(--color-text-alt);
  }

  .adyen-checkout-input__inline-validation .adyen-checkout__icon {
    vertical-align: top;
  }

  .adyen-checkout__field--cardNumber {
    margin-bottom: -1px;
  }

  .adyen-checkout__field--expiryDate,
  .adyen-checkout__field--securityCode {
    .adyen-checkout__label__text {
      display: none;
    }
  }

  .adyen-checkout__field-wrapper > .adyen-checkout__field:first-child.adyen-checkout__field--expiryDate {
    margin-right: 0;
  }

  .adyen-checkout__field-wrapper > .adyen-checkout__field:nth-child(2).adyen-checkout__field--securityCode {
    margin-left: 0;

    .adyen-checkout__label {
      margin-left: -1px;
      display: block;
    }

    .adyen-checkout__input {
      // margin-right: -1px;
      // border-left: 0;
    }
  }

  .adyen-checkout__applepay__button {
    height: 42px;
  }

  .adyen-checkout__paywithgoogle {
    display: inline-block;
  }

  #alipay-container .adyen-checkout__button {
    background: #fff;
    color: #000;
    border: 1px solid #000;
    padding: 0 17px;
    height: 42px;
    font-size: 14px;
    border-radius: 4px;
    background: url(/images/cart/alipay-logo.svg) no-repeat 50% 50%;
    text-indent: -9999px;
    box-shadow: none !important;

    &:hover {
      background-color: #f8f8f8;
    }
  }

  .adyen-checkout__paywithgoogle>div>button {
    box-shadow: none;
    border: 1px solid #000;
  }

  .adyen-checkout__paywithgoogle>div>button, .adyen-checkout__paywithgoogle>div>button.long, .adyen-checkout__paywithgoogle>div>button.short {
    height: 42px;
    padding-top: 12px;
    padding-bottom: 12px;
  }

  /* V5+ */
  .adyen-checkout__card__brands {
    display: none !important;
  }
  .adyen-checkout__field__exp-date_hint_wrapper {
    display: none !important;
  }
}
