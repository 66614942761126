.list-cart {
  @include f-caption;
}

.list-cart__title {
  display: none;
}

.list-cart__single-total {
  display: none;
}

.list-cart__title {
  @include f-heading-05;
  margin-bottom: var(--spacing-04);
}

.list-cart__name {
  @include f-heading-05;
}

.list-cart__date {
  margin-top: var(--spacing-04);
  padding-top: var(--spacing-04);
  color: var(--color-text-alt);
  border-top: 1px solid var(--color-border);
}

.list-cart__date-value {
  display: block;

  &::first-letter {
    text-transform: uppercase;
  }
}

.list-cart__price {
  margin-top: var(--spacing-04);
  padding-top: var(--spacing-04);
  color: var(--color-text-alt);
  border-top: 1px solid var(--color-border);
}

.list-cart__price-item {
  & + & {
    margin-top: var(--spacing-04);
  }
}

.list-cart__price-single {
  display: flex;
  flex-flow: row wrap;
}

.list-cart__price-label {
  width: calc(100% - 100px);
}

.list-cart__price-quantity {
  width: 40px;
  text-align: right;
}

.list-cart__price-total {
  width: 60px;
  color: var(--color-text);
  text-align: right;
}

.list-cart__total {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-top: var(--spacing-04);
  padding-top: var(--spacing-04);
  border-top: 1px solid var(--color-border-dark);
}


/*********************
      Modifiers
*********************/

.list-cart--multiple {
  .list-cart__title {
    display: block;
  }

  .list-cart__item {
    padding-top: var(--spacing-04);
    border-top: 1px solid var(--color-border);
  }

  .list-cart__item + .list-cart__item {
    margin-top: var(--spacing-04);
  }

  .list-cart__infos {
    position: relative;
  }

  .list-cart__single-total {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
  }

  .list-cart__name {
    @include f-label;
  }

  .list-cart__date {
    padding-top: 0;
    border-top: 0;
  }

  // .list-cart__price {
  //   display: none;
  // }
}
