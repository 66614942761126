
.flatpickr-calendar {
  --datepicker-width: 100%;
}

.flatpickr-calendar {
  background: transparent;
  opacity: 0;
  display: none;
  text-align: center;
  visibility: hidden;
  padding: 0;
  animation: none;
  direction: ltr;
  border: 0;
  line-height: 24px;
  border-radius: 5px;
  position: absolute;
  width: var(--datepicker-width);
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  background: #fff;
}

.flatpickr-calendar.open,
.flatpickr-calendar.inline {
  opacity: 1;
  max-height: 640px;
  visibility: visible;
}

.flatpickr-calendar.open {
  display: inline-block;
  z-index: 99999;
}

.flatpickr-calendar.is-loading-hours {
  .flatpickr-innerContainer,
  .flatpickr-next-month,
  .flatpickr-prev-month {
    pointer-events: none;
    opacity: 0.4;
  }
  
}

.flatpickr-calendar.animate.open {
  animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px;
}

.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px);
}

.flatpickr-calendar.static.open {
  z-index: 999;
  display: block;
}

// .flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7) {
//   box-shadow: none !important;
// }

// .flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
//   box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
// }

.flatpickr-calendar .hasWeeks .dayContainer,
.flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0;
}

.flatpickr-calendar.showTimeInput.hasTime .flatpickr-time {
  height: 40px;
  border-top: 1px solid #e6e6e6;
}

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto;
}

.flatpickr-calendar.rightMost:before,
.flatpickr-calendar.rightMost:after {
  left: auto;
  right: 22px;
}

.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px;
}

.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px;
}

.flatpickr-calendar.arrowTop:before,
.flatpickr-calendar.arrowTop:after {
  bottom: 100%;
}

.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #e6e6e6;
}

.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #fff;
}

.flatpickr-calendar.arrowBottom:before,
.flatpickr-calendar.arrowBottom:after {
  top: 100%;
}

.flatpickr-calendar.arrowBottom:before {
  border-top-color: #e6e6e6;
}

.flatpickr-calendar.arrowBottom:after {
  border-top-color: #fff;
}

.flatpickr-calendar:focus {
  outline: 0;
}

.flatpickr-wrapper {
  position: relative;
  display: inline-block;
}

.flatpickr-months {
  display: flex;
}

.flatpickr-months .flatpickr-month {
  background: transparent;
  color: rgba(0,0,0,0.9);
  fill: rgba(0,0,0,0.9);
  height: 20px;
  line-height: 1;
  text-align: left;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  flex: 1;
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  position: absolute;
  top: -3px;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  color: currentColor;
  fill: currentColor;
  text-decoration: none;
  cursor: pointer;
  z-index: 3;

  svg {
    display: block;
  }
}

.flatpickr-months .flatpickr-prev-month.flatpickr-disabled,
.flatpickr-months .flatpickr-next-month.flatpickr-disabled {
  display: none;
}

.flatpickr-months .flatpickr-prev-month i,
.flatpickr-months .flatpickr-next-month i {
  position: relative;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  left: 0;
}


.flatpickr-months .flatpickr-prev-month.flatpickr-next-month,
.flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  right: 0;
}

.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  width: 14px;
  height: 14px;
}

.flatpickr-months .flatpickr-prev-month svg path,
.flatpickr-months .flatpickr-next-month svg path {
  transition: fill 0.1s;
  fill: inherit;
}

.numInputWrapper {
  position: relative;
  height: auto;
}

.numInputWrapper input,
.numInputWrapper span {
  display: inline-block;
}

.numInputWrapper input {
  width: 100%;
}

.numInputWrapper input::-ms-clear {
  display: none;
}

.numInputWrapper input::-webkit-outer-spin-button,
.numInputWrapper input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}

.numInputWrapper span {
  position: absolute;
  right: 0;
  width: 14px;
  padding: 0 4px 0 2px;
  height: 50%;
  line-height: 50%;
  opacity: 0;
  cursor: pointer;
  border: 1px solid rgba(57,57,57,0.15);
}

.numInputWrapper span:hover {
  background: rgba(0,0,0,0.1);
}

.numInputWrapper span:active {
  background: rgba(0,0,0,0.2);
}

.numInputWrapper span:after {
  display: block;
  content: "";
  position: absolute;
}

.numInputWrapper span.arrowUp {
  display: none;
  top: 0;
  border-bottom: 0;
}

.numInputWrapper span.arrowUp:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(57,57,57,0.6);
  top: 26%;
}

.numInputWrapper span.arrowDown {
  display: none;
  top: 50%;
}

.numInputWrapper span.arrowDown:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(57,57,57,0.6);
  top: 40%;
}

.numInputWrapper span svg {
  width: inherit;
  height: auto;
}

.numInputWrapper span svg path {
  fill: rgba(0,0,0,0.5);
}

.numInputWrapper:hover {
  background: rgba(0,0,0,0.05);
}

.numInputWrapper:hover span {
  opacity: 1;
}

.flatpickr-current-month {
  line-height: inherit;
  color: inherit;
  position: absolute;
  width: 75%;
  left: 12.5%;
  line-height: 1;
  height: 20px;
  display: inline-block;
  text-align: center;
  transform: translate3d(0px, 0px, 0px);
  pointer-events: none;
}

.flatpickr-current-month span.cur-month {
  @include f-label;
  display: inline-block;
  padding: 0;
  color: inherit;
}

.flatpickr-current-month span.cur-month:hover {
  background: rgba(0,0,0,0.05);
}

.flatpickr-current-month .numInputWrapper {
  width: 44px;
  display: inline-block;
}

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: rgba(0,0,0,0.9);
}

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: rgba(0,0,0,0.9);
}

.flatpickr-current-month input.cur-year {
  @include reset-input;
  @include f-label;
  margin-left: var(--spacing-01);
  cursor: text;
  display: inline-block;
  height: auto;
  border: 0;
  border-radius: 0;
  vertical-align: initial;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

.flatpickr-current-month input.cur-year:focus {
  outline: 0;
}

.flatpickr-current-month input.cur-year[disabled],
.flatpickr-current-month input.cur-year[disabled]:hover {
  color: rgba(0,0,0,0.5);
  background: transparent;
  pointer-events: none;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  appearance: menulist;
  background: transparent;
  border: none;
  border-radius: 0;
  color: inherit;
  cursor: pointer;
  font-family: inherit;
  height: auto;
  line-height: inherit;
  margin: -1px 0 0 0;
  outline: none;
  padding: 0 0 0 0.5ch;
  position: relative;
  vertical-align: initial;
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  width: auto;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:focus,
.flatpickr-current-month .flatpickr-monthDropdown-months:active {
  outline: none;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: rgba(0,0,0,0.05);
}

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background-color: transparent;
  outline: none;
  padding: 0;
}

.flatpickr-weekdays {
  display: flex;
  align-items: center;
  width: 100%;
  text-transform: capitalize;
  text-align: center;
  background: transparent;
  overflow: hidden;
}

.flatpickr-weekdays .flatpickr-weekdaycontainer {
  display: flex;
  flex: 1;
}

span.flatpickr-weekday {
  cursor: default;
  background: transparent;
  color: rgba(0,0,0,0.54);
  line-height: 1;
  margin: 0;
  text-align: center;
  display: block;
  flex: 1;
}

.dayContainer,
.flatpickr-weeks {
  padding: 1px 0 0 0;
}

.flatpickr-days {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  width: var(--datepicker-width);
  margin-top: var(--spacing-03);
}

.flatpickr-days:focus {
  outline: 0;
}

.dayContainer {
  padding: 0;
  outline: 0;
  text-align: left;
  width: var(--datepicker-width);
  min-width: var(--datepicker-width);
  max-width: var(--datepicker-width);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  transform: translate3d(0px, 0px, 0px);
  opacity: 1;
}

.dayContainer + .dayContainer {
  box-shadow: -1px 0 0 #e6e6e6;
}

.flatpickr-day {
  @include f-caption;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14.2857143%;
  height: 48px;
  text-align: center;
  color: var(--color-text);
  background: none;
  cursor: pointer;
  border-right: 1px solid var(--color-border);
  border-bottom: 1px solid var(--color-border);

  &:before {
    content: '';
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    display: block;
    border: 1px solid transparent;
    pointer-events: none;
    z-index: 2;
  }

  &:nth-child(1),
  &:nth-child(2),
  &:nth-child(3),
  &:nth-child(4),
  &:nth-child(5),
  &:nth-child(6),
  &:nth-child(7) {
    border-top: 1px solid var(--color-border);
  }

  &:nth-child(7n+1) {
    border-left: 1px solid var(--color-border);
  }

  &.hidden {
    color: transparent;
    border-color: transparent;

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7) {
      border-bottom-color: var(--color-border);
    }

    & + .flatpickr-day:not(.hidden) {
      border-left: 1px solid var(--color-border);
    }
  }

  &.hidden.nextMonthDay,
  &.hidden.prevMonthDay {
    color: transparent;

    &:hover {
      color: transparent;
      cursor: default;
    }
  }
}

.flatpickr-day.today {
  &:before {
    opacity: 1;
  }
}

// .flatpickr-day.inRange,
// .flatpickr-day.prevMonthDay.inRange,
// .flatpickr-day.nextMonthDay.inRange,
// .flatpickr-day.today.inRange,
// .flatpickr-day.prevMonthDay.today.inRange,
// .flatpickr-day.nextMonthDay.today.inRange,
// .flatpickr-day:hover,
// .flatpickr-day.prevMonthDay:hover,
// .flatpickr-day.nextMonthDay:hover,
// .flatpickr-day:focus,
// .flatpickr-day.prevMonthDay:focus,
// .flatpickr-day.nextMonthDay:focus {
//   cursor: pointer;
//   outline: 0;
//   border-color: #959ea9;
// }


.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  &:not(.hidden) {
    color: var(--color-text-light);
    background: var(--color-background-dark);
    border-color: var(--color-background-dark);

    &:before {
      border-color: var(--color-background-dark);
    }
  }
}

.flatpickr-day.selected.startRange.endRange,
.flatpickr-day.selected.startRange:hover {
  &:before {
    opacity: 0;
  }
}

.flatpickr-day.inRange {
  background-color: var(--color-gray-10);
}

// .flatpickr-day.startRange,
// .flatpickr-day.endRange {
//   &:before {
//     content: '';
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     display: block;
//     width: 100%;
//     height: 38px;
//     background-color: var(--color-gray-10);
//     border-radius: 25px;
//     z-index: -2;
//     transform: translate(-50%, -50%);
//   }
// }

// .flatpickr-day.startRange {
//   &:before {
//     top: 0;
//     left: calc(50% - 5px);
//     width: 100%;
//     border-top-right-radius: 0;
//     border-bottom-right-radius: 0;
//     transform: none;
//   }
// }

// .flatpickr-day.endRange {
//   &:before {
//     top: 0;
//     left: auto;
//     right: calc(50% - 5px);
//     width: 100%;
//     border-top-left-radius: 0;
//     border-bottom-left-radius: 0;
//     transform: none;
//   }
// }

// .flatpickr-day.selected.startRange,
// .flatpickr-day.startRange.startRange,
// .flatpickr-day.endRange.startRange {
//   border-radius: 50px 0 0 50px;
// }

// .flatpickr-day.selected.endRange,
// .flatpickr-day.startRange.endRange,
// .flatpickr-day.endRange.endRange {
//   border-radius: 0 50px 50px 0;
// }

// .flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)),
// .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)),
// .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
//   box-shadow: -10px 0 0 #569ff7;
// }

// .flatpickr-day.selected.startRange.endRange,
// .flatpickr-day.startRange.startRange.endRange,
// .flatpickr-day.endRange.startRange.endRange {
//   border-radius: 50px;
// }


.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover,
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay,
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.notAllowed.nextMonthDay {
  color: var(--color-text-alt);
  background: transparent;
  cursor: default;
}

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover {
  cursor: not-allowed;
  color: rgba(57,57,57,0.1);
}

.flatpickr-day.week.selected {
  border-radius: 0;
}

.flatpickr-weekwrapper {
  float: left;
}

.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 0 12px;
  box-shadow: 1px 0 0 #e6e6e6;
}

.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 28px;
}

.flatpickr-weekwrapper span.flatpickr-day,
.flatpickr-weekwrapper span.flatpickr-day:hover {
  display: block;
  width: 100%;
  max-width: none;
  color: rgba(57,57,57,0.3);
  background: transparent;
  cursor: default;
  border: none;
}

.flatpickr-innerContainer {
  @include f-caption;
  display: block;
  width: 100%;
  margin-top: var(--spacing-06);
  overflow: hidden;
}

.flatpickr-rContainer {
  display: block;
  width: 100%;
  padding: 0;
}

.flatpickr-time {
  text-align: center;
  outline: 0;
  display: block;
  height: 0;
  line-height: 40px;
  max-height: 40px;
  overflow: hidden;
  display: flex;
}

.flatpickr-time:after {
  content: "";
  display: table;
  clear: both;
}

.flatpickr-time .numInputWrapper {
  flex: 1;
  width: 40%;
  height: 40px;
  float: left;
}

.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: #393939;
}

.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: #393939;
}

.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%;
}

.flatpickr-time.time24hr .numInputWrapper {
  width: 49%;
}

.flatpickr-time input {
  background: transparent;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  height: inherit;
  line-height: inherit;
  color: #393939;
  position: relative;
}

.flatpickr-time input:focus {
  outline: 0;
  border: 0;
}

.flatpickr-time .flatpickr-time-separator,
.flatpickr-time .flatpickr-am-pm {
  height: inherit;
  float: left;
  line-height: inherit;
  color: #393939;
  width: 2%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}

.flatpickr-time .flatpickr-am-pm {
  outline: 0;
  width: 18%;
  cursor: pointer;
  text-align: center;
}

.flatpickr-time input:hover,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time .flatpickr-am-pm:focus {
  background: #eee;
}

.flatpickr-input {
  display: none;
}

@-webkit-keyframes fpFadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fpFadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

/*********************
      Modifiers
*********************/

.flatpickr-calendar:not(.rangeMode) {
  .flatpickr-month {
    text-align: left;
  }

  .flatpickr-current-month {
    left: 0;
    display: block;
    width: 100%;
    text-align: left;
  }

  .flatpickr-prev-month {
    right: var(--spacing-06);
    left: auto;
  }
}


/* Inline */
.form__element--datepicker--inline {
  .numInputWrapper span {
    opacity: 1;
  }
  .numInputWrapper span.arrowDown,
  .numInputWrapper span.arrowUp {
    display: block;
  }
  .flatpickr-current-month {
    pointer-events: initial;
  }
  .flatpickr-current-month .numInputWrapper {
    width: 60px;
  }
}
