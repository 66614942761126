.notification {
  height: 0px;
  background-color: var(--color-background-darker);
  overflow: hidden;
  position: relative;
  z-index: var(--z-index-notification);

  &.has-transition {
    transition: height var(--transition-link);
  }
}

.notification__container {
  position: relative;
  padding: var(--spacing-04) var(--spacing-07) var(--spacing-04) 0;

  @include breakpoint('medium+') {
    padding: var(--spacing-05) var(--spacing-07) var(--spacing-05) 0;
  }
}

.notification__title {
  @include f-heading-05;
}

.notification__text {
  @include f-caption;
  margin-top: var(--spacing-02);

  a {
    text-decoration: underline;
  }
}

.notification__close {
  @include reset-btn;
  position: absolute;
  top: var(--spacing-04);
  right: 0;
  display: block;
  z-index: 10;

  .icon {
    display: block;
  }

  @include focus {
    outline: 1px solid var(--color-text);
  }

  @include breakpoint('medium+') {
    top: var(--spacing-05);
  }
}
