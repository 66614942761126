.card-external {
  position: relative;
}

.card-external__container {
  @include grid;

  @include breakpoint('large+') {
    @include grid-columns(grid-repeat(12, 1fr));
  }
}

.card-external__img {
  @include grid-column(6, 3);
  @include img-container($ratio-1x1);
  order: 2;

  @include breakpoint('medium') {
    @include grid-column(17, 6);
  }

  @include breakpoint('large+') {
    @include grid-column(1, 4);
    order: 1;
  }
}

.card-external__content {
  @include grid-column(1, 5);
  order: 1;

  @include breakpoint('medium') {
    @include grid-column(3, 14);
  }

  @include breakpoint('large+') {
    @include grid-column(5, 8);
    order: 2;
  }
}

.card-external__kicker {
  color: var(--color-text-alt);
}

.card-external__location {
  margin-top: var(--spacing-01);

  @include breakpoint('medium+') {
    margin-top: var(--spacing-03);
  }
}

.card-external__title {
  @include f-heading-03-alt;
  margin-top: var(--spacing-03);
}

.card-external__link {
  @include link-full;
}
