.list-option {
  @include f-body-ui;
}

.list-option__item {
  position: relative;
  display: block;
  padding: var(--spacing-05) 0;
  border-top: 1px solid var(--color-border);

  &:last-child {
    border-bottom: 1px solid var(--color-border);
  }

  @include breakpoint('small') {
    padding-right: 134px;
  }

  @include breakpoint('medium+') {
    display: flex;
    flex-flow: row wrap;
  }

  /* Modifiers */

  &--navette {
    position: relative;
    padding-left: 56px;
    border-top: 1px solid var(--color-border-dark);

    .icon--navette {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }
}

.list-option__left {
  @include breakpoint('medium+') {
    width: calc(100% - 220px);
  }
}

.list-option__right {
  @include breakpoint('medium+') {
    display: inline-flex;
    flex-flow: row wrap;
    width: 220px;
  }
}

.list-option__label {
  display: inline-flex;
  flex-flow: row wrap;
  align-items: center;
}

.list-option__price {
  width: 100px;
  padding-right: var(--spacing-06);
  color: var(--color-text-alt);

  @include breakpoint('medium+') {
    text-align: right;
  }
}

.list-option__quantity {
  width: 120px;

  .form__element--quantity {
    padding: 0;
  }

  .form__quantity {
    padding: 0;
    border: 0;
  }

  @include breakpoint('small') {
    position: absolute;
    top: 20px;
    right: 0;
  }
}
