
.block-flv-play-short {

  .carousel {
    .carousel__item {
      bottom: auto;
      opacity: 1;
      top: 0;
      width: calc(200px + 16px);

      @include breakpoint('medium+') {
        bottom: auto;
        height: auto;
        top: 0;
        width: auto;
      }

      .card-flv-play-video__img {
        /* Image is squared on mobile */
        height: 200px;
        width: 200px;

        /* Vertical : 9/16 format */
        @include breakpoint('medium') {
          height: 488px;
          width: 275px;
        }

        /* Vertical : 9/16 format */
        @include breakpoint('large+') {
          height: 610px;
          width: 343px;
        }
      }

      .card-flv-play-video__caption {
        width: 200px;

        @include breakpoint('medium') {
          width: 275px;
        }

        @include breakpoint('large+') {
          width: 343px;
        }
      }
    }

    .carousel__single {
      height: 100%;
    }
  }
}

