.text-panel {
  @include container;
  margin-top: var(--spacing-06);

  @include breakpoint('medium+') {
    margin-top: var(--spacing-09);
    margin-bottom: var(--spacing-02);
  }
}

.text-panel__title {
  @include f-heading-03;
  margin-bottom: var(--spacing-06);

  @include grid-column(1, 8);

  @include breakpoint('medium+') {
    @include grid-column(3, 20);
  }

  @include breakpoint('xlarge') {
    @include grid-column(4, 13);
  }

  @include breakpoint('xxlarge') {
    @include grid-column(5, 13);
  }

  @include breakpoint('medium+') {
    margin-bottom: var(--spacing-06);
  }
}


.text-panel__inner {
  @include grid-column(1, 8);

  @include breakpoint('medium+') {
    @include grid-column(3, 20);
  }

  @include breakpoint('xlarge') {
    @include grid-column(4, 13);
  }

  @include breakpoint('xxlarge') {
    @include grid-column(5, 13);
  }
}

.text-panel__text {
  a {
    @include reset-btn;
    position: relative;
    display: inline-flex;
    align-items: center;
    border-bottom: 1px solid currentColor;
    transition: color var(--transition-link);

    &:hover {
      color: var(--color-text-alt);
    }

    @include focus {
      color: var(--color-focus);
      transition: none;
    }
  }
}

.text-panel__links {
  margin-top: var(--spacing-03);
  margin-left: calc(0px - var(--spacing-05));
  display: flex;
  flex-wrap: wrap;

  @include breakpoint('small') {
    flex-direction: column;

    > * {
      margin-left: 0;
      margin-top: var(--spacing-04);
    }
  }

  @include breakpoint('xxlarge') {
    margin-top: var(--spacing-05);
  }

  > * {
    margin-left: var(--spacing-05);
  }
}

