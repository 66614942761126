.portrait {
  position: relative;

  .link {
    margin-top: var(--spacing-04);
  }
}

.portrait__header {
  @include breakpoint('small') {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  }
}

.portrait__row {
  @include breakpoint('medium+') {
    display: flex;
    flex-flow: row wrap;
  }
}

.portrait__left {
  @include breakpoint('medium+') {
    width: calc(100% - 112px);
    padding-right: var(--spacing-08);
  }

  @include breakpoint('large+') {
    width: calc(100% - 148px);
  }
}

.portrait__img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;

  img {
    display: block;
  }

  @include breakpoint('small') {
    position: absolute;
    top: 0;
    left: 0;
  }

  @include breakpoint('medium+') {
    width: 112px;
    height: 112px;
  }

  @include breakpoint('large+') {
    width: 148px;
    height: 148px;
  }
}

.portrait__content {
  @include breakpoint('small') {
    margin-top: var(--spacing-06);
  }

  @include breakpoint('medium+') {
    margin-top: var(--spacing-03);
  }
}

.portrait__name {
  @include f-heading-05;

  @include breakpoint('small') {
    padding-top: var(--spacing-04);
    padding-left: 90px;
  }
}

.portrait__btn {
  @include reset-btn;
  @extend .link;
  border-bottom: 1px solid currentColor;
}

.portrait__btn-label {
  &--less {
    display: none;
  }
}

.portrait__text {
  &--extra {
    display: none;
  }
}


/*********************
      Modifiers
*********************/

.portrait.is-active {
  .portrait__btn-label {
    &--more {
      display: none;
    }

    &--less {
      display: block;
    }
  }

  .portrait__text--extra {
    display: block;
    margin-top: var(--spacing-04);
  }
}
