.callout {
  position: relative;
}

.callout__container {
  @include grid;

  @include breakpoint('large+') {
    @include grid-columns(1fr);
  }
}

.callout__img {
  @include grid-column(6, 3);
  @include img-container($ratio-1x1);
  position: relative;
  order: 2;

  @include breakpoint('medium+') {
    @include grid-column(17, 6);
  }

  @include breakpoint('large+') {
    @include grid-column(1, 1);
    @include img-container($ratio-3x2);
    order: 1;
  }
}

.callout__sold-out {
  @include f-caption;
  position: relative;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 36px;
  margin-top: -36px;
  color: var(--color-text-light);
  background-color: var(--color-sticker);
  z-index: 3;

  @include breakpoint('large+') {
    position: absolute;
    top: var(--spacing-04);
    right: var(--spacing-04);
    width: 80px;
    height: 80px;
    margin-top: 0;
    border-radius: 50%;
  }
}

.callout[data-product-availability-product-id] .callout__sold-out {
  opacity: 0;
  pointer-events: none;
}

.callout.is-full .callout__sold-out {
  opacity: 1;
  pointer-events: auto;
}

.callout__content {
  @include grid-column(1, 5);
  order: 1;

  @include breakpoint('medium') {
    @include grid-column(3, 14);
  }

  @include breakpoint('large+') {
    @include grid-column(1, 1);
    order: 2;
    margin-top: var(--spacing-04);
  }
}

.callout__kicker {
  color: var(--color-text-alt);
}

.callout__location {
  margin-top: var(--spacing-01);

  @include breakpoint('medium+') {
    margin-top: var(--spacing-03);
  }
}

.callout__title {
  @include f-heading-03-alt;
  margin-top: var(--spacing-02);
  word-break: break-word;
}

.callout__text {
  margin-top: var(--spacing-04);

  @include breakpoint('large+') {
    margin-top: var(--spacing-05);
  }
}

.callout__link {
  @include link-full;

  @include focus {
    outline: 1px solid var(--color-text);
  }
}

.callout__btn {
  position: relative;
  margin-top: var(--spacing-04);
  z-index: 3;

  @include breakpoint('large+') {
    margin-top: var(--spacing-05);
  }
}

.callout[data-product-availability-product-id] .button[data-product-id] {
  opacity: 0;
  pointer-events: none;
}

.callout.is-available .button[data-product-id] {
  opacity: 1;
  pointer-events: auto;
}


/*********************
      Modifiers
*********************/

.callout--medium,
.callout--large,
.callout--xlarge {
  .callout__sold-out {
    position: absolute;
    top: var(--spacing-04);
    right: var(--spacing-04);
    width: 80px;
    height: 80px;
    margin-top: 0;
    border-radius: 50%;
  }
}


/* Medium */

.callout--medium {
  .callout__title {
    @include f-heading-01;
  }

  .callout__img {
    @include grid-column(1, 8);
    @include img-container($ratio-3x2);
    order: 1;
  }

  .callout__content {
    @include grid-column(1, 8);
    order: 2;
    margin-top: var(--spacing-04);
  }

  @include breakpoint('medium+') {
    .callout__container {
      @include grid;
    }

    .callout__img {
      @include grid-column(15, 8);
      order: 2;
    }

    .callout__content {
      @include grid-column(3, 11);
      order: 1;
      // NOTE -- check this change from large+ to medium+
      margin-top: 0;
    }
  }

  @include breakpoint('large+') {
    .callout__content {
      @include grid-column(3, 10);
    }

    .callout__img {
      @include grid-column(13, 10);
    }

  }

  @include breakpoint('xlarge') {
    .callout__img {
      @include grid-column(13, 9);
    }

    .callout__content {
      @include grid-column(4, 8);
    }
  }

  @include breakpoint('xxlarge') {
    .callout__img {
      @include grid-column(13, 8);
    }

    .callout__content {
      @include grid-column(5, 7);
    }
  }
}


/* Large */

.callout--large {
  .callout__title {
    @include f-heading-01;
    margin-top: var(--spacing-04);
  }

  .callout__img {
    @include grid-column(1, 8);
    @include img-container($ratio-3x2);
    order: 1;
  }

  .callout__content {
    @include grid-column(1, 8);
    order: 2;
    margin-top: var(--spacing-04);
  }

  @include breakpoint('medium') {
    .callout__img,
    .callout__content {
      @include grid-column(3, 20);
    }
  }

  @include breakpoint('large+') {
    .callout__container {
      @include grid;
    }

    .callout__img {
      @include grid-column(1, 12);
    }

    .callout__content {
      @include grid-column(14, 9);
      margin-top: 0;
    }
  }
}


/* Xlarge */

.callout--xlarge {
  .callout__container {
    display: flex;
    flex-direction: column;
  }

  .callout__content {
    width: 100%;
    order: 1;
  }

  .callout__img {
    @include img-container($ratio-4x5);
    order: 2;
    margin-top: var(--spacing-04);
  }

  .callout__title {
    @include f-display-02;
  }

  @include breakpoint('small') {
    .callout__img {
      width: calc(100% + 32px);
      margin-left: -16px;
    }
  }

  @include breakpoint('medium+') {
    .callout__container {
      @include grid;
    }

    .callout__content {
      @include grid-column(1, 18);
    }

    .callout__img {
      @include img-container($ratio-16x9);
      @include grid-column(1, 20);
      width: calc(100% + 16px);
    }

    .list-event--featured .list-event__item:nth-child(odd) & {
      .callout__img {
        margin-left: -16px;
      }
    }

    .list-event--featured .list-event__item:nth-child(even) & {
      .callout__content {
        @include grid-column(5, 20);
      }

      .callout__img {
        @include grid-column(5, 20);
      }
    }
  }

  @include breakpoint('xlarge+') {
    .callout__content {
      @include grid-column(1, 20);
      @include grid-container;
      @include grid-columns(grid-repeat(20, 1fr));
      @include grid-column-gap(var(--spacing-04));
      align-items: end;
      margin-top: 0;
    }

    .callout__title {
      @include grid-column(1, 15);
      margin-top: 0;
      order: 1;
    }

    .callout__infos {
      @include grid-column(16, 5);
      order: 2;
      margin-bottom: var(--spacing-02);
    }

    .callout__img {
      @include grid-container;
      @include grid-columns(grid-repeat(20, 1fr));

      .image {
        @include grid-column(1, 20);
      }
    }

    .list-event--featured .list-event__item:nth-child(even) & {
      .callout__title {
        margin-left: -0.5vw;
      }
    }
  }
}

/* FLV Play and call out homepage*/
.callout--flv_play, .callout--collection {

  .callout__content {

    @include breakpoint('medium+') {
      align-items: flex-end;
      display: flex;
      justify-content: space-between;
    }
  }

  .callout__title {
    width: 100%;

    @include breakpoint('medium+') {
      width: 75%;
    }
  }
  .callout__btn {
    margin-bottom: var(--spacing-04);
    order: 2;
  }

  .callout__img {
    position: relative;

    .icon {
      color: var(--color-white);
      height: 54px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);

      @include breakpoint('medium') {
        height: 80px;
      }

      @include breakpoint('large+') {
        height: 120px;
      }

      @include breakpoint('xlarge+') {
        height: 170px;
      }
    }
  }
}

