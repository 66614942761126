.works-featured-single {
  @include breakpoint('medium+') {
    margin-top: var(--spacing-06);
    margin-bottom: var(--spacing-06);
  }

  .image {
    margin-left: auto;
    margin-right: auto;
  }

  .image.is-portrait {
    max-width: 488px;
  }

  .image.is-landscape {
    max-width: 768px;
  }
}
