.page__infos {
  @include f-subheading-02;
  margin-top: var(--spacing-07);
}

.page__category {
  color: var(--color-text-alt);
}

.page__date {
  margin-top: var(--spacing-02);
}

.page__infos-container {
  @include grid-column(1, 8);

  @include breakpoint('medium+') {
    @include grid-column(3, 20);
  }

  @include breakpoint('xlarge') {
    @include grid-column(4, 18);
  }

  @include breakpoint('xxlarge') {
    @include grid-column(5, 16);
  }
}

.page__back {
  margin-top: var(--spacing-04);
}

.page__content {
  margin-top: var(--spacing-06);
}

.page__left {
  @include grid-column(1, 8);

  @include breakpoint('medium') {
    @include grid-column(3, 20);
  }

  @include breakpoint('large') {
    @include grid-column(3, 4);
  }

  @include breakpoint('xlarge') {
    @include grid-column(4, 4);
  }

  @include breakpoint('xxlarge') {
    @include grid-column(5, 4);
  }
}

.page__right {
  @include grid-column(1, 8);
  margin-top: var(--spacing-06);

  @include breakpoint('medium') {
    @include grid-column(3, 20);
  }

  @include breakpoint('large+') {
    @include grid-column(9, 14);
    margin-top: 0;
  }

  @include breakpoint('xlarge') {
    @include grid-column(9, 13);
  }

  @include breakpoint('xxlarge') {
    @include grid-column(9, 12);
  }
}

.page__center {
  @include grid-column(1, 8);

  @include breakpoint('medium+') {
    @include grid-column(3, 20);
  }

  @include breakpoint('xlarge') {
    @include grid-column(4, 18);
  }

  @include breakpoint('xxlarge') {
    @include grid-column(5, 16);
  }
}

.page__image {
  @include img-container($ratio-1x1);

  @include breakpoint('medium+') {
    @include img-container($ratio-2x1);
  }
}

.page__image + .page__text {
  margin-top: var(--spacing-07);
}

/* Form */

.page__form-infos {
  @include grid-column(1, 8);
  order: 2;
  margin-top: var(--spacing-07);

  @include breakpoint('medium') {
    @include grid-column(3, 20);
  }

  @include breakpoint('large+') {
    @include grid-column(3, 4);
    order: 1;
    margin-top: 0;
  }

  @include breakpoint('xlarge') {
    @include grid-column(4, 4);
  }

  @include breakpoint('xxlarge') {
    @include grid-column(5, 4);
  }
}

.page__form-image {
  @include grid-column(1, 8);
  order: 1;

  @include breakpoint('medium') {
    @include grid-column(3, 20);
  }

  @include breakpoint('large+') {
    @include grid-column(9, 14);
    order: 2;
  }

  @include breakpoint('xlarge') {
    @include grid-column(9, 13);
  }

  @include breakpoint('xxlarge') {
    @include grid-column(9, 12);
  }
}

.page__form-content {
  @include grid-column(1, 8);
  order: 3;
  margin-top: var(--spacing-07);

  @include breakpoint('medium') {
    @include grid-column(3, 20);
  }

  @include breakpoint('large+') {
    @include grid-column(9, 14);
  }

  @include breakpoint('xlarge') {
    @include grid-column(9, 13);
  }

  @include breakpoint('xxlarge') {
    @include grid-column(9, 12);
  }
}

/* Products */

.page__products {
  margin-top: var(--spacing-07);
}

.page__products-item {
  padding-top: var(--spacing-06);
  border-top: 1px solid var(--color-border);

  & + & {
    margin-top: var(--spacing-06);
  }

  @include breakpoint('large+') {
    padding-top: var(--spacing-08);

    & + & {
      margin-top: var(--spacing-08);
    }
  }
}

/* FLV Play */
.tpl-explore-online .main,
.tpl-explore-online-detail .main {
  background-color: var(--color-black);
  color: var(--color-white);

  .carousel__controls-btn {
    border-color: var(--color-white);
  }

  .explore-online__related {
    margin-top: var(--spacing-09);

    @include breakpoint('small') {
      .carousel--controls-top {
        margin-left: 0;
      }
    }

    &-header {
      margin-bottom: var(--spacing-04);

      @include breakpoint('medium') {
        margin-bottom: calc(-1 * var(--spacing-08));
      }
      @include breakpoint('large+') {
        margin-bottom: calc(-1 * var(--spacing-11));
      }
    }

    &-title {
      @include f-heading-04;
      margin-bottom: var(--spacing-04);
    }

    @include breakpoint('large+') {
      margin-top: var(--spacing-13);

      &-title {
        margin-bottom: var(--spacing-09);
      }
    }
  }

  .explore-online__back {
    @include f-display-03;
    margin-left: var(--spacing-04);
    padding-top: var(--spacing-09);
    padding-bottom: var(--spacing-06);

    a {
      align-items: center;
      display: flex;
    }

    .icon {
      height: 30px;
      margin-left: var(--spacing-03);
      width: 30px;
    }

    @include breakpoint('medium+') {
      padding-top: var(--spacing-13);
      padding-bottom: var(--spacing-10);

      .icon {
        height: 40px;
        margin-left: var(--spacing-05);
        width: 40px;
      }
    }
  }
}

/* Barba.js */

div[data-barba='wrapper'] {
  transition: opacity .2s linear;
  opacity: 1;
}

.tpl-before-leave,
.tpl-leaving,
.tpl-before-enter,
.tpl-entering,
.tpl-after-enter {
  div[data-barba='wrapper'] {
    opacity: 0;
  }
}
