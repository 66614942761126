
.list-event-small__title {
  @include f-heading-05;
}

.list-event-small__body {
  margin-top: var(--spacing-02);

  > li:not(:first-child) {
    margin-top: var(--spacing-02);
  }

  a {
    text-decoration: underline;
  }
}

.list-event-small__date {
  color: var(--color-text-alt);
}
