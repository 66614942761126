.carousel {
  width: 100%;

  .flickity-slider {
    position: relative;
    height: 100%;
  }

  .flickity-button {
    display: none;
  }
}

.carousel__list {
  cursor: grab;
  transition: all 0.35s ease;

  &.is-translating {
    cursor: grabbing;
    transition: none;
  }
}

.carousel__item {
  bottom: 0;
  width: calc(100vw - 120px);

  @include breakpoint('medium+') {
    width: 30vw;
  }
}

.carousel__single {
  position: relative;
  padding-right: 16px;

  .image__content {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding-right: var(--spacing-04);
  }
}

.carousel__caption {
  @include f-caption;
  margin-top: var(--spacing-04);
  color: var(--color-text-alt);
}

.carousel__controls {
  @include grid;
  @include grid-row-gap(var(--spacing-03));
  margin-top: var(--spacing-09);
  padding-bottom: 1px;
  transform: translateY(-3px);
}

.carousel__controls-inner {
  @include grid-column(1, 8);
  display: flex;
  flex-flow: row wrap;
  grid-row: 1;

  @include breakpoint('medium+') {
    @include grid-column(1, 24);
  }
}

.carousel__cta {
  @include grid-column(1, 8);
  display: flex;

  @include breakpoint('medium+') {
    @include grid-column(1, 24);
    justify-content: center;
    grid-row: 1;
  }
}

.carousel__controls-btn {
  @include reset-btn;
  position: relative;
  display: inline-flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  border: 1px solid var(--color-text);
  border-radius: 50%;

  & + & {
    margin-left: var(--spacing-03);
  }

  &:before {
    content: '';
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    display: none;
    border: 2px solid var(--color-focus);
    border-radius: 50%;
  }

  @include focus {
    &:before {
      display: block;
    }
  }
}


/*********************
      Modifiers
*********************/

.carousel--quick-access {

  &.carousel--controls-top {
    margin-left: 0;
  }

  .image__container {
    padding-bottom: 100%;
  }

  .carousel__item {
    bottom: auto;
    top: 0;
  }

  @include breakpoint('medium+') {
    .carousel__item {
      width: 296px;
    }
  }
}

.carousel--digital-content {
  // To enable flickity with watchCSS option on
  .carousel__list:after {
    content: 'flickity';
    display: none;
  }

  .carousel__item {
    bottom: auto;
    top: 0;
  }

  @include breakpoint('medium+') {
    .carousel__list:after {
      // Disable flickity with CSS with watchCSS option
      content: '';
      display: block;
    }
    .carousel__list {
      @include grid-container;
      @include grid-column-gap(var(--spacing-04));
      @include grid-columns(grid-repeat(3, 1fr));
    }
    .carousel__single {
      padding: 0;
    }
    .carousel__controls {
      display: none;
    }
    .carousel__item {
      width: 100%;
    }
  }
}

.carousel--wide {
  .carousel__item {
    width: calc(100vw);
  }

  .carousel__single {
    padding-right: 0;
  }

  .carousel__controls {
    @include container;
    margin-top: var(--spacing-04);
    min-height: var(--spacing-09); // Allows for longer captions, which are pos:abs
  }

  .carousel__controls-inner {
    @include breakpoint('small') {
      @include grid-column(9, 4);
    }
  }

  .image__content {
    @include container;
    @include grid-container;
    @include grid-columns(grid-repeat(12, 1fr));
    @include grid-column-gap(16px);
    padding-right: 0;
    right: 0; // for pos:abs

    @include breakpoint('medium+') {
      @include grid-columns(grid-repeat(24, 1fr));
    }
  }

  .image__title {
    @include breakpoint('small') {
      @include grid-column(1, 8);
      margin-right: calc(0px - var(--spacing-04));
    }

    @include breakpoint('medium+') {
      @include grid-column(10, 13);
    }
  }

  .image__caption {
    @include breakpoint('small') {
      @include grid-column(1, 8);
      margin-right: calc(0px - var(--spacing-04));
    }

    @include breakpoint('medium+') {
      @include grid-column(10, 13);
    }
  }
}

.carousel--controls-top {
  position: relative;

  .carousel__controls {
    margin: 0 0 var(--spacing-06) 0;

    @include breakpoint('large') {
      margin: 0 0 40px 0;
    }
    @include breakpoint('xlarge+') {
      margin: 0 0 var(--spacing-07) 0;
    }
  }
  .carousel__controls-inner {
    justify-content: flex-end;
  }

  @include breakpoint('small') {
    margin-left: -16px;

    .carousel__controls {
      margin: var(--spacing-06) 0 0 0;
    }
    .carousel__controls-inner {
      justify-content: flex-start;
    }
  }

  .container {
    display: flex;
    flex-direction: column;

    .carousel__controls {
      order: 2;
    }

    @include breakpoint('medium+') {
      display: block;
    }
  }
}

.carousel--explore-online {

  .carousel__item {
    bottom: auto;
    // min-height: 280px;
    top: 0;
  }
  @include breakpoint('small') {
    .carousel__controls {
      margin-top: var(--spacing-04);
    }
  }
  @include breakpoint('medium+') {
    .carousel__item {
      min-height: 230px;
      width: 296px;
    }
  }
  @include breakpoint('xlarge+') {
    .carousel__item {
      width: 25%;
    }
  }
}

.carousel--digital-content {
  .card-flv-play--serie {
    .card-flv-play__details {
      color: var(--color-white);
    }
    .card-flv-play__link:before {
      display: none;
    }
  }
}

.carousel.has-controls-hidden {

  .carousel__list:after {
    content: 'flickity';
    display: none;
  }

  @include breakpoint('medium+') {

    .carousel__controls {
      visibility: hidden;
    }
    .carousel__list {
      align-items: flex-start;
      display: flex;
      justify-content: space-between;
    }
    .carousel__list:after {
      content: '';
    }
  }
}
