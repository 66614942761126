:root {
  --bg-before: linear-gradient(180deg, rgba(0, 0, 0, 0) 32.29%, rgba(0, 0, 0, 0.152432) 57.29%, rgba(0, 0, 0, 0.6) 94.79%), linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15));
  --bg-before-hover: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 45.83%, rgba(0, 0, 0, 0) 96.35%), linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));
}

.card-flv-play {
  overflow: hidden;
  position: relative;
}

.card-flv-play__title {
  @include f-heading-05;
  margin: var(--spacing-02) 0;

  @include breakpoint('xlarge+') {
    margin: var(--spacing-04) 0;
  }
}

.card-flv-play__description {
  @include f-body;
  max-height: 0;
  opacity: 0;
  transition: opacity var(--transition-card-flv), max-height var(--transition-card-flv);
}

.card-flv-play__link {
  @include link-full;

  @include focus {
    outline: 1px solid var(--color-text);
  }

  &:before {
    background: var(--bg-before);
    transition: background var(--transition-card-flv);
  }

  &:hover:before {
    background: var(--bg-before-hover);
  }

  @include breakpoint('medium+') {
    &:hover {
      .card-flv-play__description {
        max-height: 500px;
        opacity: 1;
      }
    }
  }
}

.card-flv-play__img {
  @include img-container($ratio-16x9);
  position: relative;

  .image__container {
    width: 100%;
  }
}

.card-flv-play__content {
  bottom: var(--spacing-03);
  left: var(--spacing-04);
  max-width: 560px;
  position: absolute;
  width: 75%;
  z-index: 10;

  @include breakpoint('small') {
    bottom: auto;
    left: auto;
    position: relative;
    width: 100%;

    .card-flv-play__timer {
      display: none;
    }
  }

  @include breakpoint('medium') {
    width: calc(100% - var(--spacing-04));
  }

  @include breakpoint('large+') {
    bottom: var(--spacing-04);
    left: var(--spacing-06);
  }
}

.card-flv-play__timer {
  @include f-label;
  display: flex;

  @include breakpoint('small') {
    margin-bottom: var(--spacing-02);
  }

  &-inner {
    align-items: center;
    background-color: var(--color-black);
    display: flex;
    padding: var(--spacing-01) var(--spacing-02) var(--spacing-01) var(--spacing-01);
  }
}

.card-flv-play__timer .icon {
  height: 18px;
  margin-right: var(--spacing-01);
  width: 18px;
}

.card-flv-play__details {
  display: none;

  @include breakpoint('small') {
    bottom: 0;
    display: block;
    position: absolute;
    margin: var(--spacing-04) var(--spacing-04) var(--spacing-02);
    z-index: 10;
  }
}


/*
  MODIFIERS
*/

.card-flv-play--hero {
  height: 100%;

  .card-flv-play__link:hover:before {
    background: var(--bg-before);
  }

  @include breakpoint('medium-') {
    .card-flv-play__link:before {
      display: none;
    }
  }

  .card-flv-play__content {
    margin: 0 var(--spacing-06);
    position: static;
    width: calc(100% - var(--spacing-07));

    @include breakpoint('large+') {
      display: none;
    }
  }

  .card-flv-play__title {
    @include f-display-04;

    @include breakpoint('small') {
      @include grid-column(1, 8);
    }

    @include breakpoint('medium+') {
      @include grid-column(1, 12);
    }
  }
  .card-flv-play__description {
    @include grid-column(1, 8);
    @include f-body;
    margin-top: var(--spacing-02);
    max-height: 500px;
    opacity: 1;

    @include breakpoint('medium+') {
      @include grid-column(1, 12);
    }
  }

  .icon {
    height: 54px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);

    @include breakpoint('medium') {
      height: 120px;
    }

    @include breakpoint('large+') {
      height: 120px;
      top: 35%;
    }

    @include breakpoint('xlarge+') {
      height: 170px;
    }
  }

  .card-flv-play__img {
    @include img-container($ratio-16x9);
  }

  @include breakpoint('large+') {
    .card-flv-play__link {
      display: block;
      height: 100%;
    }
    .card-flv-play__img {
      height: 100%;

      .image {
        height: 100%;
      }
      .image__container {
        height: 100%;
        padding: 0 !important;
      }
      img {
        position: static;
      }
    }
  }
}

.card-flv-play--serie {
  .card-flv-play__content {
    bottom: auto;
    left: auto;
    position: relative;
  }

  .card-flv-play__details {
    bottom: 0;
    display: block;
    position: absolute;
    margin: var(--spacing-04) var(--spacing-04) var(--spacing-02);
  }

  .card-flv-play__timer {
    margin-bottom: var(--spacing-02);
  }

  .card-flv-play__link {
    &:before,
    &:hover:before {
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.0508108) 45.83%, rgba(0, 0, 0, 0.2) 96.35%);
    }
  }
}
