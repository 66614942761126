@mixin container {
  margin-left: auto;
  margin-right: auto;

  @each $name, $point in $breakpoints {
    @include breakpoint('#{$name}') {
      @if (map-get($main-col-widths, $name) == 'fluid') {
        width: calc(100% - #{map-get($outer-gutters, $name) * 2});
      }
      @else {
        padding-left: map-get($outer-gutters, $name);
        padding-right: map-get($outer-gutters, $name);
        width: $max-width;
      }
    }
  }
}

.container,
%container {
  @include container;
}

.break-container {
  // Break out of .container, useful for when a component
  // is inside a container, but also has its own
  @each $name, $point in $breakpoints {
    @include breakpoint('#{$name}') {
      margin-right: (0px - map-get($outer-gutters, $name));
      margin-left: (0px - map-get($outer-gutters, $name));
    }
  }
}

.grid {
  @include grid;

  /* Modifiers */

  &--top {
    align-items: top;
  }

  &--center {
    align-items: center;
  }

  &--bottom {
    align-items: end;
  }
}
