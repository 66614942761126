/*********************
      Modifiers
*********************/

.block-title--icon {
  position: relative;
  padding-right: 80px;

  .icon {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
}
