.live {
  position: fixed;
  bottom: 0;
  right: var(--spacing-04);
  width: 250px;
  background-color: var(--color-background-light);
  opacity: 0;
  pointer-events: none;
  z-index: var(--z-index-live);
  transition: opacity var(--transition-live);

  &.is-visible {
    opacity: 1;
    pointer-events: auto;
  }

  .grid {
    display: block;
  }

  .live__content {
    display: block;
    width: 100%;
  }


  @include breakpoint('medium+') {
    width: 400px;
  }
}

.live__title {
  @include f-display-02;
  display: none;
  margin-top: var(--spacing-02);
}

.live__text {
  @include grid-column(1, 8);
  position: relative;
  order: 1;
  display: none;
  color: var(--color-error);

  &:after {
    content: '';
    display: inline-flex;
    width: 8px;
    height: 8px;
    margin-left: var(--spacing-03);
    background-color: var(--color-error);
    border-radius: 50%;
  }

  @include breakpoint('medium+') {
    @include grid-column(1, 16);
  }

  @include breakpoint('xlarge+') {
    @include grid-column(15, 6);
    align-self: end;
    order: 2;
  }
}

.live__close {
  @include reset-btn;
  position: absolute;
  top: -28px;
  right: 0;
  display: block;

  .icon {
    display: block;
  }
}


/*********************
      Modifiers
*********************/

.tpl-homepage .live {
  position: relative;
  width: 100%;
  right: 0;
  opacity: 1;
  pointer-events: auto;

  .grid {
    @include grid;
  }

  .live__title {
    @include grid-column(1, 8);
    display: block;
    order: 2;
  }

  .live__text {
    display: block;
  }

  .live__content {
    @include grid-column(1, 8);
    order: 3;
    width: calc(100% + 32px);
    margin-left: calc(var(--spacing-04) * -1);
    margin-top: var(--spacing-04);
  }

  .live__close {
    display: none;
  }

  @include breakpoint('medium+') {
    .live__title {
      @include grid-column(1, 16);
    }

    .live__content {
      @include grid-column(1, 20);
      width: calc(100% + 16px);
    }
  }

  @include breakpoint('xlarge+') {
    .live__title {
      @include grid-column(1, 14);
      order: 1;
    }

    .live__content {
      @include grid-column(1, 20);
    }
  }
}
