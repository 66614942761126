#askmona-widget {
  z-index: var(--z-index-chatbot) !important;
}

#askmona-widget > div {
  transition: all 1s ease-in-out;
  z-index: var(--z-index-chatbot) !important;
}

.mona-widget{
  &--hidden {
    opacity: 0;
  }
}

