.block-button__list {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: calc(var(--spacing-04) * -1);
}

.block-button__item {
  margin-right: var(--spacing-05);
  margin-bottom: var(--spacing-04);
}
