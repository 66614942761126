.newsletter__form-container {
  @include grid-column(1, 8);

  @include breakpoint('medium+') {
    @include grid-column(3, 20);
  }

  @include breakpoint('xlarge') {
    @include grid-column(4, 13);
  }

  @include breakpoint('xxlarge') {
    @include grid-column(5, 11);
  }
}

/* HUBSPOT STYLES OVERRIDE */
.hs-form-field {
  @include f-body-ui;
  position: relative;
}

.hs-form-field label {
  display: block;
  width: 100%;
  margin-bottom: var(--spacing-02);
  color: var(--color-text-alt);
}

.hbspt-form .form fieldset {
  max-width: none;
}

.hbspt-form .form fieldset.form-columns-2 .hs-form-field {
  width: 100%;
  &:first-child {
    margin-top: var(--spacing-10);
  }
 }

.hbspt-form .form fieldset.form-columns-1 .input {
  margin-right: 0;
}

.hbspt-form .form .hs-input:not([type="checkbox"]) {
  @include reset-input;
  display: block;
  width: 100%;
  height: var(--height-input);
  padding: 0 var(--spacing-03);
  border: 1px solid var(--color-border);

  &:hover {
    border-color: var(--color-text);
  }

  &:focus,
  &:active {
    padding: 0 calc(var(--spacing-03) - 1px);
    border-color: var(--color-text);
    border-width: 2px;
  }

  &::-webkit-input-placeholder {
    color: #B0B3AF;
  }

  &::-moz-placeholder {
    color: #B0B3AF;
  }

  &:-ms-input-placeholder {
    color: #B0B3AF;
  }

  &:-moz-placeholder {
    color: #B0B3AF;
  }
}

.hs-form-field {
  margin-top: var(--spacing-06);
  &.hs_centres_interet {
    margin-top: 0;
  }
}

.hs-centres_interet > label {
  @include f-heading-04;
  display: block;
  width: 100%;
  margin-bottom: var(--spacing-06);
  color: var(--color-text);
}

/* HUBSPOT OVERRIDE CHECKBOX TO TOGGLE */

@include breakpoint('medium+') {
  .hbspt-form .inputs-list.multi-container {
    @include grid-container;
    @include grid-columns(grid-repeat(2, 1fr));
    @include grid-column-gap(var(--spacing-05));
    @include grid-row-gap(var(--spacing-05));
    max-width: 600px;
    margin-left: 0;
  }
}

.hs-form-checkbox > label {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: -4px;
    display: none;
    width: 54px;
    height: 32px;
    border-radius: 25px;
    border: 2px solid var(--color-focus);
  }

  &.is-focus {
    &:after {
      display: block;
    }
  }

  &--online {
    .form-toggle__input:checked + .form-toggle__label:before {
      border-color: var(--color-text-online);
    }

    .form-toggle__input:checked + .form-toggle__label {
      color: var(--color-text-online);
    }

    .form-toggle__input:checked + .form-toggle__label:after {
      background-color: var(--color-text-online);
    }
  }
}

.hs-input[type="checkbox"] {
  // SR Only
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

// Checkbox label
.hs-form-checkbox-display > span {
  display: block;
  padding-left: var(--spacing-08);
  padding-top: var(--spacing-01);
  cursor: pointer;
  transition: var(--transition-link);

  &:before {
    position: absolute;
    top: var(--spacing-01);
    left: 0;
    content: '';
    display: block;
    width: 46px;
    height: 24px;
    border: 1px solid var(--color-border-dark);
    border-radius: 25px;
    transition: var(--transition-link);
  }

  &:after {
    position: absolute;
    top: var(--spacing-02);
    left: var(--spacing-01);
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    background-color: var(--color-border-dark);
    border-radius: 50%;
    transition: var(--transition-link);
  }

  &:hover {
    &:before {
      border-color: var(--color-text);
    }

    &:after {
      background-color: var(--color-text)
    }
  }
}

.hs-input[type="checkbox"] + span {
  color: var(--color-text);
}

.hs-input[type="checkbox"]:checked + span:before {
  border-color: var(--color-background-highlight);
}

.hs-input[type="checkbox"]:checked + span:after {
  left: 26px;
  background-color: var(--color-background-highlight);
}

.hbspt-form .legal-consent-container {
  margin-top: var(--spacing-06);
}

.hbspt-form .submitted-message {
  margin-top: var(--spacing-06);
}

.hs-button {
  @include reset-btn;
  @include f-label;
  margin-top: var(--spacing-06);
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 10px 20px;
  color: var(--color-text-light);
  background-color: var(--color-text);
  border: 1px solid var(--color-text);
  border-radius: 40px;
  transition: color var(--transition-link), background-color var(--transition-link), border-color var(--transition-link), right var(--transition-link-slow);

  &:before {
    content: '';
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    display: none;
    border: 2px solid var(--color-focus);
    border-radius: 40px;
  }

  &:hover {
    color: var(--color-text);
    background-color: var(--color-background-light);
  }

  &[disabled] {
    color: var(--color-text-light);
    background-color: var(--color-border);
    border-color: var(--color-background-light);
    cursor: not-allowed;

    &:hover {
      color: var(--color-text-light);
      background-color: var(--color-border);
      border-color: var(--color-background-light);
    }
  }

  @include focus {
    &:before {
      display: block;
    }
  }
}
