.nav-tabs {
  @include scroll-indicator;
  position: relative;
  display: flex;
  width: 100%;
}

.nav-tabs__scroll {
  @include custom-scrollbar;
  white-space: nowrap;
  overflow-x: auto;

  @include breakpoint('medium+') {
    padding-top: var(--spacing-04);
  }
}

.nav-tabs__list {
  display: flex;
  flex-direction: row;
}

.nav-tabs__item {
  margin-right: var(--spacing-06);
  padding-top: var(--spacing-04);
  padding-bottom: var(--spacing-01);

  &:last-child {
    padding-right: var(--spacing-06);
  }
}
