.works-grid {
  @include grid-container;
  @include grid-column-gap(var(--spacing-04));
  align-items: flex-end;

  @include breakpoint('small') {
    @include grid-columns(grid-repeat(2, 1fr));
    @include grid-row-gap(var(--spacing-08));
  }

  @include breakpoint('medium') {
    @include grid-columns(grid-repeat(3, 1fr));
    @include grid-row-gap(var(--spacing-09));
  }

  @include breakpoint('large+') {
    @include grid-columns(grid-repeat(4, 1fr));
    @include grid-row-gap(var(--spacing-10));
  }
}

.works-grid__footer {
  display: flex;
  justify-content: center;
}

.works-grid--3-col {
  @include breakpoint('large+') {
    @include grid-columns(grid-repeat(3, 1fr));
  }
}

.works-grid--alternating {
  @include grid-columns(grid-repeat(12, 1fr));
  @include grid-column-gap(16px);
  @include grid-row-gap(var(--spacing-07));

  @include breakpoint('small') {
    > *:nth-child(2n+1) {
      @include grid-column(1, 9);
    }

    > *:nth-child(2n) {
      @include grid-column(4, 9);
    }
  }

  @include breakpoint('medium+') {
    @include grid-columns(grid-repeat(24, 1fr));
    @include grid-row-gap(var(--spacing-11));

    > *:nth-child(3n+1) {
      @include grid-column(1, 11);
    }

    > *:nth-child(3n+2) {
      @include grid-column(15, 10);
    }

    > *:nth-child(3n) {
      @include grid-column(5, 16);
    }
  }
}

/////////////////////////////////////////////////////
// Prevent content shift up onto next line
// Instead, any multilines will 'overlap' the works-grid row gap

.works-grid > .image {
  overflow: visible;
}

.works-grid > .image > .image__content {
  height: 40px;
}
