.hero-artist {
  @include grid;
  align-items: center;
  padding-top: var(--spacing-07);

  @include breakpoint('medium+') {
    min-height: calc(100vh - var(--height-header));
    padding-bottom: var(--spacing-07);
  }
}

.hero-artist__title-group {
  @include grid-column(1, 8);

  @include breakpoint('small') {
    grid-row: 2;
    margin-top: var(--spacing-05);
  }

  @include breakpoint('medium') {
    @include grid-column(3, 8);
  }

  @include breakpoint('large') {
    @include grid-column(3, 7);
  }

  @include breakpoint('xlarge') {
    @include grid-column(4, 7);
  }

  @include breakpoint('xxlarge') {
    @include grid-column(5, 7);
  }
}

.hero-artist__title {
  @include f-heading-02;
}

.hero-artist__desc {
  color: var(--color-text-alt);
  margin-top: 20px;
}

.hero-artist__image {

  @include grid-column(1, 8);

  @include breakpoint('small') {
    grid-row: 1;
  }

  @include breakpoint('medium') {
    @include grid-column(12, 10);
  }

  @include breakpoint('large') {
    @include grid-column(11, 8);
  }

  @include breakpoint('xlarge+') {
    @include grid-column(14, 8);
  }
}

