.product {
  width: 100%;

  @include breakpoint('medium-') {
    @include grid-container;
    @include grid-column-gap(var(--spacing-04));
  }

  @include breakpoint('small') {
    @include grid-columns(grid-repeat(8, 1fr));
  }

  @include breakpoint('medium') {
    @include grid-columns(grid-repeat(20, 1fr));
  }

  @include breakpoint('large+') {
    text-align: center;
  }
}

.product__img {
  @include grid-column(1, 3);
  @include img-container($ratio-1x1);
  width: 100%;
  margin: 0 auto;
  object-fit: contain;

  img {
    width: auto;
    width: 200px;
    height: 200px;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }

  @include breakpoint('medium') {
    @include grid-column(1, 6);
  }

  @include breakpoint('large+') {
    .image {
      position: relative;
      width: 200px;
      height: 200px;
      margin: 0 auto;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
      transform: translate(-50%, -50%);
    }
  }
}

.product__content {
  @include grid-column(4, 5);

  @include breakpoint('medium') {
    @include grid-column(8, 12);
  }

  @include breakpoint('large+') {
    max-width: 200px;
    margin: var(--spacing-05) auto 0;
  }
}

.product__title {
  @include f-body;
}

.product__link {
  margin-top: var(--spacing-04);
}
