html {
  /* juggling left/right and margin left/right as 100vw includes scroll bars so forcing 100vw width to make grid colspan calcs work */
  position: relative;
  left: 50%;
  right: 50%;
  width: 100vw;
  min-height: 100%;
  margin-right: -50vw;
  margin-left: -50vw;
  overflow-x: hidden;
  overflow-y: scroll;

  &.is-page-locked {
    pointer-events: none;
  }
}

body {
  @include f-body;
  @include font-smoothing(on);
  color: var(--color-text);

  &.has-modal {
    height: 100vh;
    overflow-y: hidden;

    .header,
    .header__toggle {
      z-index: -1;
    }
  }
}

[data-content] {
  position: relative;
}

.tpl {
  position: relative;
}

@each $name, $point in $breakpoints {
  @include breakpoint('#{$name}') {
    head {
      font-family: '#{$name}';
    }

    body::after {
      display: none;
      content: '#{$name}';
    }
  }
}

.main {
  position: relative;
  background-color: var(--color-background-light);
  z-index: var(--z-index-main);
}

/* Image */

img,
video {
  transition: opacity .5s ease .25s, filter .5s ease .25s;
}

img[data-lazyload],
video[data-lazyload] {
  opacity: 0;
  // filter: blur(5px);
}

/* Others */

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.is-scroll-locked {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.no-wrap {
  white-space: nowrap;
}
