.banner {
  position: relative;
  display: none;
  flex-flow: row wrap;
  align-items: center;
  height: 70px;
  background-color: var(--color-background-light);
  overflow: hidden;
  z-index: 2;

  @include breakpoint('large+') {
    height: 132px;
  }

  // Show only on homepage.

  @include breakpoint('xlarge+') {
    .tpl-homepage & {
      display: flex;

      .is-banner-hidden & {
        display: none;
      }
    }
  }
}

.banner-list {
  display: flex;
  flex-flow: row nowrap;
}

.banner-item {
  padding-right: var(--spacing-09);
}

.banner-logo {
  display: block;
  width: 976px;

  @include breakpoint('medium+') {
    width: 1951px;
  }
}
