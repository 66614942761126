$sans-serif-font: 'futura-pt', 'Arial', sans-serif;
$serif-font: 'adobe-garamond-pro', 'Georgia', serif;


/***
  Font styles to use inside typo styles.
***/

$f-fu-01: (font-size: 15px, line-height: 20px, font-weight: 500, letter-spacing: 0.04em);
$f-fu-02: (font-size: 18px, line-height: 24px, font-weight: 500);
$f-fu-03: (font-size: 25px, line-height: 32px, font-weight: 500);
$f-fu-04: (font-size: 31px, line-height: 36px, font-weight: 500);
$f-fu-05: (font-size: 39px, line-height: 44px, font-weight: 500);
$f-fu-06: (font-size: 49px, line-height: 52px, letter-spacing: -0.01em, font-weight: 500);
$f-fu-07: (font-size: 61px, line-height: 64px, letter-spacing: -0.01em, font-weight: 500);
$f-fu-08: (font-size: 76px, line-height: 80px, letter-spacing: -0.01em, font-weight: 500);
$f-fu-09: (font-size: 95px, line-height: 96px, letter-spacing: -0.01em, font-weight: 500);
$f-fu-10: (font-size: 120px, line-height: 120px, letter-spacing: -0.02em, font-weight: 500);
$f-fu-11: (font-size: 149px, line-height: 149px, letter-spacing: -0.02em, font-weight: 500);

$f-ga-01: (font-size: 16px, line-height: 20px);
$f-ga-02: (font-size: 18px, line-height: 24px);
$f-ga-03: (font-size: 20px, line-height: 28px);
$f-ga-04: (font-size: 25px, line-height: 32px);
$f-ga-05: (font-size: 31px, line-height: 36px);
$f-ga-06: (font-size: 39px, line-height: 44px);
$f-ga-07: (font-size: 49px, line-height: 52px, letter-spacing: -0.01em);
$f-ga-08: (font-size: 61px, line-height: 64px, letter-spacing: -0.01em);
$f-ga-09: (font-size: 76px, line-height: 80px, letter-spacing: -0.01em);
$f-ga-10: (font-size: 95px, line-height: 96px, letter-spacing: -0.01em);
$f-ga-11: (font-size: 120px, line-height: 120px, letter-spacing: -0.02em);


/***************
   Display 01
***************/

$f-display-01: generate-font-obj(
  (
    font-family: $sans-serif-font,
    settings: (
      'small': (font-size: 13vw, line-height: 13vw, font-weight: 500),
      'medium': (font-size: 9.7vw, line-height: 9.7vw),
      'large': (font-size: 8.9vw, line-height: 8.9vw),
      'xlarge+': (font-size: 8.8vw, line-height: 8.8vw)
    )
  )
);

@mixin f-display-01 {
  @include font-styles($f-display-01);
  font-kerning: normal;
  font-variant-ligatures: common-ligatures;
  text-transform: uppercase;
}

%f-display-01,
.f-display-01 {
  @include f-display-01;
}

/***************
   Display 02
***************/

$f-display-02: generate-font-obj(
  (
    font-family: $sans-serif-font,
    settings: (
      'small': (font-size: 13vw, line-height: 13vw, font-weight: 500),
      'medium': (font-size: 9.7vw, line-height: 9.7vw),
      'large': (font-size: 8.9vw, line-height: 8.9vw),
      'xlarge+': (font-size: 7vw, line-height: 7vw)
    )
  )
);

@mixin f-display-02 {
  @include font-styles($f-display-02);
  font-kerning: normal;
  font-variant-ligatures: common-ligatures;
  text-transform: uppercase;
}

%f-display-02,
.f-display-02 {
  @include f-display-02;
}

/***************
   Display 02 Alt
***************/

$f-display-02-alt: generate-font-obj(
  (
    font-family: $sans-serif-font,
    settings: (
      'small': $f-fu-06,
      'medium': $f-fu-07,
      'large': $f-fu-08,
      'xlarge': $f-fu-09,
      'xxlarge': $f-fu-09
    )
  )
);

@mixin f-display-02-alt {
  @include font-styles($f-display-02-alt);
  font-kerning: normal;
  font-variant-ligatures: common-ligatures;
  text-transform: uppercase;
}

%f-display-02-alt,
.f-display-02-alt {
  @include f-display-02-alt;
}

/***************
   Display 03
***************/

$f-display-03: generate-font-obj(
  (
    font-family: $sans-serif-font,
    settings: (
      'small': (font-size: 10.4vw, line-height: 10.4vw, font-weight: 500),
      'medium': (font-size: 6.25vw, line-height: 6.25vw),
      'large': (font-size: 5.69vw, line-height: 5.69vw),
      'xlarge+': (font-size: 5.6vw, line-height: 5.6vw)
    )
  )
);

@mixin f-display-03 {
  @include font-styles($f-display-03);
  font-kerning: normal;
  font-variant-ligatures: common-ligatures;
  text-transform: uppercase;
}

%f-display-03,
.f-display-03 {
  @include f-display-03;
}

/***************
   Display 04
***************/

$f-display-04: generate-font-obj(
  (
    font-family: $sans-serif-font,
    settings: (
      'small': $f-fu-04,
      'medium': $f-fu-05,
      'large': $f-fu-06,
      'xlarge': $f-fu-07,
      'xxlarge': $f-fu-08
    )
  )
);

@mixin f-display-04 {
  @include font-styles($f-display-04);
  font-kerning: normal;
  font-variant-ligatures: common-ligatures;
  text-transform: uppercase;
}

%f-display-04,
.f-display-04 {
  @include f-display-04;
}

/***************
   Heading 01
***************/

$f-heading-01: generate-font-obj(
  (
    font-family: $sans-serif-font,
    settings: (
      'small': $f-fu-04,
      'medium': $f-fu-05,
      'large+': $f-fu-06,
      'xxlarge': $f-fu-07
    )
  )
);

@mixin f-heading-01 {
  @include font-styles($f-heading-01);
  font-kerning: normal;
  font-variant-ligatures: common-ligatures;
  text-transform: uppercase;
}

%f-heading-01,
.f-heading-01 {
  @include f-heading-01;
}

/***************
   Heading 02
***************/

$f-heading-02: generate-font-obj(
  (
    font-family: $sans-serif-font,
    settings: (
      'small': $f-fu-04,
      'large+': $f-fu-05,
      'xxlarge': $f-fu-06
    )
  )
);

@mixin f-heading-02 {
  @include font-styles($f-heading-02);
  font-kerning: normal;
  font-variant-ligatures: common-ligatures;
  text-transform: uppercase;
}

%f-heading-02,
.f-heading-02 {
  @include f-heading-02;
}

/***************
   Heading 03
***************/

$f-heading-03: generate-font-obj(
  (
    font-family: $sans-serif-font,
    settings: (
      'small': $f-fu-04,
      'xlarge': $f-fu-05,
      'xxlarge': $f-fu-06
    )
  )
);

@mixin f-heading-03 {
  @include font-styles($f-heading-03);
  font-kerning: normal;
  font-variant-ligatures: common-ligatures;
  text-transform: uppercase;
}

%f-heading-03,
.f-heading-03 {
  @include f-heading-03;
}

/***************
 Heading 03 alt
***************/

$f-heading-03-alt: generate-font-obj(
  (
    font-family: $sans-serif-font,
    settings: (
      'small': $f-fu-02,
      'medium+': $f-fu-03,
      'xxlarge': $f-fu-04
    )
  )
);

@mixin f-heading-03-alt {
  @include font-styles($f-heading-03-alt);
  font-kerning: normal;
  font-variant-ligatures: common-ligatures;
  text-transform: uppercase;
}

%f-heading-03-alt,
.f-heading-03-alt {
  @include f-heading-03-alt;
}

/***************
   Heading 04
***************/

$f-heading-04: generate-font-obj(
  (
    font-family: $sans-serif-font,
    settings: (
      'small': $f-fu-03,
      'medium+': $f-fu-04
    )
  )
);

@mixin f-heading-04 {
  @include font-styles($f-heading-04);
  font-kerning: normal;
  font-variant-ligatures: common-ligatures;
  text-transform: uppercase;
}

%f-heading-04,
.f-heading-04 {
  @include f-heading-04;
}

/***************
   Heading 04 alt
***************/

$f-heading-04-alt: generate-font-obj(
  (
    font-family: $sans-serif-font,
    settings: (
      'small': $f-fu-02,
      'medium+': $f-fu-03,
      'xxlarge': $f-fu-04
    )
  )
);

@mixin f-heading-04-alt {
  @include font-styles($f-heading-04-alt);
  font-kerning: normal;
  font-variant-ligatures: common-ligatures;
  text-transform: uppercase;
}

%f-heading-04-alt,
.f-heading-04-alt {
  @include f-heading-04-alt;
}


/***************
   Heading 05
***************/

$f-heading-05: generate-font-obj(
  (
    font-family: $sans-serif-font,
    settings: (
      'small': $f-fu-02,
      'xxlarge': (font-size: calc(18px + 0.1vw), line-height: calc(24px + 0.1vw))
    )
  )
);

@mixin f-heading-05 {
  @include font-styles($f-heading-05);
  font-kerning: normal;
  font-variant-ligatures: common-ligatures;
  text-transform: uppercase;
}

%f-heading-05,
.f-heading-05 {
  @include f-heading-05;
}

/***************
   Heading Hero
***************/

$f-heading-hero: generate-font-obj(
  (
    font-family: $sans-serif-font,
    settings: (
      'small': $f-fu-06,
      'medium+': $f-fu-08,
      'xlarge': $f-fu-09,
      'xxlarge': $f-fu-10
    )
  )
);

@mixin f-heading-hero {
  @include font-styles($f-heading-hero);
  font-kerning: normal;
  font-variant-ligatures: common-ligatures;
  text-transform: uppercase;
}

%f-heading-hero,
.f-heading-hero {
  @include f-heading-hero;
}

/***************
  Subheading 01
***************/

$f-subheading-01: generate-font-obj(
  (
    font-family: $serif-font,
    settings: (
      'small': $f-ga-04,
      'medium': $f-ga-05,
      'large+': $f-ga-06,
      'xxlarge': $f-ga-07,
    )
  )
);

@mixin f-subheading-01 {
  @include font-styles($f-subheading-01);
  font-kerning: normal;
  font-variant-ligatures: common-ligatures;
}

%f-subheading-01,
.f-subheading-01 {
  @include f-subheading-01;
}

/***************
  Subheading 02
***************/

$f-subheading-02: generate-font-obj(
  (
    font-family: $serif-font,
    settings: (
      'small': $f-ga-03,
      'medium+': $f-ga-04
    )
  )
);

@mixin f-subheading-02 {
  @include font-styles($f-subheading-02);
  font-kerning: normal;
  font-variant-ligatures: common-ligatures;
}

%f-subheading-02,
.f-subheading-02 {
  @include f-subheading-02;
}

/***************
  Subheading 03
***************/

$f-subheading-03: generate-font-obj(
  (
    font-family: $serif-font,
    settings: (
      'small': $f-ga-04,
      'medium+': $f-ga-04
    )
  )
);

@mixin f-subheading-03 {
  @include font-styles($f-subheading-03);
  font-kerning: normal;
  font-variant-ligatures: common-ligatures;
}

%f-subheading-03,
.f-subheading-03 {
  @include f-subheading-03;
}

/***************
  Subheading Hero
***************/

$f-subheading-hero: generate-font-obj(
  (
    font-family: $serif-font,
    settings: (
      'small': $f-ga-04,
      'medium': $f-ga-05,
      'large+': $f-ga-06
    )
  )
);

@mixin f-subheading-hero {
  @include font-styles($f-subheading-hero);
  font-kerning: normal;
  font-variant-ligatures: common-ligatures;
}

%f-subheading-hero,
.f-subheading-hero {
  @include f-subheading-hero;
}

/***************
     Body
***************/

$f-body: generate-font-obj(
  (
    font-family: $serif-font,
    settings: (
      'small': $f-ga-02,
      'medium+': $f-ga-03,
      'xxlarge': (font-size: calc(20px + 0.2vw), line-height: calc(30px + 0.2vw))
    )
  )
);

@mixin f-body {
  @include font-styles($f-body);
  font-kerning: normal;
  font-variant-ligatures: common-ligatures;
}

%f-body,
.f-body {
  @include f-body;
}


/***************
    Body UI
***************/

$f-body-ui: generate-font-obj(
  (
    font-family: $serif-font,
    settings: (
      'small': $f-ga-02,
      'medium+': $f-ga-03
    )
  )
);

@mixin f-body-ui {
  @include font-styles($f-body-ui);
  font-kerning: normal;
  font-variant-ligatures: common-ligatures;
}

%f-body-ui,
.f-body-ui {
  @include f-body-ui;
}



/***************
    Caption
***************/

$f-caption: generate-font-obj(
  (
    font-family: $serif-font,
    settings: (
      'small': $f-ga-01
    )
  )
);

@mixin f-caption {
  @include font-styles($f-caption);
  font-kerning: normal;
  font-variant-ligatures: common-ligatures;
}

%f-caption,
.f-caption {
  @include f-caption;
}

/***************
     Label
***************/

$f-label: generate-font-obj(
  (
    font-family: $sans-serif-font,
    settings: (
      'small': $f-fu-01
    )
  )
);

@mixin f-label {
  @include font-styles($f-label);
  font-kerning: normal;
  font-variant-ligatures: common-ligatures;
  text-transform: uppercase;
}

%f-label,
.f-label {
  @include f-label;
}

/***************
    Nav Link
***************/

$f-nav-link: generate-font-obj(
  (
    font-family: $serif-font,
    settings: (
      'small': $f-ga-06,
      'xlarge+': $f-ga-03,
    )
  )
);

@mixin f-nav-link {
  @include font-styles($f-nav-link);
  font-kerning: normal;
  font-variant-ligatures: common-ligatures;
}

%f-nav-link,
.f-nav-link {
  @include f-nav-link;
}

/***************
Nav link Secondary
***************/

$f-nav-link-secondary: generate-font-obj(
  (
    font-family: $serif-font,
    settings: (
      'small': $f-ga-04,
      'xlarge+': $f-ga-03
    )
  )
);

@mixin f-nav-link-secondary {
  @include font-styles($f-nav-link-secondary);
  font-kerning: normal;
  font-variant-ligatures: common-ligatures;
}

%f-nav-link-secondary,
.f-nav-link-secondary {
  @include f-nav-link-secondary;
}
