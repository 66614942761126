.form {
  padding-top: var(--spacing-08);

  &--confirm {
    padding-top: var(--spacing-04);
  }
}

.form__cell {
  & + & {
    margin-top: var(--spacing-06);
  }
}

.form__line {
  display: flex;
  margin: var(--spacing-06) 0;

  .form__cell {
    flex-grow: 1;
  }

  .form__cell + .form__cell {
    margin-left: var(--spacing-04);
    margin-top: 0;
  }
}

.form__element {
  @include f-body-ui;
  position: relative;

  &--quantity {
    padding-top: var(--spacing-04);
  }

  &--csrf {
    display: none;
  }

  &.has-error {
    color: var(--color-error);

    .form__message {
      color: var(--color-error);
    }

    .form__input,
    .form__select-input,
    .form__checkbox-label:before,
    .form__radio-label:before {
      border-color: var(--color-error);
    }

    &.form__element--quantity {
      color: var(--color-text-alt);
    }

    .form__quantity {
      border-color: var(--color-error);
    }
  }
}

.form__label {
  display: block;
  width: 100%;
  margin-bottom: var(--spacing-02);
  color: var(--color-text-alt);
}

.form__select {
  position: relative;

  .icon--chevron-down {
    position: absolute;
    top: 14px;
    right: var(--spacing-04);
    display: block;
    pointer-events: none;
  }
}

.form__input,
.form__select-input {
  @include reset-input;
  display: block;
  width: 100%;
  height: var(--height-input);
  padding: 0 var(--spacing-03);
  border: 1px solid var(--color-border);

  &:hover {
    border-color: var(--color-text);
  }

  &:focus,
  &:active {
    padding: 0 calc(var(--spacing-03) - 1px);
    border-color: var(--color-text);
    border-width: 2px;
  }

  &::-webkit-input-placeholder {
    color: #B0B3AF;
  }

  &::-moz-placeholder {
    color: #B0B3AF;
  }

  &:-ms-input-placeholder {
    color: #B0B3AF;
  }

  &:-moz-placeholder {
    color: #B0B3AF;
  }
}

.form__input-container,
.form__select {
  position: relative;
}

.form__toggle-sidebar {
  @include reset-btn;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;

  .icon {
    position: absolute;
    top: 14px;
    right: var(--spacing-03);
    color: var(--color-text-alt);
  }
}

.form__select-input {
  padding-right: var(--spacing-07);
  color: var(--color-black);

  &[data-selected=""] {
    color: var(--color-gray-40);
  }
}

.form__message {
  @include f-caption;
  margin-top: var(--spacing-02);
  color: var(--color-text-alt);
}

.form__caption {
  @include f-caption;
  margin-top: var(--spacing-10);
  color: var(--color-text-alt);

  a {
    display: inline-block;
    border-bottom: 1px solid currentColor;
  }
}


/* Fieldset */

.form__fieldset {
  & + & {
    margin-top: var(--spacing-07);
  }

  @include breakpoint('medium') {
    & + & {
      margin-top: var(--spacing-08);
    }
  }

  @include breakpoint('large+') {
    & + & {
      margin-top: var(--spacing-10);
    }
  }

  &--hidden {
    display: none;
  }
}

.form__fieldset-title {
  @include f-heading-04;
  display: block;
  width: 100%;
  margin-bottom: var(--spacing-06);
}

.form__fieldset-description {
  color: var(--color-gray-60);
  margin-bottom: var(--spacing-04);

  @include breakpoint('medium+') {
    margin-bottom: var(--spacing-07);
  }
}

.form__fieldset-description-mobile {
  display: block;
  margin-bottom: var(--spacing-07);

  @include breakpoint('medium+') {
    display: none;
  }

  &.is-hidden {
    display: none;
  }
}

.form__fieldset--row {
  display: flex;
  flex-flow: row wrap;
}

.form__fieldset--2-cols {
  @include breakpoint('medium+') {
    .form__fieldset-content {
      @include grid-container;
      @include grid-columns(grid-repeat(2, 1fr));
      @include grid-column-gap(var(--spacing-05));
      @include grid-row-gap(var(--spacing-05));
      max-width: 600px;
      margin-left: 0;
    }

    .form__cell {
      margin-top: 0;
    }
  }
}


/* Checkbox */

.form__checkbox {
  &:after {
    content: '';
    position: absolute;
    top: -4px;
    left: -4px;
    display: none;
    width: 24px;
    height: 24px;
    border: 2px solid var(--color-focus);
    border-radius: 3px;
    z-index: 2;
  }

  &.is-focus {
    &:after {
      display: block;
    }
  }
}

.form__checkbox-input {
  @include reset-input;
}

.form__checkbox-label {
  position: relative;
  display: block;
  padding-left: 28px;
  cursor: pointer;

  .icon--check {
    position: absolute;
    top: 3px;
    left: 0;
    color: var(--color-text-light);
    opacity: 0;
    pointer-events: none;
    z-index: 2;
    transition: opacity var(--transition-link);
  }

  &:before {
    content: '';
    position: absolute;
    top: 3px;
    left: 0;
    display: block;
    width: 16px;
    height: 16px;
    border: 1px solid var(--color-border-dark);
    transition: background-color var(--transition-link), border-color var(--transition-link);;
  }

  &:hover {
    &:before {
      border-color: var(--color-text);
    }
  }

  @include breakpoint('medium+') {
    &:before {
      top: 4px;
    }

    .icon--check {
      top: 4px;
    }
  }
}

.form__checkbox-input:checked + .form__checkbox-label .icon--check {
  opacity: 1;
}

.form__checkbox-input:checked + .form__checkbox-label:before {
  background-color: var(--color-text);
  border-color: var(--color-text);
}


/* Radio */

.form__radio {
  &:after {
    content: '';
    position: absolute;
    top: -4px;
    left: -4px;
    display: none;
    width: 24px;
    height: 24px;
    border: 2px solid var(--color-focus);
    border-radius: 50%;
    z-index: 2;
  }

  &.is-focus {
    &:after {
      display: block;
    }
  }
}

.form__radio-input {
  @include reset-input;
}

.form__radio-label {
  position: relative;
  display: block;
  padding-left: 28px;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    top: 3px;
    left: 0;
    display: block;
    width: 16px;
    height: 16px;
    border: 1px solid var(--color-border-dark);
    border-radius: 50%;
    transition: background-color var(--transition-link), border-color var(--transition-link);;
  }

  &:after {
    content: '';
    position: absolute;
    top: 8px;
    left: 5px;
    display: block;
    width: 6px;
    height: 6px;
    background-color: var(--color-background-light);
    border-radius: 50%;
  }

  &:hover {
    &:before {
      border-color: var(--color-text);
    }
  }

  @include breakpoint('medium+') {
    &:before {
      top: var(--spacing-01);
    }

    &:after {
      top: 9px;
    }
  }
}

.form__radio-input:checked + .form__radio-label:before {
  background-color: var(--color-text);
  border-color: var(--color-text);
}

/* Quantity */

.form__quantity {
  display: flex;
  flex-flow: row wrap;
  padding: calc(var(--spacing-05) - 2px) 0;
  border-top: 1px solid var(--color-border);
  border-bottom: 1px solid var(--color-border);

  .form__label {
    flex: 1;
    margin-bottom: 0;
    padding-right: var(--spacing-05);
  }
}

.form__quantity-container {
  position: relative;
  height: 100%;
  width: 120px;
  height: 32px;
  overflow: hidden;
}

.form__quantity-btn {
  @include reset-btn;
  @include focus;
  position: absolute;
  top: 0;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border: 1px solid var(--color-border);
  border-radius: 50%;
  transition: border-color var(--transition-link), opacity var(--transition-link);

  .icon {
    width: 16px;
    height: 16px;
  }

  &:hover {
    border-color: var(--color-border-dark);
  }

  &--minus {
    left: 0;
  }

  &--plus {
    right: 0;
  }

  &.is-disable {
    opacity: .4;
    pointer-events: none;
  }
}

.form__quantity-number {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  text-align: center;
}

.form__quantity-input {
  display: none;
}

/* Textarea */

.form__element--textarea {
  .form__input {
    height: 102px;
    padding: var(--spacing-03);
    resize: none;

    &:focus {
      padding: calc(var(--spacing-03) - 1px)
    }
  }
}

/* Date */

.form__element--date {
  position: relative;

  .form__input--hidden {
    overflow: visible;
    pointer-events: none;
    margin-bottom: -2px;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }

  .form__fake {
    @extend .form__input;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    color: var(--color-text-alt);
    z-index: 1;
    position: relative;
    background-color: #fff;
  }

  .form__toggle-sidebar {
    z-index: 1;
  }

  &.has-value {
    .form__fake {
      color: var(--color-text);
    }
  }
}

/* Datepicker */
.form__element--datepicker {
  background: var(--color-white);
  box-shadow: 0px 4px 14px 0px #00000026;
  max-width: 380px;
  padding: var(--spacing-05);
  position: absolute;
  z-index: 10;

  &.is-hidden {
    display: none;
  }
}

/* Captcha */

#a-recaptcha > div {
  width: 100% !important;
}


/* Submit */

.form__submit {
  margin-top: var(--spacing-07);
}


/* Confirm */

.form__confirm-text {
  @include f-subheading-01;
}

.form__confirm-btn {
  margin-top: var(--spacing-06);
}


/* Confirm */

.form__confirm-text {
  @include f-subheading-01;
}

.form__confirm-btn {
  margin-top: var(--spacing-06);
}

/* Membership */
.form--membership {
  padding-top: 0;

  .form__fieldset-title {
    @include f-heading-05;
    margin-bottom: var(--spacing-04);
  }

  .form__fieldset-description {
    @include breakpoint('small') {
      margin-bottom: var(--spacing-06);
    }
  }

  .form__fieldset + .form__fieldset {
    margin-top: var(--spacing-07);
  }

  .form__fieldset + .form__fieldset--compact,
  .form__fieldset--compact + .form__fieldset--compact {
    margin-top: var(--spacing-06);
  }

  .form__fieldset--delivery {
    .form__fieldset-description {
      margin-bottom: 4px;
    }

    .form__cell + .form__cell {
      margin-top: 0;
    }

    .form__radio-label {
      @include f-caption;
      color: var(--color-text-alt);

      &::before {
        top: 0;
      }

      &::after {
        top: 5px;
      }
    }
  }

  .form__line:last-child {
    margin-bottom: 0;
  }

  .field__hidden {
    display: none;
  }
}
