.billboard {
  width: 100%;
  min-height: 480px;
  margin-top: var(--spacing-12);
  padding: var(--spacing-04) 0 var(--spacing-12);
  color: var(--color-text-light);
  overflow: hidden;
  will-change: transform;
  z-index: var(--z-index-section);
  position: relative;
}

.billboard__background {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 200%;
  height: 200%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(180deg, rgba(0, 148, 255, 0.6) 0%, rgba(0, 148, 255, 0) 100%);
  animation: bannerGradient 10s linear infinite alternate;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.billboard__gradient {
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 100%;
  height: 100%;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(180deg, rgba(0, 148, 255, 0.6) 0%, rgba(0, 148, 255, 0) 100%);
  transform: translate(-50%, -50%);
  z-index: 2;
}

@keyframes bannerGradient {
  0% {
    top: 0%;
    left: 0%;
  }

  50% {
    top: 100%;
    left: 100%;
  }

  100% {
    top: 50%;
    left: 50%;
  }
}

.billboard__content {
  position: relative;
  z-index: 2;
}

.billboard__title {
  @include f-display-04;
  @include grid-column(1, 8);

  @include breakpoint('medium+') {
    @include grid-column(1, 16);
  }
}

.billboard__text {
  @include grid-column(1, 8);
  margin-top: var(--spacing-04);
  margin-bottom: var(--spacing-06);

  @include breakpoint('medium+') {
    @include grid-column(1, 12);
  }
}

/*********************
      Modifiers
*********************/

.billboard--featured {
  min-height: 600px;
  margin-top: 0;

  .billboard__title {
    @include f-display-03;
    margin-top: var(--spacing-08);
  }

  @include breakpoint('xlarge+') {
    .billboard__title {
      margin-top: var(--spacing-09);
    }
  }
}

.billboard--hero {
  margin-top: calc(-1 * var(--spacing-08));
  min-height: 600px;
  padding-bottom: var(--spacing-13);

  .billboard__title {
    @include f-heading-hero;
    margin-top: var(--spacing-12);

    @include breakpoint('medium') {
      @include grid-column(1, 22);
    }

    @include breakpoint('xxlarge') {

    }
  }

  .billboard__text {
    @include f-subheading-hero;

    @include breakpoint('medium+') {
      @include grid-column(1, 16);
    }
  }
}

.billboard--collection {

  .billboard__background {
    animation: none;
    background-position: center center;
    background-size: cover;
    height: 100%;
    left: 0;
    top: 0;
    transform: none;
    width: 100%;
  }
  .billboard__gradient {
    display: none;
  }
}

.billboard--explore-online {

  background: var(--color-black);
  display: flex;
  justify-content: space-between;
  min-height: 0;
  padding-bottom: 0;
  position: relative;

  .billboard__content,
  .billboard__background-media {
    width: 50%;
  }

  .billboard__content {
    margin-bottom: var(--spacing-04);
  }

  .billboard__title,
  .billboard__text {
    @include breakpoint('medium') {
      @include grid-column(1, 18);
    }
    @include breakpoint('large+') {
      @include grid-column(1, 23);
    }
  }

  .billboard__background-media {

    margin-top: var(--spacing-06);
    position: relative;

    @include breakpoint('medium+') {
      margin-bottom: var(--spacing-02);
      margin-right: var(--spacing-04);
      margin-top: 0;

      /*
      bottom: 0;
      left: 50%;
      position: absolute;
      right: 0;
      top: 0;
      margin-top: 0;
      */
    }
  }

  .billboard__background-video {

    height: auto;
    width: 100%;

    @include breakpoint('medium+') {
      /*
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      display: block;
      height: 100%;
      object-fit: cover;
      */
    }
  }

  .icon {
    height: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @include breakpoint('large+') {
      height: 120px;
    }

    @include breakpoint('xlarge+') {
      height: 170px;
    }
  }

  @include breakpoint('small') {
    flex-direction: column;
    min-height: 0;

    .billboard__content,
    .billboard__background-media {
      width: 100%;
    }
  }
}
