/* General */

.direction__text {
  &--metro,
  &--bus {
    span {
      @include f-caption;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 26px;
      height: 26px;
      margin: 0 2px;
      padding-top: 2px;
      border-radius: 50%
    }
  }

  &--metro {
    span {
      background-color: #F0C93D;
    }
  }

  &--bus {
    span {
      color: var(--color-text-light);
      background-color: #644C30;
    }
  }
}

.direction__text-value {
  display: block;
}

.direction__link {
  margin-top: var(--spacing-05);
}
.direction .link {
  margin-top: var(--spacing-04);
}


/* Map */

.direction__map {
  @include f-heading-03;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-bottom: 128%;
  background-color: var(--color-background);

  @include breakpoint('medium') {
    padding-bottom: 100%;
  }

  @include breakpoint('large+') {
    padding-bottom: 40%;
  }
}


/* Infos */

.direction__infos {
  margin-top: var(--spacing-05);

  @include breakpoint('medium+') {
    padding-bottom: var(--spacing-05);
    border-bottom: 1px solid var(--color-border);
  }
}


/* Description list */

.direction__description-list {
  width: 100%;
}

.direction__description-item {

  dt {
    color: var(--color-text-alt);
  }

  @include breakpoint('medium+') {
    @include grid-container;
    @include grid-column-gap(var(--spacing-04));
    @include grid-columns(grid-repeat(12, 1fr));

    dt {
      @include grid-column(1, 4);
    }
    dd {
      @include grid-column(5, 8);
    }
  }

  & + & {
    margin-top: var(--spacing-05);
  }
}


/* Shuttle */

.direction__shuttle {
  margin-top: var(--spacing-05);
  width: 100%;

  .image {
    display: block;
    margin: var(--spacing-05) auto 0;
    width: 80%;

    @include breakpoint('medium+') {
      width: 100%;
    }
  }
}

