.hero-event {
  padding-top: var(--spacing-07);

  @include breakpoint('medium') {
    padding-top: var(--spacing-08);
  }

  @include breakpoint('large+') {
    padding-top: var(--spacing-10);
  }

  @include breakpoint('xlarge+') {
    padding-top: var(--spacing-11);
  }
}

.hero-event__title {
  @include f-display-02;
  word-break: break-word;

  @include breakpoint('small') {
    @include grid-column(1, 8);
  }

  @include breakpoint('medium+') {
    @include grid-column(3, 20);
  }

  @include breakpoint('xlarge') {
    @include grid-column(4, 18);
  }

  @include breakpoint('xxlarge') {
    @include grid-column(5, 16);
  }
}

.hero-event__details {
  align-items: center;
  justify-content: space-between;
  display: flex;
  margin-top: var(--spacing-06);
  margin-bottom: var(--spacing-06);

  @include breakpoint('small') {
    align-items: flex-start;
    flex-direction: column;
    @include grid-column(1, 8);
  }

  @include breakpoint('medium') {
    @include grid-column(3, 20);
  }

  @include breakpoint('medium+') {
    position: relative;
    margin-top: var(--spacing-07);
    margin-bottom: var(--spacing-06);
    // padding-right: 225px;
  }

  @include breakpoint('large') {
    @include grid-column(3, 20);
  }

  @include breakpoint('xlarge') {
    @include grid-column(4, 18);
  }

  @include breakpoint('xxlarge') {
    @include grid-column(5, 16);
  }
}

.hero-event__text {
  @include f-subheading-02;
}

.hero-event__text-value {
  display: block;

  & + & {
    margin-top: var(--spacing-01);
  }

  &--category {
    color: var(--color-text-alt);
  }

  @include breakpoint('large+') {
    & + & {
      margin-top: var(--spacing-02);
    }
  }
}


.hero-event__buttons {
  align-items: flex-end;
  display: flex;
  height: 100%;
  justify-content: flex-end;

  @include breakpoint('small') {
    align-items: flex-start;
  }

  @include breakpoint('medium-') {
    flex-direction: column;
  }
}
.hero-event__ticket {
  @include breakpoint('small') {
    margin-top: var(--spacing-04);
  }

  @include breakpoint('medium+') {
    // position: absolute;
    // right: 0;
    // bottom: var(--spacing-02);
  }
}
.hero-event__membership {
  margin-left: var(--spacing-02);

  @include breakpoint('small') {
    display: none;
  }

  @include breakpoint('medium-') {
    margin-top: var(--spacing-03);
  }
}

.hero-event[data-product-availability-product-id] .button[data-product-id] {
  opacity: 0;
  pointer-events: none;
}

.hero-event.is-available .button[data-product-id] {
  opacity: 1;
  pointer-events: auto;
}

.hero-event__media {
  position: relative;

  @include breakpoint('small') {
    @include grid-column(1, 8);
    @include img-container($ratio-1x1);
  }

  @include breakpoint('medium+') {
    @include grid-column(3, 20);
    @include img-container($ratio-2x1);
  }

  @include breakpoint('xlarge+') {
    @include grid-column(4, 18);
  }
}

.hero-event__sold-out {
  @include f-caption;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  color: var(--color-text-light);
  background-color: var(--color-sticker);
  z-index: 3;
  position: absolute;
  top: var(--spacing-04);
  right: var(--spacing-04);
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.hero-event[data-product-availability-product-id] .hero-event__sold-out {
  opacity: 0;
  pointer-events: none;
}

.hero-event.is-full .hero-event__sold-out {
  opacity: 1;
  pointer-events: auto;
}

// Taken from .hero-standard__text

.hero-event__text {
  @include grid-column(1, 8);
  @include f-subheading-02;
  margin-top: var(--spacing-02);

  @include breakpoint('medium-') {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    margin-right: var(--spacing-02);
  }

  @include breakpoint('medium+') {
    @include grid-column(1, 20);
  }

  @include breakpoint('large') {
    @include grid-column(1, 20);
  }

  @include breakpoint('xlarge') {
    @include grid-column(1, 18);
  }

  @include breakpoint('xxlarge') {
    @include grid-column(1, 16);
  }
}

.hero-event--special .hero-event__title {
  padding-bottom: var(--spacing-06);

  @include breakpoint('xlarge+') {
    padding-bottom: var(--spacing-12);
  }
}
.event__hero-small__sold-out {
  @include f-caption;
  position: relative;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 36px;
  margin-top: -36px;
  color: var(--color-text-light);
  background-color: var(--color-sticker);
  z-index: 3;

  @include breakpoint('large+') {
    position: absolute;
    top: var(--spacing-04);
    right: var(--spacing-04);
    width: 80px;
    height: 80px;
    margin-top: 0;
    border-radius: 50%;
  }
}

.event__hero-small[data-product-availability-product-id] .event__hero-small__sold-out {
  opacity: 0;
  pointer-events: none;
}
.event__hero-small.is-full .event__hero-small__sold-out {
  opacity: 1;
  pointer-events: auto;
}

