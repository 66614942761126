.header {
  position: relative;
  background-color: var(--color-background-light);
  z-index: var(--z-index-header);

  @include breakpoint('medium+') {
    &.has-transition {
      transition: transform var(--transition-header)
    }

    &.is-sticky {
      position: sticky;
      top: 0;
      left: 0;
      width: 100%;
    }

    &.is-transformed {
      transform: translateY(-100%);
    }
  }
}

.header__container {
  position: relative;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  height: var(--height-header-mobile);

  @include breakpoint('medium+') {
    height: var(--height-header);
  }

  @include breakpoint('xlarge+') {
    justify-content: space-between;
  }
}

.header__left {
  @include breakpoint('xlarge+') {
    position: relative;
    top: -11px;
  }
}

.header__right {
  display: inline-flex;
  align-items: center;

  @include breakpoint('large') {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
}

.header__logo {
  position: absolute;
  top: 50%;
  display: block;
  width: 100%;

  img {
    display: block;
    width: 100%
  }

  @include breakpoint('small') {
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @include breakpoint('medium+') {
    top: 19px;
    left: 0;
    transform: none;
  }

  @include breakpoint('medium+') {
    width: 488px;
    transition: transform 0.25s ease, opacity 0.25s ease;
  }

  @include breakpoint('xlarge+') {
    top: 0;
    transform: translate(0, 0);

    &:before {
      content: '';
      position: absolute;
      top: -8px;
      left: -8px;
      right: -8px;
      bottom: -8px;
      display: none;
      border: 1px solid var(--color-text);
    }

    @include focus {
      &:before {
        display: block;
      }
    }
  }
}

.header__hours {
  position: absolute;
  top: -2px;
  left: 0;
  display: none;
  white-space: nowrap;
  transition: transform 0.25s ease, opacity 0.25s ease;

  &.is-open {
    color: var(--color-text-highlight);
  }
}

.header__burger {
  display: none;

  @include breakpoint('medium+') {
    @include reset-btn;
    display: block;
    padding: var(--spacing-02);
    z-index: 2;

    .icon {
      display: block;
    }
  }

  @include breakpoint('medium') {
    position: absolute;
    top: 10px;
    right: -8px;
  }

  @include breakpoint('large') {
    margin-right: -8px;
    margin-left: var(--spacing-02);
  }

  @include breakpoint('xlarge+') {
    display: none;
  }
}

.header__toggle {
  @include reset-btn;
  @include f-label;
  position: fixed;
  bottom: var(--spacing-05);
  left: var(--spacing-04);
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  width: 124px;
  height: 40px;
  padding: 0 var(--spacing-04);
  background-color: var(--color-background-light);
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, .15);
  border-radius: 20px;
  z-index: var(--z-index-header-toggle);

  @include breakpoint('medium+') {
    display: none;
  }
}

.header__toggle-icon {
  position: relative;
  width: 24px;
  height: 24px;
  margin-right: var(--spacing-03);

  .icon {
    position: absolute;
    top: 0;
    left: 0;
  }

  .icon--close {
    opacity: 0;
  }
}

.header__toggle-label-close {
  display: none;
}


/*********************
      Modifiers
*********************/


.tpl-homepage {
  @include breakpoint('xlarge+') {
    .header__logo {
      position: absolute;
      top: 10px;
      left: 0;
      opacity: 0;
      pointer-events: none;
    }

    .header__hours {
      display: block;
    }

    .is-banner-hidden & {
      .header__hours {
        opacity: 0;
        pointer-events: none;
        transform: translate3d(0, -100%, 0);
      }

      .header__logo {
        opacity: 1;
        pointer-events: auto;
        transform: translate3d(0, -10px, 0);
      }
    }
  }
}

.tpl-explore-online,
.tpl-explore-online-detail {
  .header {
    transition: background 0.1s linear;
  }
  .header {
    background-color: var(--color-black);

    .header__logo {
      filter: invert(1);
    }

    .nav__tablet-link,
    .header__burger .icon {
      color: white;
    }

    .nav__tablet-link--secondary {
      opacity: 1;
    }

    @include breakpoint('xlarge+') {
      .nav__link {
        color: white;
      }

      .nav__link--secondary {
        opacity: 1;
      }
    }
  }
}

.tpl-explore-online {
  .header:not(.is-sticky) {
    @include breakpoint('large+') {
      background-color: transparent;
    }
  }
}

.tpl-membership {
  .header {
    transition: background 0.1s linear;
  }
  .header:not(.is-sticky) {
    background-color: transparent;

    .header__logo {
      filter: invert(1);
    }

    .nav__tablet-link,
    .header__burger .icon {
      color: white;
    }

    .nav__tablet-link--secondary {
      opacity: 0.4;
    }

    @include breakpoint('xlarge+') {
      .nav__link {
        color: white;
      }

      .nav__link--secondary {
        opacity: 0.4;
      }
    }
  }
}
