.push-newsletter__container {
  @include grid-column(1, 8);

  @include breakpoint('medium+') {
    @include grid-column(3, 20);
  }

  @include breakpoint('xlarge') {
    @include grid-column(4, 18);
  }

  @include breakpoint('xxlarge') {
    @include grid-column(5, 16);
  }
}

.push-newsletter__title {
  @include f-heading-04;
}

.push-newsletter__text,
.push-newsletter__btn {
  margin-top: var(--spacing-05);
}
