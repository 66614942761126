.card-activity__container {
  @include grid;
}

.card-activity__left {
  @include grid-column(1, 8);

  @include breakpoint('medium') {
    @include grid-column(3, 7);
  }

  @include breakpoint('large+') {
    @include grid-column(1, 7);
  }
}

.card-activity__right {
  @include grid-column(1, 8);

  @include breakpoint('small') {
    margin-top: var(--spacing-04);
  }

  @include breakpoint('medium') {
    @include grid-column(11, 12);
  }

  @include breakpoint('large') {
    @include grid-column(9, 16);
  }

  @include breakpoint('xlarge+') {
    @include grid-column(9, 14);
  }
}

.card-activity__kicker {
  margin-bottom: var(--spacing-02);
  color: var(--color-text-alt);
}

.card-activity__title {
  @include f-heading-01;

  @include breakpoint('xlarge+') {
    max-width: 600px;
  }
}

.card-activity__text {
  margin-top: var(--spacing-04);
}

.card-activity__caption {
  @include f-caption;
  margin-top: var(--spacing-04);
  color: var(--color-text-alt);
}

.card-activity__btn {
  margin-top: var(--spacing-05);
}
