.cart-mini__expand {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  z-index: var(--z-index-cart-mini);
  transition: opacity var(--transition-cart-mini);

  @include breakpoint('small') {
    display: flex;
    flex-flow: column wrap;
    justify-content: flex-end;
    opacity: 1;
  }
}

.cart-mini__btn-box {
  position: relative;
  display: block;
  width: 0px;
  height: 32px;
  margin: 0 0 0 auto;
  z-index: calc(var(--z-index-cart-mini) + 1);
  transition: width var(--transition-link-slow), margin-left var(--transition-link-slow);
  pointer-events: none;

  @include breakpoint('small') {
    position: fixed;
    bottom: 24px;
    left: 140px;
    height: 40px;
    margin-left: 0;
  }

  @include breakpoint('medium') {
    position: absolute;
    top: 14px;
    right: 38px;
  }

  @include breakpoint('medium+') {
    overflow: hidden;
  }
}

.cart-mini__btn {
  @include reset-btn;
  @include focus;
  @include f-label;
  position: absolute;
  top: 50%;
  right: 0;
  display: block;
  width: 32px;
  height: 32px;
  background-color: var(--color-background-light);
  border: 1px solid var(--color-border);
  border-radius: 50%;
  cursor: pointer;
  opacity: 0;
  transition: opacity var(--transition-link), border-color var(--transition-link);
  transform: translateY(-50%);

  &:hover,
  &:focus {
    border-color: var(--color-border-dark);
  }

  @include breakpoint('small') {
    width: 40px;
    height: 40px;
    border: 0;
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, .15);
  }
}

.cart-mini__layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-layer);
  z-index: 1;

  @include breakpoint('small') {
    opacity: 0;
    transition: opacity var(--transition-cart-mini), backdrop-filter var(--transition-cart-mini);
    pointer-events: none;
  }
}

.cart-mini__content {
  @include f-body-ui;
  position: relative;
  width: 100%;
  margin: 0 0 0 auto;
  padding: var(--spacing-04) var(--spacing-04) 100px var(--spacing-04);
  text-align: left;
  background-color: var(--color-background-light);
  z-index: 2;

  @include breakpoint('small') {
    max-height: 100vh;
    overflow-y: auto;
    transform: translateY(100%);
    transition: transform 0.4s ease;
  }

  @include breakpoint('medium+') {
    max-width: 584px;
    padding: var(--spacing-04) var(--spacing-08) var(--spacing-05) var(--spacing-05);
  }
}

.cart-mini__title {
  margin-bottom: var(--spacing-04);
}

.cart-mini__title-value {
  &--added {
    display: none;
  }
}

.cart-mini__footer {
  margin-top: var(--spacing-06);
}

/*********************
       State
*********************/

.cart-mini.is-visible {
  .cart-mini__btn-box {
    width: 32px;
    margin-left: var(--spacing-04);
    pointer-events: auto;
  }

  .cart-mini__btn {
    opacity: 1;
    transition: opacity var(--transition-link) .4s, border-color var(--transition-link) 0s;
  }
}

.cart-mini.is-just-added {
  .cart-mini__title-value {
    &--global {
      display: none;
    }

    &--added {
      display: block;
    }
  }

  .cart-mini__footer {
    display: none;
  }
}

.cart-mini.is-open {
  .cart-mini__expand {
    opacity: 1;
    pointer-events: auto;
  }

  @include breakpoint('small') {
    .cart-mini__layer {
      opacity: 1;
      pointer-events: auto;
      backdrop-filter: blur(var(--backdrop-filter-blur));
    }

    .cart-mini__content {
      transform: translateY(0);
    }
  }
}

.cart-mini.no-transition {
  .cart-mini__btn-box {
    transition: none;
  }

  .cart-mini__btn {
    transition: border-color var(--transition-link);
  }
}
