.sidebar {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: var(--z-index-sidebar);
  pointer-events: none;

  [data-scroll-reveal-element] & {
    opacity: 0;
  }

  [data-scroll-reveal-element].is-animation-done & {
    opacity: 1;
  }

  &.no-transition-loader {
    .sidebar__loader {
      transition: none;
    }
  }

  &.is-open {
    pointer-events: auto;

    .sidebar__layer {
      opacity: 1;
      backdrop-filter: blur(var(--backdrop-filter-blur));
    }

    .sidebar__container {
      transform: translateX(0);
    }
  }
}

.sidebar__layer {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-layer);
  cursor: pointer;
  opacity: 0;
  z-index: 1;
  transform: translateZ(0); /*for older browsers*/
  will-change: transform;
  transition: opacity var(--transition-sidebar), backdrop-filter 0.3s ease;
}

.sidebar__container {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  max-width: 800px;
  padding: 0;
  background-color: var(--color-background-light);
  transform: translateX(100%);
  z-index: 2;
  transition: transform var(--transition-sidebar);

  @include breakpoint('medium') {
    width: calc(100% - 12.25%);
  }

  @include breakpoint('large') {
    width: calc(100% - 24.5%);
    padding: var(--spacing-09) 5.6%;
  }

  @include breakpoint('xlarge+') {
    width: calc(100% - 41.1%);
    padding: var(--spacing-09) 5.3%;
  }
}

.sidebar__scroll {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
}

.sidebar__close {
  @include reset-btn;
  position: absolute;
  top: var(--spacing-04);
  right: var(--spacing-04);
  z-index: 3;

  .icon {
    display: block;
  }
}

.sidebar__header {
  padding: var(--spacing-06) var(--spacing-04) var(--spacing-04);
  border-bottom: 1px solid var(--color-border);

  @include breakpoint('large+') {
    padding: 0 0 var(--spacing-04);
  }
}

.sidebar__title {
  @include f-heading-04;
}

.sidebar__content {
  padding: var(--spacing-07) var(--spacing-04);

  @include breakpoint('small') {
    @include custom-scrollbar;
    height: calc(100% - 148px); // Sidebar header + Sidebar footer
    overflow-y: auto;
  }

  @include breakpoint('medium') {
    padding: var(--spacing-07) 11.6%;
  }

  @include breakpoint('large+') {
    padding: var(--spacing-07) 0;
  }
}

.sidebar__date {
  position: relative;
  padding-top: var(--spacing-05);
  padding-right: 130px;

  &::first-letter {
    text-transform: uppercase;
  }

  &.is-hidden {
    display: none;
  }
}

.sidebar__date-day,
.sidebar__date-hours {
  display: block;
}

.sidebar__date-hours {
  @include f-caption;
  margin-top: var(--spacing-01);
  color: var(--color-text-alt);
}

.sidebar__date-link {
  position: absolute;
  top: var(--spacing-01);
  right: 0;
}

/* Step */

.sidebar__step {
  position: relative;
}

.sidebar__step-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: opacity var(--transition-sidebar-step), visibility var(--transition-sidebar-step);

  &.is-visible {
    opacity: 1;
    visibility: visible;
    pointer-events: auto;
  }
}

.sidebar__step-title {
  @include f-heading-04;
  margin-bottom: var(--spacing-04);

  &--with-border {
    margin-bottom: var(--spacing-07);
    padding-bottom: var(--spacing-05);
    border-bottom: 1px solid var(--color-border);
  }
}

.sidebar__step-text {
  margin-top: var(--spacing-04);
  color: var(--color-text-alt);
}

.sidebar__step-option {
  margin-top: var(--spacing-06);
}

.sidebar__step-footer {
  margin-top: var(--spacing-07);
  text-align: right;

  .sidebar__availability-error-text {
    @include width-multi((small: 8, medium: 6, large: 6, xlarge: 6, xxlarge: 6));
    @include f-caption;
    color: var(--color-error);
    text-align: left;
  }

  .button + .button {
    margin-left: var(--spacing-06);
  }

  @include breakpoint('small') {
    .button[data-adyen-submit] {
      display: none;
    }
  }

  @include breakpoint('medium+') {
    .sidebar__availability-error-text {
      position: absolute;
    }
  }
}

.sidebar__aside-date {
  margin-top: var(--spacing-04);
  padding-top: var(--spacing-04);
  border-top: 1px solid var(--color-border);

  &.is-hidden {
    display: none;
  }
}

.sidebar__aside-day {
  &::first-letter {
    text-transform: uppercase;
  }
}

.sidebar__aside-day,
.sidebar__aside-hour {
  @include f-caption;
  color: var(--color-text-alt);
}

.sidebar__aside-cart {
  margin-top: var(--spacing-04);
  border-top: 1px solid var(--color-border);

  &.is-hidden {
    display: none;
  }
}

/* Hours */

.sidebar__hours {
  margin-top: var(--spacing-06);
  position: relative;

  &.is-hidden {
    display: none;
  }

  &.is-loading {
    pointer-events: none;

    .sidebar__hours-title {
      opacity: 0.5;
    }

    .sidebar__hours-list {
      opacity: 0.5;
    }
  }
}

.sidebar__hours-title {
  @include f-heading-05;
  margin-bottom: var(--spacing-05);
}

.sidebar__hours-loader {
  opacity: 0;
  position: absolute;
  width: 50px;
  height: 50px;
  pointer-events: none;
  transition: opacity 0.2s ease-out;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.sidebar__hours.is-loading .sidebar__hours-loader {
  opacity: 1;
}

.sidebar__hours-list {
  display: flex;
  flex-flow: row wrap;
}

.sidebar__hours-item {
  height: 48px;
  border-right: 1px solid var(--color-border);
  border-bottom: 1px solid var(--color-border);

  @include breakpoint('small') {
    width: 16.666666667%;

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6) {
      border-top: 1px solid var(--color-border);
    }

    &:nth-child(6n+1) {
      border-left: 1px solid var(--color-border);
    }
  }

  @include breakpoint('medium+') {
    width: 14.285714286%;

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7) {
      border-top: 1px solid var(--color-border);
    }

    &:nth-child(7n+1) {
      border-left: 1px solid var(--color-border);
    }
  }

  &.is-not-available {
    cursor: not-allowed;
    text-decoration: line-through;

    .sidebar__hours-btn {
      pointer-events: none;
    }
  }
}

.sidebar__hours-btn {
  @include reset-btn;
  @include f-caption;
  @include focus();
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    display: block;
    border: 1px solid var(--color-background-dark);
    opacity: 0;
    z-index: 2;
  }

  &.is-selected {
    color: var(--color-text-light);
    background-color: var(--color-background-dark);

    &:before {
      opacity: 1;
    }
  }
}

/* Payment */

.sidebar__payment {
  @include breakpoint('medium+') {
    margin-top: var(--spacing-07);
  }
}

.sidebar__payment-quick-btns-msg {
  @include f-caption;
  margin-top: 10px;
}

.sidebar__payment-quick-btns-msg:empty {
  display: none;
}

.sidebar__payment-quick-btns {
  display: flex;
  flex-wrap: wrap;
  margin-left: -16px;
  margin-top: -16px;

  &.is-disabled {
    display: none;
  }

  > * {
    width: 100%;
    padding-left: 16px;
    padding-top: 16px;
    display: none;

    @include breakpoint('medium+') {
      width: 33.33%;
    }

    &.is-enabled {
      display: block;
    }

    > * {
      width: 100% !important;

      .gpay-button.long, .gpay-button.buy {
        min-width: 0;
      }
    }
  }
}

.sidebar__payment-block {
  &.is-hidden {
    display: none;
  }
}

.sidebar__payment-cgv {
  @include f-caption;
  margin-bottom: var(--spacing-07);
  padding: var(--spacing-03) var(--spacing-04);
  color: var(--color-text-alt);
  border: 1px solid var(--color-border);

  a {
    text-decoration: underline;
  }

  @include breakpoint('medium+') {
    margin-top: var(--spacing-07);
  }
}

.sidebar__payment-methods {
  position: relative;
  width: 100%;
  min-height: 40px;
  border-radius: 4px;
  overflow: hidden;
  background-color: #fbfbfb;
  transition: opacity var(--transition-link);

  &.is-loading {
    .sidebar__payment-methods-loader {
      opacity: 1;
      animation: sidebarPaymentMethods 1.5s linear infinite alternate;
    }
  }

  &.is-available {
    .sidebar__payment-methods-btn {
      opacity: 1;
    }
  }

  &.is-hidden {
    display: none;
  }
}

.sidebar__payment-methods-loader {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  display: block;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-image: linear-gradient(90deg, #fbfbfb, #f1f0f0, #fbfbfb);
}

@keyframes sidebarPaymentMethods {
  0% {
    left: -100%;
  }

  100% {
    left: 100%;
  }
}

.sidebar__payment-methods-btn {
  opacity: 0;
  transition: var(--transition-link-slow);
}

.sidebar__payment-form-title {
  @include f-label;
  margin-top: var(--spacing-07);
}

.sidebar__payment-form-row {
  margin-top: var(--spacing-06);

  &.is-hidden {
    display: none;
  }
}

.sidebar__payment-stripe {
  margin-top: var(--spacing-06);
}

.sidebar__payment-stripe-label {
  margin-bottom: var(--spacing-02);
  color: var(--color-text-alt);
}

.sidebar__payment-stripe-form {
  display: flex;
  flex-flow: row wrap;
}

.sidebar__payment-stripe-card {
  width: 100%;
  height: var(--height-input);
  box-shadow: inset 0px 0px 0px 1px var(--color-border);
  padding: 14px var(--spacing-04);

  &:hover {
    box-shadow: inset 0px 0px 0px 1px var(--color-text);
  }

  &.StripeElement--focus {
    box-shadow: inset 0px 0px 0px 2px var(--color-text);
  }
}

.sidebar__payment-stripe-error {
  @include f-body-ui;
  margin-top: var(--spacing-04);
  color: var(--color-error);
}

/* Aside */

.sidebar__aside-infos {
  margin-bottom: var(--spacing-06);
  padding: var(--spacing-04);
  border: 1px solid var(--color-border);

  &.is-hidden {
    display: none;
  }
}

.sidebar__aside-text {
  @include f-caption;
  color: var(--color-text-alt);
}
.sidebar__aside-text--membership {
  &-title {
    @include f-body-ui;
    color: var(--color-black);
  }
}

/* Mobile */

.sidebar__mobile {
  &.is-paying {
    .sidebar__mobile-footer-left,
    .sidebar__mobile-footer-right {
      display: none;
    }

    .sidebar__mobile-footer-payment {
      display: block;
    }
  }

  @include breakpoint('medium+') {
    display: none;
  }
}

.sidebar__mobile-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 88px;
  padding: var(--spacing-04);
  background-color: var(--color-background-light);
  border-bottom: 1px solid var(--color-border);
  z-index: 2;
}

.sidebar__mobile-header-title {
  @include f-heading-04;
  margin-top: var(--spacing-01);
}

.sidebar__mobile-header-step {
  @include f-label;
}

.sidebar__mobile-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 72px;
  padding: var(--spacing-04);
  background-color: var(--color-background-light);
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  z-index: 2;

  .sidebar__mobile-membership {
    align-items: center;
    display: none;
    justify-content: center;
    width: 100%;
  }

  &.is-membership {
    .sidebar__mobile-membership {
      display: flex;
    }
    .sidebar__mobile-footer-left,
    .sidebar__mobile-footer-right {
      display: none;
    }
  }
}

.sidebar__mobile-infos {
  @include f-caption;
  color: var(--color-text-alt);
}

.sidebar__mobile-infos-text {
  &::first-letter {
    text-transform: uppercase;
  }

  &.is-hidden {
    display: none;
  }
}

.sidebar__mobile-infos-date {
  text-transform: capitalize;

  &.is-hidden {
    display: none;
  }
}

.sidebar__mobile-infos-prices {
  &.is-hidden {
    display: none;
  }
}

.sidebar__mobile-text {
  @include f-caption;
  margin-top: var(--spacing-06);
  color: var(--color-text-alt);

  @include breakpoint('medium+') {
    display: none;
  }
}

.sidebar__mobile-footer-payment {
  display: none;
  width: 100%;

  .button {
    width: 100%;
    justify-content: center;
  }
}

/* Loader */

.sidebar__loader {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: var(--color-background-light);
  opacity: 0;
  pointer-events: none;
  z-index: 10;
  transition: opacity 0.35s ease;
}

.sidebar__loader-icon {
  svg {
    display: block;
    animation: 2s linear infinite loading-rotate-keyframe;
  }

  circle {
    fill: transparent;
    stroke-dasharray: 178px;
    stroke-linecap: round;
    stroke-width: 4;
    transform-origin: 50% 50%;
    animation: 2.5s linear infinite both loading-circle-keyframe;
  }
}

@keyframes loading-rotate-keyframe {
  0% {
    transform: rotateZ(0deg);
  }

  100% {
    transform: rotateZ(360deg);
  }
}

@keyframes loading-circle-keyframe {
  0%,
  25% {
    stroke-dashoffset: 178;
    transform: rotate(0);
  }

  50%,
  75% {
    stroke-dashoffset: 30;
    transform: rotate(45deg);
  }

  100% {
    stroke-dashoffset: 178;
    transform: rotate(360deg);
  }
}

/* Banner */

.sidebar__banner {
  @include f-body-ui;
  width: 100%;
  color: var(--color-text-light);
  background-color: var(--color-error);
  z-index: var(--z-index-notification);
  box-shadow: 0 -1px 0 0 rgba(255, 0, 51, .15);
  transform: translateY(-100%);
  transition: transform var(--transition-link);
  position: fixed;

  &.is-visible {
    transform: translateY(0);
  }
}

.sidebar__banner-container {
  position: relative;
  padding: var(--spacing-04) 0;
  padding-right: var(--spacing-06);
}

.sidebar__banner-close {
  position: absolute;
  width: 24px;
  right: 0;
  top: 16px;
  appearance: none;
  color: #fff;
  background: none;
  border: 0;
  cursor: pointer;
}

/* Form Membership */
.sidebar__breadcrumb,
.sidebar__form-membership,
.sidebar__step-prices {
  &.is-hidden {
    display: none;
  }
}

.sidebar__form-membership {
  .sidebar__form-membership__title {
    display: none;
    margin-bottom: 0;

    @include breakpoint('small') {
      .is-hidden-mobile {
        display: none;
      }
    }

    @include breakpoint('medium+') {
      @include f-heading-04;
      display: block;
      margin-bottom: var(--spacing-06);
      width: 100%;
    }

    .is-hidden {
      display: none;
    }
  }

  .sidebar__form-membership__button {
    display: flex;
    justify-content: flex-end;
    margin-top: var(--spacing-07);

    .button.is-hidden {
      display: none;
    }
    .button.is-hidden-mobile {
      display: none;

      @include breakpoint('medium+') {
        display: block;
      }
    }
  }

  .is-mobile-only {
    color: var(--color-gray-60);
    display: block;

    @include breakpoint('medium+') {
      display: none;
    }
  }
}


/*********************
        State
*********************/

.sidebar {
  &.is-loading {
    // .sidebar__breadcrumb,
    // .sidebar__header,
    // .sidebar__content,
    // .sidebar__aside {
    //   opacity: 0;
    // }

    .sidebar__loader {
      opacity: 1;
      pointer-events: auto;
    }
  }
}

/*********************
      Modifiers
*********************/

.sidebar--hours {
  .sidebar__date {
    padding-right: 0;
  }

  @include breakpoint('small') {
    .sidebar__content {
      height: calc(100% - 81px);
    }
  }
}

.sidebar--affluence {
  .sidebar__header {
    border-bottom: 0;
  }
  .list__item {
    border-top: 0;
  }
}

.sidebar--medium {
  .sidebar__container {
    max-width: 968px;
  }

  @include breakpoint('small') {
    .sidebar__container {
      width: 100%;
      padding: 0;
    }
  }

  @include breakpoint('large') {
    .sidebar__container {
      width: calc(100% - 20.5%);
    }
  }

  @include breakpoint('xlarge+') {
    .sidebar__container {
      width: calc(100% - 28.8%);
    }
  }
}

.sidebar--cart {
  .sidebar__aside .is-hidden {
    display: none;
  }

  @include breakpoint('small') {
    .sidebar__container {
      width: 100%;
    }

    .sidebar__grid,
    .sidebar__background {
      height: 100%;
    }

    .sidebar__scroll {
      height: calc(100% - 72px - 88px);
      margin-top: 88px;
      padding: 0;
    }

    .sidebar__content {
      height: 100%;
      padding-bottom: var(--spacing-06);
    }

    .sidebar__breadcrumb {
      display: none;
    }

    .sidebar__scroll {
      overflow: hidden;
    }

    .sidebar__aside {
      display: none;
    }

    .sidebar__step {
      overflow: hidden;
    }

    .sidebar__step-item {
      padding-bottom: var(--spacing-01);
    }

    .sidebar__step-text {
      margin-top: 0;
    }

    .sidebar__step-footer {
      width: 100%;
      margin-top: var(--spacing-06);
      text-align: center;

    @include breakpoint('medium+') {
      width: calc(100% + 32px);
      margin-left: -16px;
    }

      [data-cart-step-btn] {
        display: none;
      }
    }

    .sidebar__step-title {
      display: none;
    }
  }

  @include breakpoint('medium+') {
    @include grid;
    padding: 0 var(--spacing-04);

    .sidebar__container {
      position: relative;
      padding: 0;
    }

    .sidebar__grid {
      @include grid-container();
      @include grid-columns(grid-repeat(20, 1fr));
      @include grid-column-gap(16px);
      margin-left: 0;
    }

    .sidebar__breadcrumb {
      @include grid-column(3, 18);
    }

    .sidebar__content {
      @include grid-column(3, 10);
    }

    .sidebar__aside {
      @include grid-column(15, 5);
    }
  }

  @include breakpoint('medium') {
    .sidebar__container {
      @include grid-column(1, 24);
      width: calc(100% + 32px);
      max-width: none;
      margin-left: -16px;
    }

    .sidebar__breadcrumb {
      @include grid-column(2, 19);
    }

    .sidebar__content {
      @include grid-column(2, 11);
      padding-left: 0;
      padding-right: 0;
    }

    .sidebar__aside {
      @include grid-column(14, 6);
    }
  }

  @include breakpoint('large+') {
    .sidebar__container {
      @include grid-column(5, 20);
      width: calc(100% + 16px);
      max-width: 1136px;
      margin-left: auto;
      margin-right: -16px;
      padding: 0;
    }
  }
}

.sidebar--text {

  // For a main title that is within the scroll area

  .sidebar__content .sidebar__title {
    margin-bottom: var(--spacing-06);
  }

  .sidebar__container {
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
    padding-top: 0;
  }

  .sidebar__content {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    flex-grow: 1;
    padding-top: var(--spacing-08);
    margin-right: -16px;
    padding-right: 16px;
  }
}

.sidebar--form {
  .form {
    padding-top: 0;
  }

  @include breakpoint('small') {
    .sidebar__grid {
      display: flex;
      flex-flow: column wrap;
    }

    .sidebar__aside {
      order: 1;
      margin-top: var(--spacing-08);
      padding: 0 var(--spacing-04);
    }

    .sidebar__content {
      order: 2;
      height: 100%;
      padding-bottom: var(--spacing-10);
    }
  }

  @include breakpoint('medium+') {
    @include grid;
    padding: 0 var(--spacing-04);

    .form {
      padding-top: 32px;
    }

    .sidebar__container {
      position: relative;
      padding: 0;
    }

    .sidebar__grid {
      @include grid-container();
      @include grid-columns(grid-repeat(20, 1fr));
      @include grid-column-gap(16px);
      margin-left: 0;
    }

    .sidebar__content {
      @include grid-column(3, 10);
    }

    .sidebar__aside {
      @include grid-column(15, 5);
      margin-top: 80px;
    }
  }

  @include breakpoint('medium') {
    .sidebar__container {
      @include grid-column(1, 24);
      width: calc(100% + 32px);
      max-width: none;
      margin-left: -16px;
    }

    .sidebar__content {
      @include grid-column(2, 11);
      padding-left: 0;
      padding-right: 0;
    }

    .sidebar__aside {
      @include grid-column(14, 6);
    }
  }

  @include breakpoint('large+') {
    .sidebar__container {
      @include grid-column(5, 20);
      width: calc(100% + 16px);
      max-width: 1136px;
      margin-left: auto;
      margin-right: -16px;
      padding: 0;
    }
  }
}
