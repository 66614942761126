.homepage__featured-events,
.homepage__about,
.homepage__events,
.homepage__quick-access {
  position: relative;
  background-color: var(--color-background-light);
  z-index: 1;
}

.homepage__about {
  padding-top: var(--spacing-12);
  overflow: hidden;
}

.homepage__quick-access {
  padding-left: var(--spacing-04);
  padding-top: var(--spacing-12);
  overflow: hidden;

  &__button {
    margin-top: var(--spacing-09);
    text-align: center;
  }

  @include breakpoint('small') {

    &__button {
      margin-left: calc(-1 * var(--spacing-04));
    }

    .container {
      width: 100%;
    }
    .carousel--digital-content .container {
      width: calc(100% - 32px);
    }
  }

  @include breakpoint('medium+') {
    padding-left: 0;
  }
}

.homepage__quick-access--tall {
  .carousel--quick-access .carousel__item {
    bottom: initial;

    @include breakpoint('medium+') {
      width: 232px;
    }
  }
}

.homepage__quick-access-title-container {
  margin-bottom: var(--spacing-04);

  @include breakpoint('medium+') {
    margin-bottom: calc(-1 * var(--spacing-08));
  }
}

.homepage__quick-access-title {
  @include f-display-04;
  @include grid-column(1, 8);
  margin-bottom: var(--spacing-04);
}

.homepage__quick-access-text {
  @include grid-column(1, 8);
  margin-bottom: var(--spacing-05);

  @include breakpoint('medium') {
    @include grid-column(1, 12);
  }
}
