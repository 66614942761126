.hero-visit {
  padding-top: var(--spacing-07);
  padding-bottom: var(--spacing-07);

  @include breakpoint('medium') {
    padding-top: var(--spacing-08);
    padding-bottom: 0;
  }

  @include breakpoint('large+') {
    padding-top: var(--spacing-11);
    padding-bottom: var(--spacing-07);
  }
  @include breakpoint('xlarge+') {
    padding-bottom: var(--spacing-09);
  }
}

.hero-visit__title {
  @include f-display-02;

  @include breakpoint('small') {
    @include grid-column(1, 8);
  }

  @include breakpoint('medium') {
    @include grid-column(1, 16);
  }

  @include breakpoint('large') {
    @include grid-column(1, 14);
  }

  @include breakpoint('xlarge+') {
    @include grid-column(1, 10);
  }
}

.hero-visit__infos {
  margin-top: var(--spacing-05);

  @include breakpoint('small') {
    @include grid-column(1, 8);
  }

  @include breakpoint('medium') {
    @include grid-column(1, 24);
  }

  @include breakpoint('large+') {
    @include grid-column(17, 8);
    margin-top: var(--spacing-04);
  }

  @include breakpoint('xlarge+') {
    @include grid-column(16, 6);
  }
}

.hero-visit__infos-text {
  & + & {
    margin-top: var(--spacing-04);
  }
}

.hero-visit__infos-value {
  display: block;
}

.hero-visit__nav {
  @include grid-column(1, 8);
  margin-top: var(--spacing-07);

  @include breakpoint('medium+') {
    @include grid-column(1, 24);
    margin-top: var(--spacing-08);
  }
}

.hero-visit__img {
  @include grid-column(1, 8);
  @include img-container($ratio-1x1);
  margin-left: -16px;
  margin-right: -16px;

  @include breakpoint('medium+') {
    @include grid-column(1, 24);
    @include img-container($ratio-13x5);
  }
}
