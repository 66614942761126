.breadcrumb {
  height: 68px;
  padding: var(--spacing-05) 0;
}

.breadcrumb__list {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.breadcrumb__item {
  display: inline-flex;
  align-items: center;
  transition: color var(--transition-link);

  .icon--chevron-right {
    margin: 0 var(--spacing-03);
  }

  &.is-disable {
    color: var(--color-text-alt);
    pointer-events: none;
  }

  &.is-hidden {
    display: none;
  }
}

.breadcrumb__link {
  @include reset-btn;
  @include f-caption;
  @include focus();
}
