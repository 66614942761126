.form-toggle {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: -4px;
    display: none;
    width: 54px;
    height: 32px;
    border-radius: 25px;
    border: 2px solid var(--color-focus);
  }

  &.is-focus {
    &:after {
      display: block;
    }
  }

  &--online {
    .form-toggle__input:checked + .form-toggle__label:before {
      border-color: var(--color-text-online);
    }

    .form-toggle__input:checked + .form-toggle__label {
      color: var(--color-text-online);
    }

    .form-toggle__input:checked + .form-toggle__label:after {
      background-color: var(--color-text-online);
    }
  }
}

.form-toggle__input {
  @include reset-input;
}

.form-toggle__label {
  display: block;
  padding-left: var(--spacing-08);
  padding-top: var(--spacing-01);
  cursor: pointer;
  transition: var(--transition-link);

  &:before {
    position: absolute;
    top: var(--spacing-01);
    left: 0;
    content: '';
    display: block;
    width: 46px;
    height: 24px;
    border: 1px solid var(--color-border-dark);
    border-radius: 25px;
    transition: var(--transition-link);
  }

  &:after {
    position: absolute;
    top: var(--spacing-02);
    left: var(--spacing-01);
    content: '';
    display: block;
    width: 16px;
    height: 16px;
    background-color: var(--color-border-dark);
    border-radius: 50%;
    transition: var(--transition-link);
  }

  &:hover {
    &:before {
      border-color: var(--color-text);
    }

    &:after {
      background-color: var(--color-text)
    }
  }
}

.form-toggle__input:checked + .form-toggle__label {
  color: var(--color-text);
}

.form-toggle__input:checked + .form-toggle__label:before {
  border-color: var(--color-background-highlight);
}

.form-toggle__input:checked + .form-toggle__label:after {
  left: 26px;
  background-color: var(--color-background-highlight);
}
