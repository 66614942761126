.nav-anchor {
  @include scroll-indicator;
  position: relative;
  overflow: hidden;
  width: calc(100% + 32px);
  margin-left: -16px;
  margin-top: var(--spacing-02);
  margin-bottom: var(--spacing-04);

  @include breakpoint('large+') {
    margin-top: var(--spacing-04);
  }
}

.nav-anchor__scroll {
  @include custom-scrollbar;
  padding: 0 var(--spacing-04);
  white-space: nowrap;
  overflow-x: auto;
}

.nav-anchor__list {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.nav-anchor__item {
  margin-right: var(--spacing-06);
  padding-top: var(--spacing-04);
  padding-bottom: var(--spacing-01);

  &:last-child {
    padding-right: var(--spacing-06);

    .nav-anchor__link {
      margin-right: 16px;
    }
  }
}

.nav-anchor__link {
  @include f-heading-05;
  display: block;
  color: var(--color-text-alt);
  transition: color var(--transition-link);

  &:hover {
    color: var(--color-text);
  }
}

.article-standard__anchor {
  margin: 0 var(--spacing-04);
}
