.event__content {
  @include breakpoint('medium-') {
    @include grid-container;

    .event__infos {
      order: 2;
    }

    .event__hero {
      order: 1;
    }

    .blocks {
      order: 3;
    }
  }
}

.event__infos {
  @include breakpoint('medium-') {
    margin-top: var(--spacing-07);
    margin-bottom: var(--spacing-07);
  }
}

.event__infos-wrapper {
  @include grid-column(1, 8);

  @include breakpoint('medium') {
    @include grid-column(3, 20);
  }

  @include breakpoint('large+') {
    position: relative;
    @include grid-column(3, 5);
  }

  @include breakpoint('xlarge') {
    @include grid-column(4, 4);
  }

  @include breakpoint('xxlarge') {
    @include grid-column(5, 3);
  }
}

.event__infos-container {
  @include breakpoint('large+') {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}

.event__hero-small {
  @include breakpoint('large+') {
    margin-bottom: var(--spacing-07);
  }
}

.event__hero-small-container {
  position: relative;
  @include breakpoint('small') {
    @include grid-column(1, 8);
    @include img-container($ratio-1x1);
  }

  @include breakpoint('medium') {
    @include grid-column(3, 20);
  }

  @include breakpoint('medium+') {
    @include img-container($ratio-3x2);
  }

  @include breakpoint('large+') {
    @include grid-column(9, 13);
  }

  @include breakpoint('xxlarge') {
    @include grid-column(9, 12);
  }
}


/*********************
      Modifiers
*********************/

.event--medium {
  @include breakpoint('small') {
    .hero-event__img {
      width: calc(100% + 32px);
      margin-left: -16px;
    }
  }

  @include breakpoint('medium') {
    .hero-event__title {
      @include grid-column(3, 20);
    }
  }

  @include breakpoint('medium+') {
    .hero-event__img {
      @include img-container($ratio-2x1);
    }
  }

  @include breakpoint('large+') {
    .event__content {
      margin-top: var(--spacing-08);
    }
  }

  @include breakpoint('xxlarge') {
    .hero-event__media {
      @include grid-column(5, 16);
    }
  }
}

.event--large {
  .hero-event__title {
    @include f-display-01;
  }

  .hero-event__media {
    margin-left: -16px;
    margin-right: -16px;
  }

  @include breakpoint('small') {
    .hero-event__title,
    .hero-event__details,
    .hero-event__media {
      @include grid-column(1, 8);
    }
  }

  @include breakpoint('medium+') {
    .hero-event__title {
      @include grid-column(1, 24);
    }

    .hero-event__media {
      @include grid-column(1, 24);
    }
  }

  @include breakpoint('large+') {
    .hero-event__title {
      @include grid-column(1, 21);
    }

    .event__content {
      margin-top: var(--spacing-08);
    }
  }

  @include breakpoint('xxlarge') {
    .hero-event__title {
      @include grid-column(1, 22);
    }
  }
}

/* Modifiers */

.event__hero {
  &--white {
    color: var(--color-white);
  }

  &--with-background {
    &.event__hero--black {
      .hero-event__text-value--category {
        color: var(--color-text);
      }
    }

    &.event__hero--white {
      .hero-event__text-value--category {
        color: var(--color-white);
      }
    }
  }
}