.hero-landing {
  padding-top: 40vh;
}

.hero-landing__kicker,
.hero-landing__img {
  @include grid-column(1, 8);

  @include breakpoint('medium+') {
    @include grid-column(1, 24);
  }
}

.hero-landing__title {
  @include f-display-01;
  @include grid-column(1, 8);

  @include breakpoint('medium+') {
    @include grid-column(1, 18);
  }
}

.hero-landing__kicker {
  @include f-subheading-02;
  margin-bottom: var(--spacing-02);
  color: var(--color-text-alt);

  @include focus {
    outline: 1px solid var(--color-text);
  }
}

.hero-landing__img {
  @include img-container($ratio-1x1);
  margin-top: var(--spacing-03);
  margin-left: -16px;
  margin-right: -16px;
}

.hero-landing__text {
  @include grid-column(1, 8);
  @include f-subheading-01;
  margin-top: var(--spacing-04);

  @include breakpoint('medium+') {
    @include grid-column(1, 20);
  }

  @include breakpoint('xlarge+') {
    @include grid-column(1, 18);
  }
}

.hero-landing__link {
  @include grid-column(1, 8);
  margin-top: var(--spacing-04);
}
