.indexed-list {
  position: relative;
  z-index: 0;
  @include grid;
  align-items: flex-start;
}

.indexed-list__image-container {
  @include grid-column(14, 7);
  grid-row: 1;
  height: 100%;
  position: relative; // for sensor
  // position: sticky;
  // height: 100vh;
  top: 0;
  // display: flex;
  // flex-direction: column;
  // align-items: center;

  @include breakpoint('small') {
    display: none;
  }

  @include breakpoint('medium') {
    @include grid-column(13, 10);
  }
}

.indexed-list__image {
  opacity: 0;
  background-color: #ccc;
  transition: opacity 0.6s ease-out;
  .image {
    height: 100%;
    width:100%;
    overflow: hidden;
    .image__container {
      height: 100%;
      position: relative;
      overflow: hidden;
    }
  }
}

.indexed-list__image img {
  width: 100%;
  height: auto;
}

.indexed-list__image-container.is-sticking:not(.is-stuck-bottom) .indexed-list__image {
  position: fixed;
  top: 50vh;
  transform: translate(0px, -50%);
}

.indexed-list__image-container.is-stuck-bottom .indexed-list__image {
  position: absolute;
  bottom: 0;
  left: 0
}

.indexed-list__image.is-visible {
  opacity: 1;
  transition-delay: 0.1s;
}

.indexed-list__nav {
  @include grid-column(1, 4);
  // background: green;
  height: 100%;
  grid-row: 1;
  position: relative; // for sensor

  @include breakpoint('small') {
    display: none;
  }
}


.indexed-list__sensor {
  width: 1px;
  height: 1px;
  // background: blue;
  position: absolute;
  left: 0;
}

.indexed-list__sensor[data-indexed-list-nav-top-sensor] {
  top: 0;
}

.indexed-list__sensor[data-indexed-list-nav-bottom-sensor] {
  bottom: 0
}

.indexed-list__nav__inner {
  overflow-y: auto;
  padding-top: 10px;
  max-height: 100vh;
}


.indexed-list__nav.is-sticking:not(.is-stuck-bottom) .indexed-list__nav__inner {
  position: fixed;
  top: 50vh;
  transform: translate(0px, -50%);
}

.indexed-list__nav.is-stuck-bottom .indexed-list__nav__inner {
  position: absolute;
  bottom: 0;
  left: 0
}

.indexed-list__nav__item {
  @include f-label;
}

.indexed-list__nav__item a {
  display: inline-block;
  vertical-align: top;
  margin-left: -10px;
  margin-right: -10px;
  padding-left: 10px;
  padding-right: 10px;
}

.indexed-list__nav__item a:hover {
  text-decoration: underline;
}

.indexed-list__nav__item.is-empty {
  opacity: 0.2;
  pointer-events: none;
}

.indexed-list__main {

  position: relative;
  z-index: 1;
  // background: red;

  @include breakpoint('small') {
    @include grid-column(1, 8);
  }

  @include breakpoint('medium+') {
    @include grid-column(5, 12);
    grid-row: 1;
  }
}

.indexed-list__main > li {
  @include f-heading-04;
}

.indexed-list__main > li > a {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    bottom: 4px;
    left: 0;
    right: 100%;
    display: block;
    height: 1px;
    background-color: currentColor;
    transition: right var(--transition-link-slow);
  }

  &:hover::before {
    right: 0;
  }
}

