// Font-smoothing
@mixin font-smoothing($value: on) {
  // sass-lint:disable-block no-vendor-prefixes

  @if $value == on {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }
  @else {
    -moz-osx-font-smoothing: auto;
    -webkit-font-smoothing: subpixel-antialiased;
  }
}

// Reset btn
@mixin reset-btn {
  // sass-lint:disable-block no-vendor-prefixes
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  white-space: nowrap;
}

// Reset input
@mixin reset-input {
  // sass-lint:disable-block no-vendor-prefixes

  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  border: 0 none;
  border-radius: 0;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  outline: none;
  padding: 0;

  &::-ms-clear {
    display: none;
  }
}

// ####################################################
// Font setup mixins
//
// Use the serif/sans-serif mixins directly in the SCSS do any responsive
// overwrites - see setup/_typography.scss
// See
// * https://codepen.io/13twelve/pen/WYgrYE?editors=1000
// * https://code.area17.com/a17/fe-boilerplate/wikis/scss-setup-typography
// * https://code.area17.com/a17/fe-boilerplate/wikis/scss-setup-mixins-
//   typography

@function get-primary-font-name($font) {
  // check for a font family rather than a single font name
  $font-family-comma-index: str-index('#{$font}', ',');
  @if $font-family-comma-index {
    // get the primary font from the font family
    $font: str-slice(#{$font}, 0, $font-family-comma-index - 1);
    // $font: str-replace($font, '"', '');
  }
  @return $font;
}

@function generate-font-obj($obj) {
  $_obj: ();
  $_settings: ();

  @each $key, $value in $obj {
    @if ($key != 'settings') {
      $_obj: map-merge($_obj, ($key: $value));
    }
  }

  $font-family: map-get($obj, font-family);
  $font-family-loaded: map-get($obj, font-family-loaded);
  $settings: map-get($obj, settings);
  // work out the font string to use
  $font: if($font-family-loaded, $font-family-loaded, $font-family);
  // work out the primary font name (used in the cap heights map)
  $font-name: get-primary-font-name($font);
  // ok if we have settings
  @if $settings {
    @each $breakpoint, $font-info in $settings {
      $_settings: map-merge($_settings, (#{$breakpoint}: $font-info));
    }
  }
  //
  $_obj: map-merge($_obj, (settings: $_settings));
  @return $_obj;
}

@mixin font-style($font-size: false, $line-height: false, $font-weight: false,
$font-style: false, $letter-spacing: false, $text-transform: false) {
  // set font settings
  @if $font-size {
    font-size: $font-size;
  }
  @if $line-height {
    line-height: $line-height;
  }
  @if $font-weight {
    font-weight: $font-weight;
  }
  @if $font-style {
    font-style: $font-style;
  }
  @if $letter-spacing {
    letter-spacing: $letter-spacing;
  }
  @if $text-transform {
    text-transform: $text-transform;
  }
}

@mixin font-styles($obj, $bolder-weight: false) {
  $font-family: map-get($obj, font-family);
  $font-family-loaded: map-get($obj, font-family-loaded);
  $font-loaded-class: map-get($obj, font-loaded-class);
  $settings: map-get($obj, settings);
  // set base font family
  @if $font-family {
    font-family: $font-family;
  }

  @if $bolder-weight {
    b,
    strong {
      font-weight: $bolder-weight;
    }
  }
  @else {
    b,
    strong {
      font-weight: 400;
    }
  }

  i,
  em {
    font-style: italic;
  }

  // set font family when font loaded class, if exists
  @if $font-family-loaded and $font-loaded-class {
    .#{$font-loaded-class} & {
      font-family: $font-family-loaded;
    }
  }
  // set the various font info per breakpoint
  @if $settings and $font-family {
    $font-size: 0;
    $line-height: 0;
    // loop the breakpoints in the setttings
    @each $breakpoint, $font-info in $settings {
      // check this is a valid breakpoint
      @if map-has-key($breakpoints-with-directions, $breakpoint) {
        // get font info for this breakpoint
        @if map-has-key($font-info, font-size) {
          // conditional here because this can inherit from earlier items
          // in the map so that the tuck calculation can take place
          $font-size: map-get($font-info, font-size);
        }
        @if map-has-key($font-info, line-height) {
          $line-height: map-get($font-info, line-height);
        }
        $font-weight: map-get($font-info, font-weight);
        $font-style: map-get($font-info, font-style);
        $letter-spacing: map-get($font-info, letter-spacing);
        $text-transform: map-get($font-info, text-transform);

        // check if this is the smallest breakpoint (the default font setting)
        @if (index(map-keys($settings), $breakpoint) == 1) {
          @include font-style($font-size, $line-height,
          $font-weight, $font-style, $letter-spacing, $text-transform);
        }
        @else {
          @include breakpoint(#{$breakpoint}) {
            @include font-style($font-size, $line-height, $font-weight,
            $font-style, $letter-spacing, $text-transform);
          }
        }
      }
    }
  }
}


/*
  @mixin hide_text
  Hides text in an element
*/

@mixin hide-text {
  color: transparent;
  font: 0 / 0 a;
  overflow: hidden;
  text-indent: -100%;
  text-shadow: none;
}

/*
  @mixin img-container
  Add padding bottom to image container to prevent jump on page load.
*/

@mixin img-container($ratio: false) {
  .image__container {
    position: relative;
    overflow: hidden;

    @if $ratio {
      padding-bottom: #{(100 / $ratio) + '%'};
    }
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    min-width: 2px;
    min-height: 2px;
    object-fit: cover;
    pointer-events: none;
  }
}

/*
  @mixin link-full
  Make link taking full space by adding pseudo element.
*/

@mixin link-full {
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
}

/*
  @mixin focus
  Adjust focus styles when class is apply on body.
*/


@mixin focus {
  &:focus {
    outline: none;
  }

  .is-tabbed & {
    &:focus {
      @content;
    }
  }
}

/*
  @mixin scroll-indicator
  Add small gradient block to indicate cut elements.
*/

@mixin scroll-indicator {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 50px;
    height: calc(100% - 5px);
    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 50%, rgba(255,255,255,1) 100%);
    pointer-events: none;
    z-index: 2;
  }
}


/*
  @mixin custom-scrollbar
  Show custom scrollbar, especially on Windows computers
*/

@mixin custom-scrollbar {
  &::-webkit-scrollbar-track {
    background-color: #F5F5F5;
  }

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: #F5F5F5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #BABABA;
  }
}

/* Add this to your chatbot styles */
.chatbot--hidden-mobile {
    display: none !important;
}

@media screen and (min-width: 768px) {  /* or your breakpoint */
    .chatbot--hidden-mobile {
        display: block !important;
    }
}
