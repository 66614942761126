
.hero-explore-online {
  margin-top: 0;
  margin-bottom: var(--spacing-12);
  overflow: hidden;
  padding-top: 0;

  &__container {
    @include grid-column(1, 8);
    margin: 0 calc(-1 * var(--spacing-04));
    height: auto;
    overflow: hidden;
    position: relative;

    @include breakpoint('medium+') {
      @include grid-column(1, 24);
    }

    @include breakpoint('large+') {
      height: calc(100vh - 40px);

      &[data-index="0"] {
        .hero-explore-online__card:nth-child(1) {
          opacity: 1;
        }
        .hero-explore-online__index:nth-child(1) {
          background-color: var(--color-white);
        }
      }

      &[data-index="1"] {
        .hero-explore-online__card:nth-child(2) {
          opacity: 1;
        }
        .hero-explore-online__index:nth-child(2) {
          background-color: var(--color-white);
        }
      }

      &[data-index="2"] {
        .hero-explore-online__card:nth-child(3) {
          opacity: 1;
        }
        .hero-explore-online__index:nth-child(3) {
          background-color: var(--color-white);
        }
      }
    }


    @include breakpoint('xlarge+') {
      height: calc(100vh - var(--spacing-08));
    }
  }

  &__items {
    height: 100%;
    width: 100%;
  }

  &__indexes {
    bottom: var(--spacing-09);
    display: none;
    position: absolute;
    right: var(--spacing-06);
    z-index: 10;

    @include breakpoint('large+') {
      display: block;
    }
  }
  &__index {
    background-color: transparent;
    border: 1px solid var(--color-white);
    border-radius: 50%;
    height: 8px;
    margin-top: var(--spacing-02);
    overflow: hidden;
    text-indent: -5000px;
    width: 8px;

    transition: background var(--transition-card-flv);
  }

  &__content {
    bottom: var(--spacing-08);
    display: none;
    height: 100%;
    left: var(--spacing-06);
    pointer-events: none;
    position: absolute;
    z-index: 10;
    width: 100%;

    @include breakpoint('large+') {
      display: block;
    }
  }

  &__card {
    bottom: 0;
    position: absolute;
    opacity: 0;
    left: 0;
    right: 55%;

    transition: opacity var(--transition-card-flv);

    &-title {
      @include f-display-04;
    }
    &-description {
      @include f-body;
    }
  }

  .carousel__list {
    width: 100%;

    @include breakpoint('large+') {
      height: 100%;
      overflow: auto;
      scroll-snap-type: y mandatory;

      /* CSS Hack to hide scrollbar */
      scrollbar-width: none;
      -ms-overflow-style: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }

  .carousel__list:after {
    content: 'flickity';
    display: none;
  }

  .carousel__item {
    bottom: auto;
    height: auto;
    top: 0;
    width: 100%;
  }
  .carousel__single {
    height: 100%;
    padding-right: 0;
  }
  .container {
    width: 100%;
  }
  .carousel__controls {
    margin-top: var(--spacing-06);
    margin-left: var(--spacing-06);
  }

  @include breakpoint('large-') {
    .flickity-page-dots {
      bottom: var(--spacing-04);
      display: flex;
      position: absolute;
      right: var(--spacing-06);
    }
    .dot {
      background-color: transparent;
      border: 1px solid var(--color-white);
      border-radius: 50%;
      height: 8px;
      margin-left: var(--spacing-02);
      width: 8px;

      transition: background var(--transition-card-flv);

      &.is-selected {
        background-color: var(--color-white);
      }
    }
  }

  @include breakpoint('large+') {
    margin-top: -180px;
    padding-top: var(--spacing-10);

    .carousel,
    .container,
    .carousel__container {
      height: 100%;
      width: 100%;
    }

    .carousel__item {
      height: 100%;
      width: 100%;
      scroll-snap-align: start;
    }

    .carousel__controls {
      display: none;
    }
    .carousel__list:after {
      content: '';
    }
  }

  @include breakpoint('xlarge+') {
    padding-top: var(--spacing-11);
  }
}

.hero-explore-online__title {
  @include f-display-04;

  @include breakpoint('small') {
    @include grid-column(1, 8);
  }

  @include breakpoint('medium+') {
    @include grid-column(1, 12);
  }
}

// Taken from .hero-standard__text

.hero-explore-online__text {
  @include grid-column(1, 8);
  @include f-body;
  margin-top: var(--spacing-02);

  @include breakpoint('medium+') {
    @include grid-column(1, 12);
  }
}

.hero-explore-online__anchor {
  @include grid-column(1, 8);
  display: none;
  margin-left: var(--spacing-04);

  @include breakpoint('medium+') {
    @include grid-column(1, 24);
  }

  @include breakpoint('large+') {
    display: block;
  }

  .nav-anchor {
    margin-top: 0;
  }
  .nav-anchor .tab {
    color: var(--color-white);
  }
  .nav-anchor:after {
    display: none;
  }
}
