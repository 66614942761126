.is-online {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  color: var(--color-text-online);
}

.is-online__text {
  margin-left: var(--spacing-02);
}

.hero-event__text .is-online__text {
  margin-left: var(--spacing-03)
}

.card-activity__title + .is-online {
  margin-top: var(--spacing-04)
}

.is-online {
  + .callout__subtitle {
    margin-top: var(--spacing-01)
  }

  + .hero-event__text-value {
    margin-top: var(--spacing-02);
  }

  + .card-activity__text {
    margin-top: var(--spacing-03);
  }
}
