.button-group {
  display: flex;
  margin-top: calc(0px - var(--spacing-04));
  margin-left: calc(0px - var(--spacing-04));

  @include breakpoint('small') {
    flex-direction: column;
    align-items: center;
  }

  @include breakpoint('medium+') {
    flex-direction: row;
    justify-content: center;
  }

  > * {
    margin-top: var(--spacing-04);
    margin-left: var(--spacing-04);
  }
}
