// Get breakpoint directions
@function get-breakpoint-directions() {
  $_bps: ();
  @each $k, $bp in $breakpoints {
    $_bps: map-merge($_bps, ($k: $bp));
    $start: map-get($bp, start);
    $end: map-get($bp, end);

    @if $end != null and $start != null {
      $down-key: unquote($k + '-');
      $_bps: map-merge($_bps, ($down-key: (start: null, end: $end)));
    }

    @if $start != null and $end != null {
      $up-key: unquote($k) + '+';
      $_bps: map-merge($_bps, ($up-key: (start: $start, end: null)));
    }
  }

  @return $_bps;
}

$breakpoints-with-directions: get-breakpoint-directions();

// Breakpoint
@mixin breakpoint($name, $option: '') {
  $points: map-get($breakpoints-with-directions, $name);
  @if ($name and $points) {
    $media: get-media($points);
    $start: map-get($media, 'start');
    $end: map-get($media, 'end');
    $str: 'screen and (';
    @if($start != null) {
      $str: $str + 'min-width: ' + ($start * 1px);
    }
    @if($start != null and $end != null) {
      $str: $str + ') and (';
    }
    @if($end != null) {
      $str: $str + 'max-width: ' + ($end * 1px);
    }
    $str: $str + ')';
    @if($option == 'hover') {
      $str1: $str + ' and (-moz-touch-enabled: 0), ';
      $str2: $str + ' and (pointer: fine)';
      $str: $str1 + $str2;
    }
    @if($option == 'ie11') {
      $str1: $str + ' and (-ms-high-contrast: none), ';
      $str2: $str + ' and (-ms-high-contrast: active)';
      $str: $str1 + $str2;
    }
    @media #{$str} {
      @content;
    }
  }
  @else if ($option == 'hover') {
    @media (-moz-touch-enabled: 0), (pointer: fine) {
      @content;
    }
  }
  @else if ($option == 'ie11') {
    @media screen and (-ms-high-contrast: none),
      screen and (-ms-high-contrast: active) {
      @content;
    }
  }
}

// Get media
@function get-media($bp) {
  $start: null;
  $end: null;

  $bp-start: map-get($bp, 'start');
  $bp-end: map-get($bp, 'end');
  @if($bp-start != null and ($start == null or $bp-start < $start)) {
    $start: $bp-start;
  }
  @if($bp-end != null and ($end == null or $bp-end > $end)) {
    $end: $bp-end;
  }

  @return (start: $start, end: $end);
}
