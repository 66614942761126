.chip {
  @include f-label;
  position: relative;
  display: inline-flex;
  color: var(--color-text-alt);
  padding: 6px var(--spacing-04);
  border: 1px solid var(--color-border);
  transition: color var(--transition-link), border-color var(--transition-link);

  &:before {
    content: '';
    position: absolute;
    top: -5px;
    left: -5px;
    bottom: -5px;
    right: -5px;
    display: none;
    border: 2px solid var(--color-focus);
  }

  &:hover,
  &.is-active {
    color: var(--color-text);
    border-color: var(--color-text);
  }

  @include focus {
    &:before {
      display: block;
    }
  }

  @include breakpoint('medium+') {
    padding: 10px var(--spacing-04);
  }
}
