// Baseline grid height
$base-font-size: 16px;

// Max widths the main column can run to over the breakpoints
// values can either be 'fluid' or a pixel value
// recommended xxlarge is a px value and xsmall is fluid
$max-width: 1680px;

$main-col-widths: (
  xxlarge: 'fluid',
  xlarge: 'fluid',
  large: 'fluid',
  medium: 'fluid',
  small: 'fluid'
);

// Inner gutters, in px or vw, of each breakpoint
$inner-gutters: (
  xxlarge: 16px,
  xlarge: 16px,
  large: 16px,
  medium: 16px,
  small: 16px
);

// Outer gutters, in px or vw, of each breakpoint
$outer-gutters: (
  xxlarge: 16px,
  xlarge: 16px,
  large: 16px,
  medium: 16px,
  small: 16px
);

// How many columns are in each breakpoint
$column-count: (
  xxlarge: 24,
  xlarge: 24,
  large: 24,
  medium: 24,
  small: 8
);

// Breakpoint information, where each starts and stops
// if a breakpoint is not fluid, then the start value is equal
// to the main col value plus 2x the gutter at this breakpoint
$breakpoints: (
  small: (start: null, end: 599),
  medium: (start: 600, end: 899),
  large: (start: 900, end: 1199),
  xlarge: (start: 1200, end: 1440),
  xxlarge: (start: 1441, end: null)
);

:root {
  --height-header: 60px;
  --height-header-mobile: 48px;
  --height-input: 52px;

  --backdrop-filter-blur: 8px;

  --z-index-nav-mobile: 49;
  --z-index-sticker: 50;
  --z-index-header: 100;
  --z-index-sticky-nav: 99;
  --z-index-cart-mini: 102;
  --z-index-header-toggle: calc(var(--z-index-header) + 1);
  --z-index-sidebar: 102;
  --z-index-live: calc(var(--z-index-header) - 1);
  --z-index-datepicker-range: 101;
  --z-index-cookie: 500;
  --z-index-section: 2;
  --z-index-notification: 120;
  --z-index-search: 103;
  --z-index-filter-toggle: 3;
  --z-index-chatbot: 101;
  --z-index-main: 99;

  --z-index-footer: 1;
}

// Ratios for images.

$ratio-1x1: 1/1;
$ratio-3x2: 3/2;
$ratio-2x1: 2/1;
$ratio-13x5: 13/5;
$ratio-16x9: 16/9;
$ratio-4x5: 4/5;
$ratio-4x3: 4/3;
