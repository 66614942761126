
.card-image-link {

  position: relative;

  .card-image-link__link {
    @include img-container($ratio-1x1);
  }

  .card-image-link__title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover {
    .button--reverse {
      color: var(--color-text-light);
      background-color: var(--color-background-dark);
      border-color: var(--color-background-dark);
    }
  }
}
