.sound-cover__cover {
  display: flex;
  align-items: center;
}
.sound-cover__btn-play {
  display: flex;
  margin-right: var(--spacing-05);
}
.sound-cover__duration {
  margin-left: var(--spacing-03);
}

.sound-cover__player {
  display: none;
}

.show-player {
  .sound-cover__cover {
    display: none;
  }
  .sound-cover__player {
    display: block;
  }
}

.sound-cover__duration {
  @include f-label;
  color: var(--color-text-alt);
}
