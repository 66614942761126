.card-quick-access {
  position: relative;
}

.card-quick-access__title {
  @include f-heading-05;
  margin-top: var(--spacing-03);
}

.card-quick-access__link {
  @include link-full;

  @include focus {
    outline: 1px solid var(--color-text);
  }
}

.card-quick-access__img {
  @include img-container($ratio-1x1);

  @include breakpoint('medium+') {
    @include img-container($ratio-3x2);
  }
}

.card-quick-access__img {
  position: relative;
}

.card-quick-access__img .icon--play-medium {
  color: var(--color-text-light);
  position: absolute;
  margin: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
