.affluence {

  & .form__element--toggle {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    @include breakpoint('medium') {
      margin-top: var(--spacing-04);
    }

    @include breakpoint('large') {
      margin-top: var(--spacing-05);
    }

    @include breakpoint('xlarge+') {
      margin-top: var(--spacing-04);
    }
  }
}

.affluence__container {
  @include f-caption;
  display: flex;
  align-items: flex-start;
}

.affluence__table {
  width: calc(100% - 40px);
  table-layout: fixed;
}

.affluence__days th {
  padding-bottom: var(--spacing-03);
}

.affluence__hours {
  text-align: right;
  margin-top: var(--spacing-05);
  margin-right: var(--spacing-02);

  @include breakpoint('medium+') {
    text-align: center;
  }

  @include breakpoint('large+') {
    text-align: right;
  }

  @include breakpoint('xlarge+') {
    text-align: center;
  }

  li:not(:first-child) {
    padding-top: 15px;

    @include breakpoint('medium+') {
      padding-top: 36px;
    }

    @include breakpoint('large+') {
      padding-top: 17px;
    }

    @include breakpoint('xlarge+') {
      padding-top: 20px;
    }
  }
}

.affluence__content {

  tr {
    border-top: 1px dashed var(--color-border);

    &:last-child {
      border-bottom: 1px dashed var(--color-border);
    }
  }
}

.affluence__item {
  padding: var(--spacing-02);

  @include breakpoint('medium') {
    padding: var(--spacing-03);
  }
}

.affluence__color {
  background-color: grey;
  margin: auto;

  width: 26px;
  height: 18px;

  @include breakpoint('medium') {
    width: 48px;
    height: 32px;
  }

  @include breakpoint('large') {
    width: 28px;
    height: 20px;
  }

  @include breakpoint('xlarge+') {
    width: 32px;
    height: 23px;
  }

  &--25 {
    background-color: var(--affluence-low);
  }

  &--50 {
    background-color: var(--affluence-regular);
  }

  &--75 {
    background-color: var(--affluence-busy);
  }

  &--100 {
    background-color: var(--affluence-full);
  }
}
