.wysiwyg {
  ul {
    margin-top: var(--spacing-05);
    list-style: disc;
    list-style-position: inside;
  }

  p {
    margin-top: var(--spacing-05);
  }

  a {
    border-bottom: 1px solid currentColor;
    transition: color var(--transition-link);

    &:hover {
      color: var(--color-text-alt);
    }
  }

  > *:first-child {
    margin-top: 0;
  }

  h3 {
    @include f-heading-04;
    margin-top: var(--spacing-07);

    @include breakpoint('medium+') {
      margin-top: var(--spacing-09);
    }
  }
}
