// If you want to turn a container into a grid.
@mixin grid-container() {
  display: -ms-grid;
  display: grid;
  margin-left: auto;
  margin-right: auto;
}

// Turn a container into a grid css element based on the grid specificiations.
@mixin grid($has-gap-column: true, $use-grid-cols: true) {
  @include grid-container;

  @include breakpoint('small') {
    @if ($use-grid-cols) {
      @include grid-columns(grid-repeat(map-get($column-count, small), 1fr));
    }

    @if ($has-gap-column) {
      @include grid-column-gap(map-get($inner-gutters, small));
    }
  }

  @include breakpoint('medium') {
    @if ($use-grid-cols) {
      @include grid-columns(grid-repeat(map-get($column-count, medium), 1fr));
    }

    @if ($has-gap-column) {
      @include grid-column-gap(map-get($inner-gutters, medium));
    }
  }

  @include breakpoint('large') {
    @if ($use-grid-cols) {
      @include grid-columns(grid-repeat(map-get($column-count, large), 1fr));
    }

    @if ($has-gap-column) {
      @include grid-column-gap(map-get($inner-gutters, large));
    }
  }

  @include breakpoint('xlarge') {
    @if ($use-grid-cols) {
      @include grid-columns(grid-repeat(map-get($column-count, xlarge), 1fr));
    }

    @if ($has-gap-column) {
      @include grid-column-gap(map-get($inner-gutters, xlarge));
    }
  }

  @include breakpoint('xxlarge') {
    @if ($use-grid-cols) {
      @include grid-columns(grid-repeat(map-get($column-count, xxlarge), 1fr));
    }

    @if ($has-gap-column) {
      @include grid-column-gap(map-get($inner-gutters, xxlarge));
    }
  }
}


// Define the number of cols to have on the container. If you use the @grid
// mixin on a container, it's already setup to match the number of cols
// inside the grid.
// You can use this one in combinaison with the grid-repeat mixin.
@mixin grid-columns($columns...) {
  -ms-grid-columns: $columns;
  grid-template-columns: $columns;
}


// This one is here to support IE11 because repeat() synthax is not supported.
@function grid-repeat($repeat, $fraction: 1fr) {
  $list: ();
  @for $i from 1 through $repeat {
    $list: append($list, $fraction, space);
  }
  @return $list;
}


// Define gap between column inside the CSS grid (already added if you add the
// grid mixin on a container, the column gap will match the Grid System).
@mixin grid-column-gap($gap) {
  grid-column-gap: $gap;
  column-gap: $gap;
}


// Define gap between row inside the CSS grid.
@mixin grid-row-gap($gap) {
  grid-row-gap: $gap;
  row-gap: $gap;
}


// For grid child items, you can define the number of cols each item needs
// to take. Start with the col position you want to start and pass the number
// of cols you want your element to take.
@mixin grid-column($col-start, $nb-col) {
  -ms-grid-column: $col-start;
  -ms-grid-column-span: $nb-col;
  grid-column: #{$col-start} / span #{$nb-col};
}


// Use this mixin to defined the starting position for the child.
@mixin grid-column-start($col-start) {
  grid-column-start: $col-start;
}


// Use this mixin to defined a width for a child element inside a CSS grid.
@mixin grid-column-end($nb-col) {
  grid-column-end: span $nb-col;
}



/*
  @function colspan

  Returns a calc() that represents a column span

  Parameters:
  $number-of-columns - the number of columns you want to span
  $breakpoint - at which breakpoint
  $bump - if you want the colspan + an arbitrary number
  $inverse - if you want to return a negative number (to move things backwards)

  NB: only works with breakpoint names, not the +/- variants
*/
@function colspan($number-of-columns: 1, $breakpoint: 'xsmall', $bump: 0, $inverse: false) {
  @if map-has-key($breakpoints-with-directions, $breakpoint) {
    $this-bp-main-col-width: map-get($main-col-widths, $breakpoint);
    $this-bp-inner-gutter: map-get($inner-gutters, $breakpoint);
    $this-bp-outer-gutter: map-get($outer-gutters, $breakpoint);
    $this-bp-total-cols: map-get($column-count, $breakpoint);

    @if ($number-of-columns >= $this-bp-total-cols) {
      @if ($this-bp-main-col-width == 'fluid') {
        @return calc(100vw - #{2 * $this-bp-outer-gutter});
      } @else {
        @return $this-bp-main-col-width;
      }
    } @else {

      @if ($this-bp-main-col-width == 'fluid') {
        $this-calc: '((100vw - #{(($this-bp-total-cols - 1) * $this-bp-inner-gutter) + (2 * $this-bp-outer-gutter)}) / #{$this-bp-total-cols}) * #{$number-of-columns}';

        @if ($number-of-columns >= 1) {
          $this-calc: '(#{$this-calc}) + #{($number-of-columns - 1) * $this-bp-inner-gutter}';
        }

        @if($bump != 0) {
          $this-calc: '(#{$this-calc}) + #{$bump}';
        }

        @if($inverse) {
          $this-calc: '(#{$this-calc}) * -1';
        }

        @return calc(#{$this-calc});

      } @else {
        $this-multiplier: 1;
        @if($inverse) {
          $this-multiplier: -1;
        }

        @return #{((((($this-bp-main-col-width - ($this-bp-total-cols - 1) * $this-bp-inner-gutter) / $this-bp-total-cols) * $number-of-columns) + ($number-of-columns - 1) * $this-bp-inner-gutter) + $bump) * $this-multiplier};
      }

    }
  }
}

/*
  @mixin width

  Returns a width and a calc() to correctly span columns
  with breakpoint selection and optional bump value

  Parameters:
  $number-of-columns - the number of columns you want to span
  $breakpoint - at which breakpoint
  $bump - if you want the colspan + an arbitrary number

  NB: only works with breakpoint names, not the +/- variants
*/

@mixin width($number-of-columns: 1, $breakpoint: 'xsmall', $bump: 0) {
  width: colspan($number-of-columns, $breakpoint, $bump);
}

/*
  @mixin width-multi

  A mix of @mixin width and @mixin column. Pass a map of the number
  of columns to span at each breakpoint. Use when you don't need to
  float on the grid.

  Parameters:
  $colspans - scss map detailing how many design columns this column
  needs to span on each breakpoint. Omitting a breakpoint from the
  map will not set a width at that breakpoint.
  $bump - if you want the colspan + an arbitrary number

  ```scss
  $column-spans__list-articles: (
    xsmall: 3,
    small: 3,
    medium: 4,
    large: 4,
    xlarge: 3,
    xxlarge: 3
  );

  .list-articles__item {
    @include width-multi($column-spans__list-articles);
  }
  ```

  NB: only works with breakpoint names, not the +/- variants
*/

@mixin width-multi($colspans: false, $bump: 0) {
  @if $colspans {
    @each $name, $point in $breakpoints {
      $colspan: map-get($colspans, #{$name});

      @if $colspan {
        @include breakpoint('#{$name}') {
          @include width($colspan, #{$name}, $bump);
        }
      }
    }
  }
}

