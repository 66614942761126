:root {
  --transition-header: .35s ease;
  --transition-link: .2s ease;
  --transition-link-slow: .35s ease;
  --transition-accordion: .25s ease;
  --transition-panel: .25s ease;
  --transition-cart-mini: .3s ease;
  --transition-sidebar: .4s ease;
  --transition-sidebar-loader: .35s ease;
  --transition-sidebar-step: .35s ease;
  --transition-live: .3s ease;
  --transition-datepicker-range: .3s ease;
  --transition-card-flv: .3s linear;
}
