.cookie {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: var(--spacing-05) var(--spacing-04);
  width: 100%;
  color: var(--color-text-light);
  background-color: var(--color-text);
  z-index: var(--z-index-cookie);

  @include breakpoint('medium+') {
    max-width: 432px;
    right: var(--spacing-08);
    padding: var(--spacing-06) var(--spacing-06) var(--spacing-05);
  }
}

.cookie__text {
  @include f-caption;
}

.cookie__footer {
  margin-top: var(--spacing-04);
}

.cookie__btn {
  @include reset-btn;
  @extend .link;
  @extend .link--icon;

  & + & {
    margin-left: var(--spacing-05);
  }
}
