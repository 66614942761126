.about__title {
  @include f-display-02;
  @include grid-column(1, 8);

  @include breakpoint('medium+') {
    @include grid-column(1, 18);
    margin-top: var(--spacing-05);
  }

  @include breakpoint('xlarge+') {
    @include grid-column(1, 15);
    order: 1;
  }
}

.about__infos {
  margin-top: var(--spacing-03);

  @include breakpoint('small') {
    @include grid-column(1, 8);
  }

  @include breakpoint('medium+') {
    @include grid-column(1, 18);
  }

  @include breakpoint('xlarge+') {
    @include grid-column(18, 5);
    order: 2;
    margin-top: 0;
  }
}

.about__text {
  margin-bottom: var(--spacing-02);
}

.about__video {
  margin-top: var(--spacing-04);
  margin-left: calc(var(--spacing-04) * -1);
  margin-right: calc(var(--spacing-04) * -1);
  background-color: var(--color-background);
}

.about__video-container {
  position: relative;
  padding-bottom: 133.3333%;

  @include breakpoint('medium+') {
    padding-bottom: 47.06%;
  }
}

.about__video-media {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.about__video-content {
  position: absolute;
  bottom: var(--spacing-06);
  left: 0;
  width: 100%;
  text-align: center;

  @include breakpoint('xlarge+') {
    bottom: var(--spacing-08);
  }
}
