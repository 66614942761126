.membership {
  .block--faq {
    margin-top: var(--spacing-08);
  }
  .block-text {
    margin-top: var(--spacing-08);
  }

  .block-text__title {
    @include f-heading-04;
    margin-bottom: var(--spacing-06);
  }

  .block--checklist,
  .block--faq,
  .block--text {
    .block__cell {

      @include breakpoint('xlarge') {
        @include grid-column(9, 12);
      }

      @include breakpoint('xxlarge') {
        @include grid-column(9, 10);
      }
    }
  }

  .blocks--membership {
    @include breakpoint('large+') {
      display: flex;
      flex-flow: row wrap;
      margin-top: -104px;

      .block--membership_detail {
        flex: none;
        width: 50%;
        margin-top: 16px;

        &:first-child {
          margin-top: 16px;        
        }
      }
    }
  }
}
