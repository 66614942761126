.carousel-video {
  width: 100%;

  .container {
    position: relative;
  }

  .flickity-slider {
    position: relative;
    height: 100%;
  }

  .flickity-button {
    display: none;
  }
}

.carousel-video__container {
  margin: var(--spacing-10) auto;
  width: 70%;

  @include breakpoint('medium-') {
    width: 100%;

    &.has-one-video {
      margin: var(--spacing-06) auto;
    }
  }
}
.carousel-video__list {
  cursor: grab;
  transition: all 0.35s ease;

  &.is-translating {
    cursor: grabbing;
    transition: none;
  }
}

.carousel-video__item {
  opacity: 0.4;
  pointer-events: none;
  top: 0;
  width: 100%;

  &.is-selected {
    opacity: 1;
    pointer-events: auto;
  }

  @include breakpoint('medium+') {

  }
}

.carousel-video__single {
  position: relative;
  padding-right: 16px;
}

.carousel-video__caption {
  @include f-caption;
  margin-top: var(--spacing-04);
  color: var(--color-text-alt);
}

.carousel-video__cta {
  @include grid-column(1, 8);
  display: flex;

  @include breakpoint('medium+') {
    @include grid-column(1, 24);
    justify-content: center;
    grid-row: 1;
  }
}

.carousel-video__controls-btn {
  @include reset-btn;
  position: absolute;
  top: 50%;
  left: 0;
  display: inline-flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  margin-top: -22px;
  // border: 1px solid var(--color-text);
  border: 1px solid white;
  border-radius: 50%;
  z-index: 100;

  & + & {
    left: auto;
    right: 0;
  }

  @include breakpoint('medium-') {
    margin-top: -22px !important;
    top: -50px;

    left: auto;
    right: 55px;

    & + & {
      left: auto;
      right: 0;
    }
  }

  &.hidden {
    display: none;
  }

  &:before {
    content: '';
    position: absolute;
    top: -5px;
    right: -5px;
    bottom: -5px;
    left: -5px;
    display: none;
    border: 2px solid var(--color-focus);
    border-radius: 50%;
  }

  @include focus {
    &:before {
      display: block;
    }
  }
}

.carousel-video__cta-flv-play {
  text-align: center;

  .button {
    margin: 0 0 var(--spacing-06);

    @include breakpoint('medium') {
      margin: 0 0 var(--spacing-08);
    }

    @include breakpoint('large+') {
      margin: 0 0 var(--spacing-11);
    }
  }
}

/*********************
      Modifiers
*********************/

.carousel-video--wide {

  .carousel-video__single {
    padding-right: 0;
  }

  .image__content {
    @include container;
    @include grid-container;
    @include grid-columns(grid-repeat(12, 1fr));
    @include grid-column-gap(16px);
    padding-right: 0;
    right: 0; // for pos:abs

    @include breakpoint('medium+') {
      @include grid-columns(grid-repeat(24, 1fr));
    }
  }

  .image__title {
    @include breakpoint('small') {
      @include grid-column(1, 8);
      margin-right: calc(0px - var(--spacing-04));
    }

    @include breakpoint('medium+') {
      @include grid-column(10, 13);
    }
  }

  .image__caption {
    @include breakpoint('small') {
      @include grid-column(1, 8);
      margin-right: calc(0px - var(--spacing-04));
    }

    @include breakpoint('medium+') {
      @include grid-column(10, 13);
    }
  }
}
