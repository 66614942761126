.tab {
  @include reset-btn;
  @include f-heading-05;
  position: relative;
  display: inline-flex;
  flex-flow: row wrap;
  align-items: center;
  padding-bottom: var(--spacing-01);
  color: var(--color-text-alt);
  border-bottom: 2px solid transparent;
  transition: color var(--transition-link), border-color var(--transition-link);

  &:before {
    content: '';
    position: absolute;
    top: -4px;
    left: -4px;
    bottom: 0;
    right: -4px;
    display: none;
    border: 1px solid var(--color-text);
    pointer-events: none;
  }

  .icon {
    position: absolute;
    top: 0;
    right: 0;
    margin-left: var(--spacing-01);
  }

  &:hover {
    color: var(--color-text);
  }

  &.is-active {
    color: var(--color-text);
    border-color: currentColor;
  }

  @include focus {
    border-color: transparent;

    &:before {
      display: block;
    }
  }

  &--icon {
    padding-right: var(--spacing-06);
  }
}
