.intro {
  @include grid;

  &__text {
    @include f-subheading-01;
    @include grid-column(1, 8);

    @include breakpoint('medium+') {
      @include grid-column(1, 21);
    }
  }
}
