.panel__nav-list {
  display: flex;
  flex-flow: row wrap;
}

.panel__nav-item {
  margin-right: var(--spacing-07);

  &:last-child {
    margin-right: 0;
  }
}

.panel__nav-link {
  @include reset-btn;
  @include f-heading-05;
  position: relative;
  display: block;
  padding-bottom: var(--spacing-01);
  color: var(--color-text-alt);
  transition: color var(--transition-panel);

  &:before {
    content: '';
    position: absolute;
    top: calc(var(--spacing-01) * -1);
    left: calc(var(--spacing-01) * -1);
    bottom: 0;
    right: calc(var(--spacing-01) * -1);
    display: none;
    border: 1px solid var(--color-text);
    pointer-events: none;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 3px;
    background-color: transparent;
    transition: background-color var(--transition-panel);
  }

  &:hover {
    color: var(--color-text);
  }

  &.is-active {
    color: var(--color-text);

    &:after {
      background-color: var(--color-text);
    }
  }

  @include focus() {
    color: var(--color-text-alt);

    &:before {
      display: block;
    }
  }
}

.panel__nav + .panel__content {
  margin-top: var(--spacing-06);
}

.panel__content {
  position: relative;
  transition: height var(--transition-panel);
  overflow: hidden;

  .portrait + .portrait {
    margin-top: var(--spacing-08);
  }
}

.panel__panel {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0;
  pointer-events: none;
  transition: opacity var(--transition-panel);

  & + & {
    margin-top: var(--spacing)
  }

  &.is-active {
    opacity: 1;
    pointer-events: auto;
  }
}
